import React from "react";
import { useHistory } from "react-router-dom";

export const ContentDashboard = () => {
    const history = useHistory();



    const upLoads = () => {
        history.push(`/dashboard/content/upload/boards-list`);
    }
    const valiDations = () => {
        history.push(`/dashboard/content/question-validation`);
    }
    const testCreation = () => {
        history.push(`/dashboard/content/pariksha/test-creation`);
    }
    const worksheetCreation = () => {

    }
    return (
        <div>
            <div className="border-top border-primary py-3">
                <h4 className="mt-2  border-bottom pb-2">Content Dashboard</h4>
                <div className="d-flex justify-content-around">
                    <div className="content_cards_list mt-3">
                        <div className="content_card cursor_pointer" onClick={upLoads}>
                            <h5 className="f18">Content Upload</h5>
                        </div>
                    </div>
                    <div className="content_cards_list mt-3">
                        <div className="content_card cursor_pointer cn_validation_bg" onClick={valiDations}>
                            <h5 className="f18">Content Validations</h5>
                        </div>
                    </div>
                    <div className="content_cards_list mt-3">
                        <div className="content_card cursor_pointer cn_test_bg" onClick={testCreation}>
                            <h5 className="f18">Test Creation</h5>
                        </div>
                    </div>
                    <div className="content_cards_list mt-3">
                        <div className="content_card cursor_pointer cn_worksheet_bg" onClick={worksheetCreation}>
                            <h5 className="f18">Worksheet Creation</h5>
                        </div>
                    </div>
                    <div className="content_cards_list mt-3">
                        <div className="content_card cursor_pointer cn_karakartas_bg" onClick={worksheetCreation}>
                            <h5 className="f18">Karyakartas Analysis</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}