import React, { useEffect, useRef, useState } from "react";
import { GET_CONTENT_QUESTION_FILTER_LIST } from "../../../../../app/service/content-module/question-service";
import { toast } from "react-toastify";
import FormBuilder from "../../../../../components/form-builder";
import UploadsSearchByQuestionCodeList from "./questions-list";

export const ContentSearchQuestionCode = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [questionCode, setQuestionCode] = useState<string>("");
    const [questionData, setQuestionData] = useState<any>([]);
    const [key, setKey] = useState<number>(0);
    const questionForm = useRef<any>({});


    const handleSearchCode = async (value: any) => {
        setQuestionCode(value);
        if (value) {
            try {
                const payLoad = {
                    expand: "board,medium,standard,subject,chapter,topic",
                    search: value
                }
                const res: any = await GET_CONTENT_QUESTION_FILTER_LIST(payLoad);
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.message);
                        setLoading(false);
                    } else {
                        const data = res?.data;
                        if (data?.length === 1) {
                            setQuestionData(data);
                        }
                        else {
                            setQuestionData([]);
                            setKey((prev) => prev + 1);
                        }
                    }
                } else {
                    handleRefresh();
                }
            } catch (error) {

            }
        } else {
            handleRefresh();
        }

    }
    const handleFormInput = (data: any) => {
        data = { ...data.value };
        setQuestionData(data);
    };
    function handleRefresh() {
        setQuestionData([]);
        setKey((prev) => prev + 1);
        questionForm.current.reset();

    }
    return (
        <div>
            <div className="border border-2 rounded-2 mt-4">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-3 mb-2">
                        <form>
                            <label className="form-label f16 ms-2 mt-2">Search question by Question Code</label>
                            {/* <p className=" f16 mt-1">Search question by Question Code</p> */}
                            <input className="form-control " type="text" name="source" defaultValue={questionCode} onChange={(e) => handleSearchCode(e.target.value)} />
                        </form>
                    </div>
                </div>
                <div key={key}>
                    <FormBuilder onUpdate={handleFormInput}>
                        <form ref={questionForm}>
                            <div className="row mt-2 mx-2 pb-3 border-bottom border-2">
                                <div className="col-12 col-md-2 mb-2">
                                    <label className="form-label f14">Board</label>
                                    <input className="form-control source_filed_bg f14" type="text" name="board" defaultValue={questionData[0]?._board?.name} disabled />
                                </div>
                                <div className="col-12 col-md-2 mb-2">
                                    <label className="form-label f14">Medium</label>
                                    <input className="form-control source_filed_bg f14" type="text" name="medium" defaultValue={questionData[0]?._medium?.name} disabled />
                                </div>
                                <div className="col-12 col-md-1 mb-2">
                                    <label className="form-label f14">Standard</label>
                                    <input className="form-control source_filed_bg f14" type="text" name="standard" defaultValue={questionData[0]?._standard?.standard} disabled />
                                </div>
                                <div className="col-12 col-md-2 mb-2">
                                    <label className="form-label f14">Subject</label>
                                    <input className="form-control source_filed_bg f14" type="text" name="subject" defaultValue={questionData[0]?._subject?.name} disabled />
                                </div>
                                <div className="col-12 col-md-2 mb-2">
                                    <label className="form-label f14">Chapter</label>
                                    <input className="form-control source_filed_bg f14" type="text" name="chapter" defaultValue={questionData[0]?._chapter?.name} disabled />
                                </div>
                                <div className="col-12 col-md-3 mb-2">
                                    <label className="form-label f14">Topic</label>
                                    <input className="form-control source_filed_bg f14" type="text" name="topic" defaultValue={questionData[0]?._topic?.name} disabled />
                                </div>
                            </div>
                        </form>
                    </FormBuilder>
                    <div className="mx-2">
                        <p className="mt-3">Questions</p>
                        {questionData?.length > 0 &&
                            <UploadsSearchByQuestionCodeList boardUuid={questionData[0]?.board_uuid} mediumUuid={questionData[0]?.medium_uuid} standardUuid={questionData[0]?.standard_uuid} subjectUuid={questionData[0]?.subject_uuid} chapterUuid={questionData[0]?.chapter_uuid} topicUuid={questionData[0]?.topic_uuid} questionCode={questionCode}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}