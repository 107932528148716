import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CREATE_SCHOOL, GET_SCHOOL_BY_ID, GET_SCHOOL_BY_SCHOOL_CODE, UPDATE_SCHOOL, UPLOAD_LOGO } from "../../../app/service/school.service";
import { GET_USER_AD_SCHOOL_CODE, GET_USER_BY_SCHOOL_CODE, GET_USER_VP_SCHOOL_CODE } from "../../../app/service/users.service";
import appConfig from '../../../config/constant';
import SchoolinsideRoutings from "../../../routes/institute-inside-school";
import { SchoolDashBoardRoutes } from "../../../routes/school-dashboard/school-dashboard";
import IMGLOGO from '../../../institute-dashboard-assets/images/bala-bavan-logo.svg'
import CHAIRMANLOGO from '../../../institute-dashboard-assets/images/chairman-icon.svg'
import GENERALSECRETARY from '../../../institute-dashboard-assets/images/generalsecretary-icon.svg'
import INSTITUTEADMIN from '../../../institute-dashboard-assets/images/institute-admin-icon.svg'
import FormBuilder from '../../../components/form-builder';
import { FormControlError, FormField, FormValidators } from "../../../components/form-builder/model/form-field";
import moment from "moment";
import { FormValidator, GetControlIsValid } from "../../../components/form-builder/validations";
import { DateComponent } from "../../../components/date";
import errorMessages from "../../../components/error-messages";
import TimeKeeper from "react-timekeeper";
import { MultiSelect } from "react-multi-select-component";
import { ManagePrincipalForm } from "./principal/form";
import { ManageVicePrincipalForm } from "./vice-principal/form";
import { ManageAdminForm } from "./admin/form";
import { GET_INSTITUTE_BY_INSTITUTE_ID } from "../../../app/service/institute.service";
import { LOOK_UP_DATA } from "../../../app/service/lookup.service";
import { GET_STUDENT_SUBSCRIPTION_PRICE_LIST } from "../../../app/service/subscription.service";
import ProfileImageCrop from "../../../components/image-crop/crop";

export const SchoolInFormation = () => {
    let { id } = useParams<{ id: string }>();
    const schoolId = Number(id);
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [schoolInfo, setSchoolInfo] = useState<any>({});
    const [principalInfo, setPrincipalInfo] = useState<any>({});
    const [vicePricipalInfo, setVicePricipalInfo] = useState<any>({});
    const [adminInfo, setAdminInfo] = useState<any>({});
    const [schoolCode, setSchoolCode] = useState<any>();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const organizationName = localStorage.getItem("organization_name");
    const [formStatus, setFormStatus] = useState(false);
    const [principalId, setPrincipalId] = useState(0);
    const [viceprincipalId, setVicePrincipalId] = useState(0);
    const [adminId, setAdminId] = useState(0);
    const userRole = localStorage.getItem("user_role");
    const [collapsed, setCollapsed] = useState(false)
    //  school form data
    const [schoolData, setSchoolData] = useState<any>({});
    const [formData, setFormData] = useState<any>({});
    const [instituteData, setInstituteData] = useState<any>([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [schoolValidationErrors, setSchoolValidationErrors] = useState<FormControlError[]>([]);
    const [schoolLogo, setSchoolLogo] = useState<any>();
    const [currentSchoolData, setCurrentSchoolData] = useState<any>({});
    const [statesData, setStatesData] = useState<any>([]);
    const [districtData, setDistrictData] = useState<any>([]);
    const [isFormEdit, setIsFormEdit] = useState(false);
    const [cityData, setCityData] = useState<any>([]);
    const [imageLoader, setImageLoader] = useState(false);
    const [clusterNumber, setClusterNumber] = useState(false);
    const [udiseNumber, setUdiseNumber] = useState(false);
    const [contactNumber, setContactNumber] = useState(false);
    const [pincodeNumber, setPincodeNumber] = useState(false);
    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    const [createDate, setCreateDate] = useState(moment().format('YYYY-MM-DD'));
    const [gstNumber, setGstNumber] = useState(false);
    const [checkedStatus, setCheckedStatus] = useState<any>();
    const [mediumSelected, setMediumSelected] = useState([]);
    const [email, setEmail] = useState(false);
    const [startTime, setStartTime] = useState('08:30am');
    const [endTime, setEndTime] = useState('03:30pm');
    const [startShowTime, setStartShowTime] = useState(false);
    const [endShowTime, setEndShowTime] = useState(false);
    const [faxNumber, setFaxNumber] = useState(false);
    const [instituteInfo, setInstituteInfo] = useState<any>({});
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [nameError, setNameError] = useState('');
    const [shortformNameError, setShortformNameError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [blockError, setBlockError] = useState('');
    const [isRegistrationDateError, setIsRegistrationDateError] = useState<any>({});
    const [allStateBlock, setAllStateBlock] = useState<any>({});
    const [blockData, setBlockData] = useState<any>([]);
    const [registrationNumberError, setRegistrationNumberError] = useState(false);
    const [checkedUserMechanismStatus, setCheckedUserMechanismStatus] = useState<any>();
    const [amountError, setAmountError] = useState('');
    const [subscriptionPrice, setSubscriptionPrice] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [view, setView] = useState(false);
    const [updateView, setUpdateView] = useState(false);
    const [principalView, setPrincipalView] = useState(false);
    const [vicePrincipalView, setVicePrincipalView] = useState(false);
    const [adminView, setAdminView] = useState(false)
    const [openSchoolsId, setOpenSchoolsId] = useState(false);
    const [canShowFormPopup, setCanFormPopup] = useState(false);

    const mediumType = [
        { label: "Assamese", value: "Assamese" },
        { label: "Bengali", value: "Bengali" },
        { label: "Bodo", value: "Bodo" },
        { label: "Dogri", value: "Dogri" },
        { label: "English", value: "English" },
        { label: "Gujarati ", value: "Gujarati" },
        { label: "Hindi", value: "Hindi" },
        { label: "Kannada", value: "Kannada" },
        { label: "Kashmiri", value: "Kashmiri" },
        { label: "Konkani", value: "Konkani" },
        { label: "Maithili", value: "Maithili" },
        { label: "Malayalam", value: "Malayalam" },
        { label: "Manipuri ", value: "Manipuri" },
        { label: "Marathi", value: "Marathi" },
        { label: "Nepali", value: "Nepali" },
        { label: "Odia", value: "Odia" },
        { label: "Punjabi", value: "Punjabi" },
        { label: "Sanskrit", value: "Sanskrit" },
        { label: "Santali", value: "Santali" },
        { label: "Sindhi ", value: "Sindhi" },
        { label: "Tamil", value: "Tamil" },
        { label: "Telugu ", value: "Telugu" },
        { label: "Urdu", value: "Urdu" }
    ];

    let createValidations: any = [
        new FormField('organization_code', []),
        new FormField('name_of_school', [FormValidators.REQUIRED]),
        new FormField('email_id', [FormValidators.REQUIRED]),
        new FormField('district', [FormValidators.REQUIRED]),
        new FormField('pin_code', [FormValidators.REQUIRED]),
        new FormField('address', [FormValidators.REQUIRED]),
        new FormField('state', [FormValidators.REQUIRED]),
        new FormField('udise_code', [FormValidators.REQUIRED]),
        new FormField('contact_number', [FormValidators.REQUIRED])

    ]
    let updateValidations: any = [
        new FormField('organization_code', []),
        new FormField('short_name', []),
        new FormField('name_of_school', [FormValidators.REQUIRED]),
        new FormField('email_id', [FormValidators.REQUIRED]),
        new FormField('contact_number', [FormValidators.REQUIRED]),
        new FormField('udise_code', [FormValidators.REQUIRED]),
        new FormField('address', [FormValidators.REQUIRED]),
        new FormField('taluka', []),
        new FormField('district', [FormValidators.REQUIRED]),
        new FormField('state', [FormValidators.REQUIRED]),
        new FormField('pin_code', [FormValidators.REQUIRED]),
        new FormField('logo_url', [FormValidators.REQUIRED]),
        new FormField('email_id', [FormValidators.REQUIRED]),

    ]

    const history = useHistory();
    const [formValidations, setFormValidations] = useState<any>();

    useEffect(() => {

        if (schoolId > 0) {
            setFormValidations(updateValidations);
            setIsFormEdit(true);
            getSchoolInformDetails()
            const openSchoolIdValue = localStorage.getItem("openSchoolId");
            if (openSchoolIdValue) {
                setOpenSchoolsId(true);
            }
        }
        if (schoolId == 0) {
            setFormStatus(true);
            setFormValidations(createValidations);
        }
        LOOK_UP_DATA().then((res: any) => {
            if (res) {
                setStatesData(res.states);
                if (schoolId > 0) {
                    setIsFormEdit(true);
                    GET_SCHOOL_BY_ID(schoolId).then((response: any) => {
                        if (response.status === 'fail') {
                            toast.error(response.message);
                        } else {
                            const data = response.rows[0];
                            getStatesData(res.states, data);
                            setCheckedStatus(data.cas_report_view);
                            setCurrentSchoolData(data);
                            if (data.medium) {
                                setMediumSelected(data.medium);
                            }
                            if (data.logo_url) {
                                setSchoolLogo(data.logo_url);
                            }

                            if (data.timings) {
                                setStartTime(data.timings.split("-")[0]);
                                setEndTime(data.timings.split("-")[1]);
                            }
                            if (data.date_of_registration) {
                                const date_of_registration: any = new Date(data.date_of_registration);
                                setTodayDate(date_of_registration);
                            }
                            if (data.created_at) {
                                const created_at: any = new Date(data.created_at);
                                setCreateDate(created_at);
                            }

                            if (data.access) {
                                setCheckedUserMechanismStatus(data.access);
                            }
                        }
                    });
                }
            }
        });
        const instituteCode = localStorage.getItem('organization_code');
        getInstituteById(instituteCode);

        getSubscriptionList()
    }, [])


    function getSchoolInformDetails() {
        setLoading(true);
        GET_SCHOOL_BY_ID(schoolId).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res.rows[0];
                    setSchoolInfo(data);
                    setCurrentSchoolData(data);
                    setLoading(false);
                    getPrincipal(data?.school_code);
                    getvicePrivipal(data?.school_code);
                    getAdmin(data?.school_code);
                    setSchoolCode(data?.school_code);

                }
            }
        });
    }


    function getPrincipal(schoolCode: any) {

        if (schoolCode) {
            setLoading(true);
            GET_USER_BY_SCHOOL_CODE(schoolCode, 'PRINCIPAL', 0, {}).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        const data = res.rows[0];
                        setPrincipalInfo(data);
                        setPrincipalId(data?.id);
                        setLoading(false);
                    }
                }
            });
        }

    }
    function getvicePrivipal(schCode: any) {
        if (schCode) {
            setLoading(true);
            GET_USER_VP_SCHOOL_CODE(schCode, 'VICE_PRINCIPAL', 0, {}).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        const data = res.rows[0];
                        setVicePrincipalId(data?.id);
                        setVicePricipalInfo(data);
                        setLoading(false);
                    }
                }
            });
        }
    }
    function getAdmin(schCode: any) {
        setLoading(true);
        GET_USER_BY_SCHOOL_CODE(schCode, 'SCHOOL_ADMIN', 0, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows[0];
                    setAdminId(data?.id);
                    setAdminInfo(data);
                    setLoading(false);
                }
            }
        });

    }
    // school form data

    const getInputValid = (control: string) => {
        const value = GetControlIsValid(schoolValidationErrors, control);
        return value;
    }
    const handleChange = (e: any) => {
        const data = { ...schoolData.value };
        data.organization_code = e.target.value;
        if (currentSchoolData) {
            currentSchoolData.organization_code = e.target.value;
        }
    }

    const handleInput = (data: any) => {
        data.value = { ...currentSchoolData, ...data.value };
        setSchoolData(data);
        const errors: any = FormValidator(formValidations, data.value);
        setSchoolValidationErrors(errors);
    };
    const handleSubmit = () => {
        setIsFormSubmitted(true);
        const schoolsData = schoolData.value ? { ...schoolData.value } : { ...currentSchoolData };
        const errors: FormControlError[] = FormValidator(formValidations, schoolsData);
        delete schoolsData.day;
        delete schoolsData.month;
        delete schoolsData.year;
        setSchoolValidationErrors(errors);
        Object.keys(schoolsData).forEach(function (key) {
            if (typeof schoolsData[key] === 'string') {
                schoolsData[key] = schoolsData[key].trim();
            }
        });
        if (errors.length < 1 && !email && !clusterNumber && !udiseNumber && !contactNumber && !pincodeNumber && !gstNumber && !faxNumber
            && !registrationNumberError
            && onChangeName(schoolsData.name_of_school)
            && onChangeAddress(schoolsData.address)

        ) {
            // schoolsData.logo_url = schoolLogo;
            schoolsData.date_of_registration = todayDate ? todayDate : null;
            schoolsData.medium = [];
            schoolsData.cas_report_view = 0;
            schoolsData.timings = '';
            schoolsData.subscription_price = subscriptionPrice;
            schoolsData.category = '';
            schoolsData.city_village = '';
            schoolsData.cluster_code = '';
            schoolsData.curriculum = '';
            schoolsData.fax_number = '';
            schoolsData.gst_number = '';
            schoolsData.logo_url = '';
            schoolsData.registration_number = '';
            schoolsData.taluka = '';
            // schoolsData.udise_code = '';
            schoolsData.website_url = '';

            schoolsData.organization_code = instituteInfo?.organization_code
            setSchoolData(schoolsData);
            setCurrentSchoolData(schoolsData);
            createSchool(schoolsData);
            setCanShowPopup(true);
        } else {
            if (!nameError && !onChangeName(schoolsData.name_of_school)) {
                setNameError('Please enter department name');
            }
            if (!shortformNameError && !onChangeShortformName(schoolsData.short_name)) {
                setShortformNameError('Please enter short form name');
            }
            if (!addressError && !onChangeAddress(schoolsData.address)) {
                setAddressError('Please enter address');
            }
        }
    }

    const handleUpdate = () => {
        const schoolsData = schoolData.value ? { ...schoolData.value } : { ...currentSchoolData };
        schoolsData.id = schoolId;
        schoolsData.logo_url = schoolLogo;
        schoolsData.medium = mediumSelected;
        schoolsData.cas_report_view = checkedStatus;
        schoolsData.date_of_registration = todayDate;
        schoolsData.timings = `${startTime}-${endTime}`;
        delete schoolsData[''];
        delete schoolsData.org_display_name;
        delete schoolsData.org_short_name;
        delete schoolsData.name_of_organization;
        delete schoolsData.updated_at;
        delete schoolsData.created_at;
        delete schoolsData.status;
        delete schoolsData.contact_person;
        delete schoolsData.day;
        delete schoolsData.month;
        delete schoolsData.year;
        delete schoolsData.access;

        schoolsData.contact_number = schoolsData.contact_number.toString();
        schoolsData.access = checkedUserMechanismStatus;
        if (schoolsData.subscription_price === "") {
            schoolsData.subscription_price = currentSchoolData?.subscription_price;
        }
        schoolsData.organization_code = instituteInfo?.organization_code;
        const errors: FormControlError[] = FormValidator(formValidations, schoolsData);
        setSchoolValidationErrors(errors);
        Object.keys(schoolsData).forEach(function (key) {
            if (typeof schoolsData[key] === 'string') {
                schoolsData[key] = schoolsData[key].trim();
            }
        });
        setIsFormSubmitted(true);
        if (errors.length < 1 && !email && !udiseNumber && !contactNumber && !pincodeNumber && !gstNumber && !faxNumber
            && !registrationNumberError
            && onChangeName(schoolsData.name_of_school)
            && onChangeAddress(schoolsData.address) && mediumSelected.length > 0
        ) {
            setLoading(true);
            UPDATE_SCHOOL(schoolsData).then(res => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success("School Updated Successfully");
                    setLoading(false);
                    getSchoolInformDetails();
                    setUpdateView(false);
                }
            });
        } else {
            if (!nameError && !onChangeName(schoolsData.name_of_school)) {
                setNameError('Please enter department name');
            }

            if (!addressError && !onChangeAddress(schoolsData.address)) {
                setAddressError('Please enter address');
            }

            setLoading(false);
        }
    }
    const onChangeName = (event: any) => {
        setNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setNameError('Please enter name of institution');
        }
    }
    const onChangeShortformName = (event: any) => {
        setShortformNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setShortformNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setShortformNameError('Please enter short form name');
        }
    }
    const onChangeAddress = (event: any) => {
        setAddressError('');
        const reg = /^[a-zA-Z0-9 ,:/|;.+=_\\-]*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setAddressError('Address can`t contain spaces and special characters');
            } else {
                return true;
            }
        } else {
            setAddressError('Please enter address');
        }
    }
    const onDateChange = (dates: any) => {
        setTodayDate(todayDate);
        if (dates.error) {
            setIsRegistrationDateError(dates);
        } else {
            setTodayDate(dates.date);
        }
    };
    const handleContactChange = (e: any) => {
        const data = { ...schoolData.value };
        const re = /^[0-9]{10,11}$/;

        if ((re.test(e.target.value))) {
            data.contact_number = e.target.value;
            setContactNumber(false);
        } else {

            data.contact_number = e.target.value;
            setContactNumber(true);
        }

        if (currentSchoolData) {
            currentSchoolData.contact_number = e.target.value;
        }
    }
    const handleStateChange = (e: any) => {
        setBlockData([]);
        const data = { ...schoolData.value };
        data.state = e.target.value;
        const preparedData = statesData.filter((el: any) => el.state === e.target.value);
        setDistrictData(preparedData[0].districts);
        setCityData(preparedData[0].cities);
        setAllStateBlock(preparedData[0]?.block);
        if (currentSchoolData) {
            currentSchoolData.state = e.target.value;
        }
    }
    const handleDistrictChange = (e: any) => {
        setBlockData([]);
        const data = { ...schoolData.value };
        data.district = e.target.value;
        if (currentSchoolData) {
            currentSchoolData.district = e.target.value;
        }
        onLoadBlocks(e.target.value, allStateBlock);
    }
    const onLoadBlocks = (e: any, blocks: any) => {
        Object.keys(blocks).find(key => {
            if (key === e) {
                setBlockData(blocks[key]);
                return;
            }
        });
    }

    const handleBlockChange = (e: any) => {
        const data = { ...schoolData.value };
        data.taluka = e.target.value;
        if (currentSchoolData) {
            currentSchoolData.taluka = e.target.value;
        }
    }
    const handlePinCodeChange = (e: any) => {
        const data = { ...schoolData.value };
        const re = /^[0-9\b]+$/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 6) {
            data.pin_code = e.target.value;
            setPincodeNumber(false);
        } else {
            data.pin_code = e.target.value;
            setPincodeNumber(true);
        }

        if (currentSchoolData) {
            currentSchoolData.pin_code = e.target.value.replace(/\D+/g, '');
        }
    }
    const handleEmailChange = (e: any) => {
        const data = { ...schoolData.value };
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.email_id = e.target.value;
            setEmail(false);
        } else {
            data.email_id = e.target.value;
            setEmail(true);
        }

        if (currentSchoolData) {
            currentSchoolData.email_id = e.target.value;
        }
    }
    const handleFaxNumberChange = (e: any) => {
        const data = { ...schoolData.value };
        const re = /^[0-9\b]+$/;
        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.fax_number = e.target.value;
            setFaxNumber(false);
        } else {
            data.fax_number = e.target.value;
            setFaxNumber(true);
        }

        if (currentSchoolData) {
            currentSchoolData.fax_number = e.target.value.replace(/\D+/g, '');
        }
    }
    const handleUDISEChange = (e: any) => {
        const data = { ...schoolData.value };
        const re = /^[0-9\b]+$/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 11) {
            data.udise_code = e.target.value;
            setUdiseNumber(false);
        } else {
            data.udise_code = e.target.value;
            setUdiseNumber(true);
        }

        if (currentSchoolData) {
            currentSchoolData.udise_code = e.target.value.replace(/\D+/g, '');
        }
    }
    const handleClusterChange = (e: any) => {
        const data = { ...schoolData.value };
        const re = /^[0-9\b]+$/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 9) {
            data.cluster_code = e.target.value;
            setClusterNumber(false);
        } else {
            data.cluster_code = e.target.value;
            setClusterNumber(true);
        }

        if (currentSchoolData) {
            currentSchoolData.cluster_code = e.target.value.replace(/\D+/g, '');
        }
    }
    const handleCirriculumChange = (e: any) => {
        const data = { ...schoolData.value };
        data.curriculum = e.target.value;
        if (currentSchoolData) {
            currentSchoolData.curriculum = e.target.value;
        }
    }
    const handleCategoryChange = (e: any) => {
        const data = { ...schoolData.value };
        data.category = e.target.value;
        if (currentSchoolData) {
            currentSchoolData.category = e.target.value;
        }
    }
    const onChangeRegistrationNumber = (e: any) => {
        const data = { ...schoolData.value };
        const reg = /^[0-9]{1,15}$/;
        if ((e.target.value === '' || reg.test(e.target.value))) {
            data.registration_number = e.target.value;
            setRegistrationNumberError(false);
        } else {
            data.registration_number = e.target.value;
            setRegistrationNumberError(true);
        }

        if (currentSchoolData) {
            currentSchoolData.registration_number = e.target.value;
        }
    }
    const handleGSTChange = (e: any) => {
        const data = { ...schoolData.value };

        if ((/^[a-z0-9]+$/.test(e.target.value) || e.target.value.length < 15)) {
            setGstNumber(true);
        } else {
            data.gst_number = e.target.value;
            setGstNumber(false);
        }

        if (currentSchoolData) {
            currentSchoolData.gst_number = e.target.value;
        }

    }
    function handleUploadLogo(e: any, type: string) {
        if (e.target.files && e.target.files[0]) {
            const fileType = e.target.files[0].name.split('.').pop()
            if (fileType == "jpeg" || fileType == "jpg") {
                const formData = new FormData();
                formData.append('file', e.target.files[0], e.target.files[0].name);
                uploadLogo(formData, type);
            } else {
                toast.error("Valid file type .jpg, .jpeg only");
            }
        }
    }
    function uploadLogo(formdata: any, type: string) {
        setImageLoader(true);
        UPLOAD_LOGO(formdata).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setImageLoader(false);
            } else {
                setSchoolLogo(res.url);
                setImageLoader(false);
            }
        });
    }
    const handleUserMechanism = (e: any) => {
        if (currentSchoolData) {
            const status = e.target.checked === true ? "ENABLED" : "DISABLED";
            currentSchoolData.access = status;
            setCheckedUserMechanismStatus(status);
        }
    }
    const handleReportStatusChange = (e: any) => {

        const status = e.target.checked === true ? 1 : 0;
        setCheckedStatus(status);

        if (currentSchoolData) {
            const status = e.target.checked === true ? 1 : 0;
            currentSchoolData.cas_report_view = e.target.checked === true ? 1 : 0;
            setCheckedStatus(status);

        }
    }
    const onChangeAmount = (e: any) => {

        setAmountError('');
        const reg = /^[0-9]*$/;
        if (e !== 0 || e !== '') {
            if (!reg.test(e)) {
                setAmountError('Amount only numbers');
            } else {

                return true;
            }
        } else {
            setAmountError('Please enter amount');
        }
    }
    function getInstituteById(schoolCode: any) {
        GET_INSTITUTE_BY_INSTITUTE_ID(schoolCode).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                if (res.rows.length > 0) {
                    setInstituteInfo(res.rows[0]);


                }
            }
        });
    }
    function getStatesData(states: any, data: any) {
        if (states?.length > 0) {
            const preparedData = states.filter((el: any) => el.state === data?.state);
            setDistrictData(preparedData[0]?.districts);
            setCityData(preparedData[0]?.cities);
            onLoadBlocks(data?.district, preparedData[0]?.block);
        }
    }
    function getSubscriptionList() {
        setLoading(true);
        GET_STUDENT_SUBSCRIPTION_PRICE_LIST("SUBSCRIPTION_PRICE", 0).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    setSubscriptionPrice(data[0].data.subscription_price)
                    setLoading(false);
                }
            }
        });
    }
    function createSchool(schoolData: any) {
        setLoading(true);
        const data = schoolData;
        delete data[''];
        CREATE_SCHOOL(data).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
                setCurrentSchoolData(data);
                setCanShowPopup(false);
            } else {
                toast.success("School Created Successfully");
                setLoading(false);
                setSchoolCode(res.school_code)
                getSchoolDetails(res.school_code)
                setCanShowPopup(false);
            }
        });
    }
    function getSchoolDetails(item: any) {
        setLoading(true);
        GET_SCHOOL_BY_SCHOOL_CODE(item).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res.rows.length > 0) {
                    const data = res.rows[0];
                    history.push(`/institute-dashboard/manage-school/list/info/${data.id}`);
                    setSchoolInfo(data);
                    setCurrentSchoolData(data);
                    setLoading(false);
                    getPrincipal(data?.school_code);
                    getvicePrivipal(data?.school_code);
                    getAdmin(data?.school_code);
                    setSchoolCode(data?.school_code);
                }

                setLoading(false);
            }
        });
    }
    const openSchoolDetails = (show: any) => {
        if (show === "view") {
            setView(!view);
            setUpdateView(false);
        }
        if (show === "update") {
            setUpdateView(!updateView);
            setView(false);
            setFormValidations(updateValidations);

        }
        if (show === "principalview") {
            setPrincipalView(!principalView)
            setAdminView(false);
            setVicePrincipalView(false);
        }
        if (show === "viceprincipalview") {
            setVicePrincipalView(!vicePrincipalView);
            setPrincipalView(false)
            setAdminView(false);
        }
        if (show === "adminview") {
            setAdminView(!adminView)
            setPrincipalView(false);
            setVicePrincipalView(false);
        }
        // setCanFormPopup(true);
    }
    function handleCancel() {
        getSchoolInformDetails();
        setUpdateView(false);
    }
    function updateCancel() {
        getPrincipal(schoolCode);
        setPrincipalView(false);
        setCanFormPopup(false);

    }
    function viceprincipalUpdateCancel() {
        getvicePrivipal(schoolCode);
        setVicePrincipalView(false);
        setCanFormPopup(false);
    }
    function adminUpdateCancel() {
        getAdmin(schoolCode);
        setAdminView(false);
        setCanFormPopup(false);
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb ">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item pe-2 text-primary">{organizationName}</li> /
                            {/* <li className="breadcrumb-item"><Link to="/institute-dashboard/dashboard">Dashboard</Link></li> / */}
                            <li className="breadcrumb-item"><Link to="/institute-dashboard/manage-school/list"><span className="text-primary">Schools</span></Link></li> /
                            <li className="breadcrumb-item">{schoolId > 0 ? schoolInfo?.name_of_school : <span>New</span>}</li>
                        </ol>
                    </nav>
                    <div className="border-bottom pb-3">
                        {/* {schoolId > 0 &&
                            <div> */}
                        <div className="d-md-flex sc-profile-details">
                            <div className="mb-3 mb-md-0">
                                <div className="sc-profile-img py-0">
                                    <img className='pr-logo' src={schoolInfo?.logo_url ? `${appConfig.FILE_UPLOAD_URL}${schoolInfo?.logo_url.charAt(0) === "/" ? schoolInfo?.logo_url.substring(1) : schoolInfo?.logo_url}` : IMGLOGO} alt="" />
                                    {/* <p className="mb-0">{schoolInfo?.short_name}</p> */}
                                    {/* <label>SCHOOL SLOGAN</label> */}
                                </div>
                            </div>
                            <div className="sc-profile-text ps-md-4">
                                <h2 className="m-0">{schoolInfo?.name_of_school}</h2>
                                <p className="m-0 sc-profile-contact"><span>{schoolInfo?.contact_number}</span> {schoolInfo?.email_id && <span className="me-1">|</span>}<span>{schoolInfo?.email_id}</span>
                                </p>
                                <p className="m-0 sc-profile-addrs"><span>{schoolInfo?.state}</span> {schoolInfo?.district && <span>|</span>} <span>{schoolInfo?.district}</span> {schoolInfo?.city_village && <span>|</span>} <span>{schoolInfo?.city_village}</span>  </p>
                                {schoolId > 0 && <div className="d-flex"> <p className="mt-3">
                                    <a className={`btn btn-primary sc-profile-viewbtn ${!view && "collapsed"}`} data-bs-toggle="collapse"
                                        href="#collapseExample" aria-expanded="false" aria-controls="collapseExample" onClick={() => openSchoolDetails("view")}>View More
                                        Information <i className="view-arrow"></i></a>
                                </p> <p className="ps-3 mt-3">    {userRole === "INSTITUTE_ADMIN" && <a className={`btn btn-primary sc-profile-viewbtn ${!updateView && "collapsed"}`} data-bs-toggle="collapse"
                                    href="#collapseExampleNew" aria-expanded="false" aria-controls="collapseExample" onClick={() => openSchoolDetails("update")}>Update <i className="view-arrow"></i></a>}</p>
                                </div>}
                            </div>
                        </div>
                        {schoolId > 0 && <div className={`collapse ms-lg-5 ps-lg-5 ${view && "show"}`} id="collapseExample">
                            <div className='ps-2   ms-lg-5'>
                                <div className="row">
                                    <div className="col-md-4 col-sm-6   mb-4">
                                        <label className="f12 text-mgray">Display Name</label>
                                        <p className="f18 mb-0">{schoolInfo?.name_of_school}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Short form Name</label>
                                        <p className="f18 mb-0">{schoolInfo?.short_name}</p>
                                    </div>

                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Date of Registration</label>
                                        <p className="f18 mb-0">{schoolInfo?.date_of_registration}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Contact Number</label>
                                        <p className="f18 mb-0">{schoolInfo?.contact_number}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Fax Number</label>
                                        <p className="f18 mb-0">{schoolInfo?.fax_number}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Office Timings</label>
                                        <p className="f18 mb-0">{schoolInfo?.timings}</p>
                                    </div>

                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">State</label>
                                        <p className="f18 mb-0">{schoolInfo?.state}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">District</label>
                                        <p className="f18 mb-0">{schoolInfo?.district}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Block</label>
                                        <p className="f18 mb-0">{schoolInfo?.['']}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Area/Locality</label>
                                        <p className="f18 mb-0">{schoolInfo?.taluka}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">City / Vilage (O)</label>
                                        <p className="f18 mb-0">{schoolInfo?.city_village}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Pincode</label>
                                        <p className="f18 mb-0">{schoolInfo?.pin_code}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Registration Number</label>
                                        <p className="f18 mb-0">{schoolInfo?.registration_number}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">UDISE Code</label>
                                        <p className="f18 mb-0">{schoolInfo?.udise_code}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">GST Number</label>
                                        <p className="f18 mb-0">{schoolInfo?.gst_number}</p>
                                    </div>

                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Cluster Code</label>
                                        <p className="f18 mb-0">{schoolInfo?.cluster_code}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Curriculum</label>
                                        <p className="f18 mb-0">{schoolInfo?.curriculum}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Category</label>
                                        <p className="f18 mb-0">{schoolInfo?.category}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Medium of Instruction</label>
                                        <p className="f12 mb-0">{mediumSelected && mediumSelected?.map((data: any, i: number) => (
                                            <span className="me-2 d-inline-block">{i + 1}.{data?.label}</span>
                                        ))}</p>

                                    </div>

                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">website address</label>
                                        <p className="f18 mb-0">{schoolInfo?.website_url}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Subscription Price</label>
                                        <p className="f18 mb-0">{schoolInfo?.subscription_price}</p>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {/* </div>} */}

                        <div className={`collapse ${(schoolId == 0 || updateView) && 'show'}`} id="collapseExampleNew">
                            <FormBuilder onUpdate={handleInput}>
                                <form>
                                    <div className="row custom-form mt-3">
                                        <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">Name of Institute</label>
                                                <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }} >*</span>

                                                <input className="form-control form-control-lg mb-0 f18" type="text" name="organization_code" disabled defaultValue={instituteInfo?.name_of_organization} placeholder="Please enter the institution" />

                                                {isFormSubmitted && !getInputValid('organization_code') && <p className="text-danger">Please select name of education society /trust /parent Institution</p>}

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">Name of School </label>
                                                <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                <input className="form-control form-control-lg mb-0 f18" type="text" name="name_of_school" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentSchoolData?.name_of_school} placeholder="Please enter name of institution" />
                                                {nameError && <p className="text-danger">{nameError}</p>}

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">Short form Name</label>
                                                <input className="form-control form-control-lg f18" type="text" name="short_name" onChange={(e) => onChangeShortformName(e.target.value)} defaultValue={currentSchoolData?.short_name} placeholder="Please enter short form of name" />
                                                {shortformNameError && <p className="text-danger">{shortformNameError}</p>}

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">Address</label>
                                                <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                <input className="form-control form-control-lg f18" type="text" name="address" onChange={(e) => onChangeAddress(e.target.value)} defaultValue={currentSchoolData?.address} placeholder="Please enter  address" />
                                                {addressError && <p className="text-danger">{addressError}</p>}

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">Contact Number (Mobile)</label>
                                                <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                <input className="form-control form-control-lg f18" type="text" name="contact_number" maxLength={10} value={currentSchoolData?.contact_number} onChange={(e) => { handleContactChange(e) }} placeholder="Please enter mobile number" />
                                                {contactNumber && <p className="text-danger">Length of contact number is 10 digits, Enter proper value</p>}
                                                {!contactNumber && isFormSubmitted && !getInputValid('contact_number') && <p className="text-danger">Please enter contact number</p>}
                                            </div>
                                        </div>
                                        {schoolId > 0 && <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">Date of Registration</label>
                                                <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                {currentSchoolData?.date_of_registration &&
                                                    <DateComponent onDate={onDateChange} idDisable={true} yearDiff={0} defaultData={currentSchoolData?.date_of_registration}></DateComponent>}
                                                {!currentSchoolData?.date_of_registration && <DateComponent idDisable={true} defaultData={todayDate} onDate={onDateChange} yearDiff={0}></DateComponent>}
                                            </div>
                                        </div>}

                                        <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">State</label>
                                                <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                <select name="state" className="form-select form-select-lg mb-3 f18" value={currentSchoolData?.state} onChange={(e) => { handleStateChange(e) }}>
                                                    <option value="">Select state</option>
                                                    {statesData.map((name: any, i: number) => (
                                                        <option key={i} value={name.state}>
                                                            {name.state}
                                                        </option>
                                                    ))}
                                                </select>
                                                {isFormSubmitted && !getInputValid('state') && <p className="text-danger">Please select state</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">District</label>
                                                <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                <select name="district" className="form-select form-select-lg mb-3 f18" value={currentSchoolData?.district} onChange={(e) => { handleDistrictChange(e) }}>
                                                    <option value="">Select district</option>
                                                    {districtData?.map((name: any, i: number) => (
                                                        <option key={i} value={name}>
                                                            {name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {isFormSubmitted && !getInputValid('district') && <p className="text-danger">Please select district</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">Block</label>
                                                <select name="taluka" className="form-select form-select-lg mb-3 f18" value={currentSchoolData?.taluka} onChange={(e) => { handleBlockChange(e) }}>
                                                    <option value="">Select block</option>
                                                    {blockData.map((name: any, i: number) => (
                                                        <option key={i} value={name}>
                                                            {name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {isFormSubmitted && !getInputValid('taluka') && <p className="text-danger">Please select block</p>}

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-2">City/Village (Optional)</label>
                                                <input className="form-control form-control-lg f18" type="text" name="city_village" defaultValue={currentSchoolData?.city_village} placeholder="Please enter village" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">PIN Code</label>
                                                <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                <input className="form-control form-control-lg f18" type="text" name="pin_code" maxLength={6} value={currentSchoolData?.pin_code} onChange={(e) => { handlePinCodeChange(e) }} placeholder="Please enter PIN code" />
                                                {pincodeNumber && <p className="text-danger">{errorMessages.pincode}</p>}
                                                {isFormSubmitted && !getInputValid('pin_code') && <p className="text-danger">Please enter pin code</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">Email ID</label>
                                                <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                <input className="form-control form-control-lg f18" type="text" name="email_id" value={currentSchoolData?.email_id} onChange={(e) => { handleEmailChange(e) }} placeholder="Please enter email" />
                                                {email && <p className="text-danger">{errorMessages.email}</p>}
                                                {isFormSubmitted && !getInputValid('email_id') && <p className="text-danger">Please enter email</p>}
                                            </div>
                                        </div>

                                        {schoolId > 0 && <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-2">Fax Number (Optional)</label>
                                                <input className="form-control form-control-lg f18" type="text" name="fax_number" maxLength={10} value={currentSchoolData?.fax_number} onChange={(e) => { handleFaxNumberChange(e) }} placeholder="Please enter fax number" />
                                                {faxNumber && <p className="text-danger">The length of the FAX number is 10 digits, Enter a proper value</p>}
                                            </div>
                                        </div>}

                                        <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">UDISE Code</label>
                                                <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                <input className="form-control form-control-lg f18" type="text" name="udise_code" maxLength={11} value={currentSchoolData?.udise_code} onChange={(e) => { handleUDISEChange(e) }} placeholder="Please enter UDISE code" />
                                                {udiseNumber && <p className="text-danger">{errorMessages.UDISE_number}</p>}
                                                {isFormSubmitted && !getInputValid('udise_code') && <p className="text-danger">Please enter UDISE Code</p>}
                                            </div>
                                        </div>
                                        {schoolId > 0 && <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-3">School Timings</label>
                                                <br />
                                                {startShowTime &&
                                                    <div className='timings-view'>
                                                        <TimeKeeper
                                                            time={startTime}
                                                            onChange={(newTime) => setStartTime(newTime.formatted12)}
                                                            onDoneClick={() => setStartShowTime(false)}
                                                            switchToMinuteOnHourSelect
                                                        />
                                                    </div>
                                                }
                                                {!startShowTime &&
                                                    <input className="form-control time-range cursor-pointer" type="text" placeholder={startTime} onClick={() => setStartShowTime(true)} />
                                                }
                                                {endShowTime &&
                                                    <div className='timings-view'>
                                                        <TimeKeeper
                                                            time={endTime}
                                                            onChange={(newTime) => setEndTime(newTime.formatted12)}
                                                            onDoneClick={() => setEndShowTime(false)}
                                                            switchToMinuteOnHourSelect
                                                        />
                                                    </div>
                                                }
                                                {!endShowTime &&
                                                    <input className="form-control time-range cursor-pointer" type="text" placeholder={endTime} onClick={() => setEndShowTime(true)} />
                                                }
                                            </div>
                                        </div>}
                                        {schoolId > 0 && <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">Cluster Code</label>
                                                <input className="form-control form-control-lg f18" type="text" name="cluster_code" maxLength={9} value={currentSchoolData?.cluster_code} onChange={(e) => { handleClusterChange(e) }} placeholder="Please enter cluster code" />
                                                {clusterNumber && <p className="text-danger">Please Enter Numbers only</p>}
                                                {isFormSubmitted && !getInputValid('cluster_code') && <p className="text-danger">Please enter cluster code</p>}
                                            </div>
                                        </div>}
                                        {schoolId > 0 && <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-2">Curriculum </label>
                                                <select className="form-select form-select-lg mb-3 f18" name="curriculum" value={currentSchoolData?.curriculum} onChange={(e) => { handleCirriculumChange(e) }}>
                                                    <option value="">Select curriculum</option>
                                                    <option value="SSC">SSC</option>
                                                    <option value="ICSE">ICSE</option>
                                                    <option value="CBSE">CBSE</option>
                                                </select>
                                            </div>
                                        </div>}
                                        {schoolId > 0 && <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">Category</label>
                                                <select className="form-select form-select-lg mb-3 f18" name="category" value={currentSchoolData?.category} onChange={(e) => { handleCategoryChange(e) }}>
                                                    <option value="">Select category</option>
                                                    <option value="Government">Government</option>
                                                    <option value="Private">Private</option>
                                                    <option value="Aided">Aided</option>
                                                    <option value="Non-aided">Non-aided</option>
                                                </select>
                                            </div>
                                        </div>}
                                        {schoolId > 0 && <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">Medium of Instruction</label>
                                                <span style={{ color: 'red', fontSize: '17px', paddingLeft: '10px' }}>*</span>
                                                <MultiSelect
                                                    options={mediumType}
                                                    value={mediumSelected}
                                                    onChange={setMediumSelected}
                                                    labelledBy="Select"
                                                />
                                                {(isFormSubmitted && mediumSelected?.length == 0) && <p className="text-danger">Please select medium of instruction</p>}
                                            </div>
                                        </div>}
                                        {schoolId > 0 && <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">Registration Number (Optional)</label>
                                                <input className="form-control form-control-lg f18" type="text" name="registration_number" defaultValue={currentSchoolData?.registration_number} onChange={(e) => onChangeRegistrationNumber(e)} placeholder="Please enter registration number" />
                                                {registrationNumberError && <p className="text-danger">Registration number must be numbers and min 1 and maximum 15 characters</p>}
                                            </div>
                                        </div>}
                                        {schoolId > 0 && <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">Website Address (Optional)</label>
                                                <input className="form-control form-control-lg f18" type="text" name="website_url" defaultValue={currentSchoolData?.website_url} placeholder="Please enter website url" />
                                            </div>
                                        </div>}
                                        {schoolId > 0 && <div className="col-md-4">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray mb-0">GST Number (Optional)</label>
                                                <input className="form-control form-control-lg f18" type="text" name="gst_number" maxLength={15} onChange={(e) => { handleGSTChange(e) }} value={currentSchoolData?.gst_number} placeholder="Please enter GST number" />
                                                {gstNumber && <p className="text-danger">The length of the GST number is 15 digits, Enter a proper value</p>}
                                            </div>
                                        </div>}


                                        {schoolId > 0 && <div className="col-md-4 text-center">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray">User Access</label><br />
                                                <label className="switch">
                                                    <input type="checkbox" name="access" value={currentSchoolData?.access} checked={currentSchoolData?.access === "ENABLED"} onChange={(e) => { handleUserMechanism(e) }} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>}
                                        {schoolId > 0 && <div className="col-md-4 d_none">
                                            <div className="mb-4">
                                                <label className="f12 text-mgray">CAS Report Accessible</label><br />
                                                <label className="switch">
                                                    <input type="checkbox" name="cas_report_view" value={currentSchoolData?.checkedStatus} checked={currentSchoolData?.cas_report_view === 1} onChange={(e) => { handleReportStatusChange(e) }} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>}
                                        {(schoolId > 0 && userRole === "SUPER_ADMIN") && <div className='col-md-4'>
                                            <label className="f12 text-mgray mb-0">Subscription Price</label>
                                            <input className="form-control form-control-lg no-spinner f18" type="number" name="subscription_price" onChange={(e) => onChangeAmount(e.target.value)} defaultValue={currentSchoolData?.subscription_price} placeholder="Please enter amount" disabled={disabled} style={{ backgroundColor: disabled ? 'hsl(0,0%,96.5%)' : 'none' }} />
                                            {amountError && <p className="text-danger">{amountError}</p>}
                                        </div>}
                                        {schoolId > 0 &&
                                            <div className="col-md-4 col-12">
                                                <div className="mb-4 d-flex justify-content-center">
                                                    <ProfileImageCrop uploadLogo={uploadLogo}
                                                        required={true}
                                                        profileImg={schoolLogo}
                                                        imageLoader={imageLoader}
                                                        profileImageDelete={setSchoolLogo}
                                                        errorMsg={`${isFormSubmitted && !schoolLogo ? "Please Upload Logo" : ""}`}
                                                        validImg={true}
                                                        uploadBtnText={"Upload Logo"}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </form>
                            </FormBuilder>
                            <div className="form-footer py-3 text-end mt-1">
                                {schoolId == 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleSubmit}>Create</a>}
                                {schoolId > 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleUpdate}>Update</a>}
                                {schoolId > 0 && <span className="cursor-pointer mx-3" onClick={handleCancel}>Cancel</span>}
                                {schoolId == 0 &&
                                    <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to="/institute-dashboard/manage-school/list">Cancel</Link>
                                }

                            </div>
                        </div>
                    </div>
                    <h3 className="my-4 section-hd">School Administration Wing Details</h3>
                    <div className="border-bottom mb-3">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="d-flex sc-profile-details small mb-4">
                                    <div className="sc-profile-img pt-0">
                                        <img className='pr-image' src={principalInfo?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${principalInfo?.profile_img.charAt(0) === "/" ? principalInfo?.profile_img.substring(1) : principalInfo?.profile_img}` : CHAIRMANLOGO} alt="" />
                                    </div>
                                    <div className="sc-profile-text ps-4">
                                        <p className="sc-profile-deg mb-1">Principal {(userRole === "INSTITUTE_ADMIN" && schoolCode) && <span onClick={() => openSchoolDetails("principalview")}><i className='bi bi-pencil-square cursor-pointer edit-icon p-2'></i></span>}</p>
                                        <h2 className="m-0">{principalInfo?.name}</h2>
                                        <p className="m-0 sc-profile-contact">
                                            <span>{principalInfo?.mobile_number}</span> {principalInfo?.email_id && <span> | </span>} <span>{principalInfo?.email_id}</span>
                                        </p>
                                        <p className="m-0 sc-profile-addrs">
                                            <span>{principalInfo?.state}</span>{principalInfo?.district && <span> | </span>}<span>{principalInfo?.district}</span> {principalInfo?.city_village && principalInfo?.district && <span> | </span>} <span>{principalInfo?.city_village}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {principalView && <ManagePrincipalForm userId={principalId} schoolsCode={schoolCode} updateCancel={updateCancel} setPrincipalView={setPrincipalView} />}

                            <div className="col-lg-6">
                                <div className="d-flex sc-profile-details small mb-4">
                                    <div className="sc-profile-img pt-0">
                                        <img className='pr-image' src={vicePricipalInfo?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${vicePricipalInfo?.profile_img.charAt(0) === "/" ? vicePricipalInfo?.profile_img.substring(1) : vicePricipalInfo?.profile_img}` : GENERALSECRETARY} alt="" />
                                    </div>
                                    <div className="sc-profile-text ps-4">
                                        <p className="sc-profile-deg mb-1">Vice Principal{(userRole === "INSTITUTE_ADMIN" && schoolCode) && <span onClick={() => openSchoolDetails("viceprincipalview")}><i className='bi bi-pencil-square cursor-pointer edit-icon p-2'></i></span>}</p>
                                        <h2 className="m-0">{vicePricipalInfo?.name}</h2>
                                        <p className="m-0 sc-profile-contact">
                                            <span>{vicePricipalInfo?.mobile_number}</span> {vicePricipalInfo?.email_id && <span> | </span>} <span>{vicePricipalInfo?.email_id}</span>
                                        </p>
                                        <p className="m-0 sc-profile-addrs">
                                            <span>{vicePricipalInfo?.state}</span>{vicePricipalInfo?.district && <span> | </span>}<span>{vicePricipalInfo?.district}</span> {vicePricipalInfo?.city_village && vicePricipalInfo?.district && <span> | </span>} <span>{vicePricipalInfo?.city_village}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {vicePrincipalView && <ManageVicePrincipalForm userId={viceprincipalId} schoolsCode={schoolCode} updateCancel={viceprincipalUpdateCancel} setVicePrincipalView={setVicePrincipalView} />}

                            <div className="col-lg-6">
                                <div className="d-flex sc-profile-details small mb-4">
                                    <div className="sc-profile-img pt-0">
                                        <img className='pr-image' src={adminInfo?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${adminInfo?.profile_img.charAt(0) === "/" ? adminInfo?.profile_img.substring(1) : adminInfo?.profile_img}` : INSTITUTEADMIN} alt="" />
                                    </div>
                                    <div className="sc-profile-text ps-4">
                                        <p className="sc-profile-deg mb-1">School Admin {(userRole === "INSTITUTE_ADMIN" && schoolCode) && <span onClick={() => openSchoolDetails("adminview")}><i className='bi bi-pencil-square cursor-pointer edit-icon p-2'></i></span>}</p>
                                        <h2 className="m-0">{adminInfo?.name}</h2>
                                        <p className="m-0 sc-profile-contact">
                                            <span>{adminInfo?.mobile_number}</span>  {adminInfo?.email_id && <span> | </span>} <span>{adminInfo?.email_id}</span>
                                        </p>
                                        <p className="m-0 sc-profile-addrs">
                                            <span>{adminInfo?.state}</span>{adminInfo?.district && <span> | </span>}<span>{adminInfo?.district}</span> {adminInfo?.city_village && adminInfo?.district && <span> | </span>} <span>{adminInfo?.city_village}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {adminView && <ManageAdminForm userId={adminId} schoolsCode={schoolCode} adminUpdateCancel={adminUpdateCancel} setAdminView={setAdminView} />}
                        </div>
                    </div>

                    {openSchoolsId && <ul className="nav nav-tabsnewui  custom-tabsnewui " id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <NavLink
                                className={
                                    splitLocation[6] === "teachersform"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                to={{
                                    pathname: `/institute-dashboard/manage-school/list/info/${schoolId}/teacherslist/${schoolCode}`,
                                    state: { data: "" },
                                }}
                            >
                                Teachers
                            </NavLink>

                        </li>
                        <li className="nav-item" role="presentation">
                            <NavLink
                                className={
                                    splitLocation[6] === "studentsform"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                to={{
                                    pathname: `/institute-dashboard/manage-school/list/info/${schoolId}/studentslist/${schoolCode}`,
                                    state: { data: "" },
                                }}
                            >
                                Students
                            </NavLink>

                        </li>
                        <li className="nav-item" role="presentation">
                            <NavLink
                                className={
                                    splitLocation[5] === "studentcount"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                to={{
                                    pathname: `/institute-dashboard/manage-school/list/info/${schoolId}/student-count-list/${schoolCode}`,
                                    state: { data: "" },
                                }}
                            >
                                Student Count
                            </NavLink>

                        </li>
                        <li className="nav-item" role="presentation">
                            <NavLink
                                className={
                                    splitLocation[5] === "modules"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                to={{
                                    pathname: `/institute-dashboard/manage-school/list/info/${schoolId}/modulelist/${schoolCode}`,
                                    state: { data: "" },
                                }}
                            >
                                Modules
                            </NavLink>

                        </li>
                        <li className="nav-item" role="presentation">
                            <NavLink
                                className={
                                    splitLocation[5] === "payments"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                to={{
                                    pathname: `/institute-dashboard/manage-school/list/info/${schoolId}/paymentlist/${schoolCode}`,
                                    state: { data: "" },
                                }}
                            >
                                Payments
                            </NavLink>
                        </li>
                    </ul>}
                    <div className="mt-3">
                        <SchoolinsideRoutings />
                    </div>
                </div>}

        </div>
    )
}

