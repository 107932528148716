import React from "react";
import { useHistory } from "react-router-dom";
import { ContentTopicList } from "../../../../components/topics/list";

export const ContentTopic = () => {
    const history = useHistory();


    return (
        <div>
            <div className="border-top border-primary py-3">
                <ContentTopicList isLinked={"false"} display={""} boardUuid={undefined} mediumUuid={undefined} standardUuid={undefined} subjectUuid={undefined} chapterUuid={undefined} navigateurl="/dashboard/content/upload" />
            </div>
        </div>
    )
}