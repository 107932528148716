import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

interface WorksheetChartProps {
    subjectsPercent: string[];
    graphSubjects: Highcharts.SeriesOptionsType[];
}

const ParikshaWorksheetColumnChart: React.FC<WorksheetChartProps> = ({ subjectsPercent, graphSubjects }) => {
    const options: Highcharts.Options = {
        chart: {
            type: 'column',
            backgroundColor: 'transparent',
            height: 350,
            // margin: [50, 50, 100, 50],
        },
        title: {
            text: 'Tests conducted in the week',
            align: 'center',
            style: {
                color: 'black',
                fontSize: '17px',
                fontWeight: 'normal',
            },
        },
        xAxis: {
            lineWidth: 0,
            categories: subjectsPercent,
            type: 'category',
            title: {
                text: 'Obtained Marks',
                style: {
                    fontSize: '14px',
                    width: 200,
                    fontWeight: 'normal',
                    color: 'black',
                },
            },
            labels: {
                style: {
                    color: 'black',
                    fontSize: '10px',
                    fontWeight: 'normal',
                },
            },
        },
        yAxis: {
            title: {
                text: 'Number of Students',
                style: {
                    fontSize: '14px',
                    width: 200,
                    fontWeight: 'normal',
                    color: 'black',
                },
            },
            tickPixelInterval: 50,
            gridLineColor: '#D9D9D9',
            gridLineWidth: 1,
            min: 0,
            max: 100,
        },
        legend: {
            symbolWidth: 8,
            symbolHeight: 8,
            symbolRadius: 0,
            symbolPadding: 5,
            y: -1,
            itemStyle: {
                cursor: 'default', // Change cursor to default to indicate non-interactivity
            },
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    style: {
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: 'normal',
                    },
                },
                events: {
                    legendItemClick: function () {
                        return false;
                    },
                },
            },
            series: {
                allowPointSelect: false,
            },
        },
        series: graphSubjects,
        credits: {
            enabled: false,
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ParikshaWorksheetColumnChart;