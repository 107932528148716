import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { GET_BOARD_FILTERS_LIST, GET_CHAPTER_FILTERS_LIST, GET_MEDIUM_FILTERS_LIST, GET_STANDARD_FILTERS_LIST, GET_SUBJECT_FILTERS_LIST, GET_TOPIC_FITERS_LIST } from '../../../../app/service/abhyas-worksheet-filter.service';
import FormBuilder from '../../../../components/form-builder';
import ContentValidationStatusQuestionsList from './questions-list';
import { Breadcum } from '../../../../components/breadcum';
import { NavLink } from 'react-router-dom';
import { GET_CONTENT_QUESTIONS, UPDATE_CONTENT_QUESTION } from '../../../../app/service/content-module/question-service';
import { MathJax, MathJaxContext } from 'better-react-mathjax';




export const ContentValidationQuestions = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [mediumLoading, setMediumLoading] = useState<boolean>(false);
    const [standardLoading, setStandardLoading] = useState<boolean>(false);
    const [subjectLoading, setSubjectLoading] = useState<boolean>(false);
    const [chapterLoading, setChapterLoading] = useState<boolean>(false);
    const [topicLoading, setTopicLoading] = useState<boolean>(false);
    const [boardsList, setBoardsList] = useState<any>([]);
    const [mediumList, setMediumList] = useState<any>([]);
    const [standardList, setStandardList] = useState<any>([]);
    const [subjectList, setSubjectList] = useState<any>([]);
    const [chapterList, setChapterList] = useState<any>([]);
    const [topicList, setTopicList] = useState<any>([]);
    const [filterData, setFilterData] = useState<any>({});
    const [validatioStatus, setValidationStatus] = useState<string>("ALL");
    const [showQuestions, setshowQuestions] = useState<boolean>(false);
    const [questionsList, setQuestionsList] = useState<any>([]);
    const [questionsLoading, setQuestionsLoading] = useState<boolean>(false);
    const [topicName, setTopicName] = useState<string>("ALL");
    const filterForm = useRef<any>({});
    const [key, setKey] = useState<number>(0);
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: 'Content',
            value: 'content',
            routerLink: '/dashboard/content/view',
            isActive: false
        },
        {
            label: 'Content Validation',
            value: 'Content Validation',
            routerLink: '',
            isActive: true
        }
    ]);

    useEffect(() => {
        getBoardData();

    }, [])

    const getBoardData = () => {
        setLoading(true);
        GET_BOARD_FILTERS_LIST().then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    setBoardsList(data);
                    setLoading(false);
                }
            }
        });
    }
    const handleInput = (data: any) => {
        let preData = { ...data.value }
        setFilterData(preData);
        setshowQuestions(false);
    };
    const onChangeBoard = (e: any) => {
        setMediumList([]);
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setTopicList([]);
        if (e) {
            setMediumLoading(true);
            GET_MEDIUM_FILTERS_LIST(e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setMediumLoading(false);
                    } else {
                        const data = res['rows'];
                        setMediumList(data);
                        setMediumLoading(false);
                    }
                }
            });
        }
    }
    const onChangeMedium = (e: any) => {
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setTopicList([]);
        if (e) {
            setStandardLoading(true)
            GET_STANDARD_FILTERS_LIST(filterData?.board_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setStandardLoading(false);
                    } else {
                        const data = res['rows'];
                        setStandardList(data);
                        setStandardLoading(false);
                    }
                }
            });
        }
    }
    const onChangeStandard = (e: any) => {
        setSubjectList([]);
        setChapterList([]);
        setTopicList([]);
        if (e) {
            setSubjectLoading(true);
            GET_SUBJECT_FILTERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setSubjectLoading(false);
                    } else {
                        const data = res['rows'];
                        setSubjectList(data);
                        setSubjectLoading(false);
                    }
                }
            });
        }
    }
    const onChangeSubject = (e: any) => {
        setChapterList([]);
        setTopicList([]);
        if (e) {
            setChapterLoading(true);
            GET_CHAPTER_FILTERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, filterData?.standard_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setChapterLoading(false);
                    } else {
                        const data = res['rows'];
                        setChapterList(data);
                        setChapterLoading(false);
                    }
                }
            });
        }
    }
    const onChangeChapter = (e: any) => {
        setTopicList([]);
        if (e) {
            setTopicLoading(true);
            GET_TOPIC_FITERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, filterData?.standard_uuid, filterData?.subject_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setTopicLoading(false);

                    } else {
                        const data = res['rows'];
                        setTopicList(data);
                        setTopicLoading(false);

                    }
                }
            });
        }
    }
    const onChangeTopic = (e: any, name?: string) => {
        filterData.topic_uuid = e;
        const topicNames = topicList?.filter((item: any) => item.uuid === e);
        if (topicNames?.length > 0) {
            setTopicName(topicNames[0].name);
        } else {
            setTopicName("");
        }
    }

    const handleRefresh = () => {
        setFilterData({});
        setKey((prev) => prev + 1);
        setMediumList([]);
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setTopicList([]);
        filterForm.current.reset();
        setTopicName("");
    }
    const validationFilters = () => {
        if (filterData?.board_uuid && filterData?.medium_uuid && filterData?.standard_uuid && filterData?.subject_uuid && filterData?.chapter_uuid && filterData?.topic_uuid) {
            return false;
        } else {
            return true;
        }
    }
    const getQuestionList = () => {
        setshowQuestions(true);
        setQuestionsLoading(true);
        getQuestionsList();
    }
    const getQuestionsList = () => {
        const payLoad = {
            board_uuid: filterData?.board_uuid,
            medium_uuid: filterData?.medium_uuid,
            standard_uuid: filterData?.standard_uuid,
            subject_uuid: filterData?.subject_uuid,
            chapter_uuid: filterData?.chapter_uuid,
            topic_uuid: filterData?.topic_uuid,
            validation_status: validatioStatus
        }
        GET_CONTENT_QUESTIONS(payLoad, 0, 1000000).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setQuestionsLoading(false);
                } else {
                    const data = res?.data;
                    setQuestionsList(data);
                    setQuestionsLoading(false);
                }
            }
        });
    }
    function onViewQuestion(name: any) {
        return { __html: name };
    }
    const onChangeRemark = (value: string, index: number) => {
        const data = questionsList
        data[index].remarks = value;
        setQuestionsList([...data]);
    }
    const onChangesStatus = (value: string, index: number) => {
        const data = questionsList
        data[index].validation_status = value;
        setQuestionsList([...data]);
    }
    const updateQuestionStatus = (index: number) => {
        const data = questionsList
        delete data[index].created_dt;
        delete data[index].updated_dt;
        UPDATE_CONTENT_QUESTION(data[index]?.uuid, data[index]).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
            } else {
                toast.success(res.message);
                getQuestionsList();
            }
        });
    }
    return (
        <div className="row border-top border-primary py-3" >
            <Breadcum breadcumList={breadcumInfo} />
            <ul className="nav nav-tabs custom-tabs scrolling_nav">
                <li className="nav-item">
                    <NavLink className="nav-link active" to={{ pathname: `/dashboard/content/question-validation`, state: { data: "" } }}>Question Validation</NavLink>
                </li>

            </ul>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className='my-3' key={key}>
                    <FormBuilder onUpdate={handleInput}>
                        <form ref={filterForm}>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className='mb-4'>
                                        <label>Board</label>
                                        <select name="board_uuid" id="board_uuid" className="form-select ft-14 " onChange={(e) => { onChangeBoard(e.target.value) }}>
                                            <option value="">Select Board</option>
                                            {boardsList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4 position-relative'>
                                        <label>Medium</label>
                                        <select name="medium_uuid" id="medium_uuid" className="form-select ft-14 " onChange={(e) => { onChangeMedium(e.target.value) }}>
                                            <option value="">Select Medium</option>
                                            {mediumList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Standard</label>
                                        <select name="standard_uuid" id="standard_uuid" className="form-select ft-14 " onChange={(e) => { onChangeStandard(e.target.value) }}>
                                            <option value="">Select Standard</option>
                                            {standardList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.standard}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Subject</label>
                                        <select name="subject_uuid" id="subject_uuid" className="form-select ft-14 " onChange={(e) => { onChangeSubject(e.target.value) }}>
                                            <option value="">Select Subject</option>
                                            {subjectList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Chapter</label>
                                        <select name="chapter_uuid" id="chapter_uuid" className="form-select ft-14 " onChange={(e) => { onChangeChapter(e.target.value) }}>
                                            <option value="">Select Chapter</option>
                                            {chapterList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Topic</label>
                                        <select name="topic_uuid" id="topic_uuid" className="form-select ft-14 " onChange={(e) => { onChangeTopic(e.target.value) }}>
                                            <option value="">Select Topic</option>
                                            {topicList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Validation status</label>
                                        <select name="status" id="status" className="form-select ft-14 " onChange={(e) => { setValidationStatus(e.target.value) }}>
                                            <option value="ALL">All</option>
                                            <option value="PENDING">Pending</option>
                                            <option value="VERIFIED">Verified</option>
                                            <option value="RE-PHASE">Re-Phase</option>
                                            <option value="HOLD">Hold</option>
                                            <option value="REJECT">Reject</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3 ">
                                    {(!validationFilters() && showQuestions) ?
                                        <button className="btn btn-primary cursor-pointer mt-4" type="button" onClick={() => handleRefresh()}>
                                            Clear
                                        </button> :
                                        <button className="btn btn-primary cursor-pointer mt-4" disabled={validationFilters()} type="button" onClick={() => getQuestionList()}>
                                            Go to Question Validation
                                        </button>
                                    }
                                </div>
                            </div>
                        </form>
                    </FormBuilder>
                    {/* // Question // */}
                    {(!validationFilters() && showQuestions) && <div>
                        {/* <ContentValidationStatusQuestionsList boardUuid={filterData?.board_uuid} mediumUuid={filterData?.medium_uuid} standardUuid={filterData?.standard_uuid} subjectUuid={filterData?.subject_uuid} chapterUuid={filterData?.chapter_uuid} topicUuid={filterData?.topic_uuid} validationStatus={validatioStatus}
                        /> */}

                        {questionsLoading &&
                            <div className="text-center p-5">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {!questionsLoading &&
                            <div>
                                {questionsList?.length > 0 && <div className='d-flex justify-content-between bg_secondary_light p-2'>
                                    <p className='f18  mb-0'>TOPIC : <span className='text-primary'>{topicName}</span></p>
                                    <p className='f18 fw-bold  mb-0'>Total Questions : {questionsList?.length}</p>
                                </div>}
                                <MathJaxContext>
                                    <MathJax>
                                        {questionsList?.map((question: any, i: number) => (
                                            <div className="mt-4" key={i}>
                                                <div className='row'>
                                                    <div className='col-md-8'>
                                                        <div className='row'>
                                                            <div className='col-12' style={{ paddingTop: '15px' }}>
                                                                <div className='d-flex'>
                                                                    <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                                        Q{question.id}:
                                                                    </p>
                                                                    <div className='ms-4 ps-2'>
                                                                        <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                                            <div dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='ms-5 ps-5'>
                                                            {question.options.map((options: any, j: number) => (
                                                                <div className='d-flex' key={j}>
                                                                    <div className=''>
                                                                        <span>{options.option}.</span>
                                                                    </div>
                                                                    <div className='ms-3'>
                                                                        <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className='ms-5 ps-5'>
                                                            <p className='f14 mb-0' >
                                                                <span className='fw-bold me-2'> Answer:</span>{question?.correct_answer && question?.correct_answer}
                                                            </p>
                                                            {question?.explanation && <p className='f14 mb-0 d-flex' >
                                                                <span className='fw-bold me-2'>Explanation:</span>{question?.explanation && <span dangerouslySetInnerHTML={onViewQuestion(question?.explanation)}></span>}
                                                            </p>}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2' style={{ paddingTop: '15px' }}>
                                                        <div className='mb-4  position-relative'>
                                                            <label>Validation status</label>
                                                            <select name="status" id="status" className="form-select ft-14 " value={question?.validation_status} onChange={(e) => { onChangesStatus(e.target.value, i) }}>
                                                                <option value="ALL">All</option>
                                                                <option value="PENDING">Pending</option>
                                                                <option value="VERIFIED">Verified</option>
                                                                <option value="RE-PHASE">Re-Phase</option>
                                                                <option value="HOLD">Hold</option>
                                                                <option value="REJECT">Reject</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2' style={{ paddingTop: '15px' }}>
                                                        <label>Remark</label>
                                                        <textarea className='form-control' defaultValue={question?.remarks} onChange={(e) => onChangeRemark(e.target.value, i)} />
                                                        <div className='mt-3'>
                                                            <button className='btn btn-primary' onClick={() => updateQuestionStatus(i)}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        ))}
                                    </MathJax>
                                </MathJaxContext>
                                {questionsList?.length == 0 && <p className='text-center'>No Questions Found</p>}
                            </div>
                        }
                    </div>}
                </div>
            }
        </div>
    )
}

