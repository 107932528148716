import React from "react";
import NestedAbhyasList from "../../../../routes/insideabhyas";
import NestedContentList from "../../../../routes/content-uploads/inside-content-info";
export const UploadInfo = () => {
    return (
        <div>
            <div>
                <NestedContentList />
            </div>
        </div>
    )
}