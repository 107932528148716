import API from "../../utility/axios";
const apiUrl = "/api/v2";

export const CREATE_QUESTION_SOURCE = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/questions/source`, data);
};

export const UPDATE_QUESTION_SOURCE = (uuid: any, data: any): Promise<any> => {
  return API.put(`${apiUrl}/questions/source/${uuid}`, data);
};

export const GET_QUESTION_SOURCE = (pageNumber: number): Promise<any> => {
  return API.get(
    `${apiUrl}/questions/source?limit=${10}&page_num=${pageNumber}`
  );
};

export const DELETE_QUESTION_SOURCE = (uuid: number): Promise<any> => {
  return API.delete(`${apiUrl}/questions/source/${uuid}`);
};

export const GET_QUESTION_SOURCES_list = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/questions/source`, { params: data });
};

// export const GET_DEPARTMENT_SEARCH = (data: any): Promise<any> => {
//   return API.get(`${apiUrl}/department?limit=${10}&page_num=${0}`, {
//     params: data,
//   });
// };
