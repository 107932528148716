import React, { useEffect, useRef, useState } from "react";
import { CREATE_QUESTION_SOURCE, DELETE_QUESTION_SOURCE, GET_QUESTION_SOURCES_list, UPDATE_QUESTION_SOURCE } from "../../../../../app/service/content-module/question-source";
import { toast } from "react-toastify";


export const ContentAddQuestionSource = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [currentSourceData, setcurrentSourceData] = useState<any>({});
    const [sourceList, setSourceList] = useState<any>([]);
    const [sourceName, setSourceName] = useState<string>("");
    const sourceForm = useRef<any>({});

    useEffect(() => {
        fetchData();
    }, [])
    const fetchData = async () => {
        setLoading(true);
        try {
            const payLoad = {
                limit: 100000
            }
            const res: any = await GET_QUESTION_SOURCES_list(payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.message);
                    setLoading(false);
                } else {
                    const data = res?.data;
                    setSourceList(data);
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }
    const editSource = (item: any) => {
        setSourceName(item?.source);
        setcurrentSourceData(item);
        setCanShowPopup(true);
    }
    const deleteSource = async (item: any) => {
        try {
            const res: any = await DELETE_QUESTION_SOURCE(item?.uuid);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.message);
                } else {
                    fetchData();
                }
            }
        } catch (error) {
        }
    }
    const addNewSource = () => {
        handleRefresh();
        setCanShowPopup(true);
    }
    const handleSubmit = async () => {
        try {
            const payLoad = {
                source: sourceName
            }
            const res: any = await CREATE_QUESTION_SOURCE(payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.message);
                } else {
                    setCanShowPopup(false);
                    fetchData();
                }
            }
        } catch (error) {
        }
    }
    const handleUpdate = async () => {
        try {
            const payLoad = {
                source: sourceName
            }
            const res: any = await UPDATE_QUESTION_SOURCE(currentSourceData?.uuid, payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.message);
                } else {
                    setCanShowPopup(false);
                    fetchData();
                }
            }
        } catch (error) {
        }
    }
    function handleCancel() {
        setSourceName('');
        setcurrentSourceData({});
        sourceForm.current.reset();
        setCanShowPopup(false);
    }
    function handleRefresh() {
        setSourceName('');
        setcurrentSourceData({});
        sourceForm.current.reset();
    }
    return (
        <div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="payment_modal-dialog_popup " role="document">
                    <div className="modal-content pariksha-model-content" style={{ width: "500px" }}>
                        <div className="modal-body  payment_user_popup_info position-relative">
                            <button type="button" className="close popup-close position-absolute" data-dismiss="modal" aria-label="Close" onClick={handleCancel} style={{ right: "20px" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className='payment_user_popup_block '>
                                <div className='mt-1'>
                                    <h6 className="f20 border-bottom border-primary pb-3 text-center">{currentSourceData?.source ? "Update" : "Add New"} Question Source</h6>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-8 mt-5 mb-2">
                                            <form ref={sourceForm}>
                                                <input className="form-control form-control-lg source_filed_bg" type="text" name="source" defaultValue={sourceName} onChange={(e) => setSourceName(e.target.value)} />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <div className='mt-3 d-flex' style={{ justifyContent: 'center' }}>
                                        {currentSourceData?.source ? <button className='btn question_source_btn  f18 px-4  payment_mem_text py-2' onClick={handleUpdate} disabled={!sourceName}>Update</button>
                                            : <button className='btn question_source_btn  f18 px-4  payment_mem_text py-2' onClick={handleSubmit} disabled={!sourceName}>Submit</button>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-5">
                <div className="col-12 col-md-10">
                    <h6 className="border-bottom pb-3 text-center f20">Question's Source</h6>
                    <div className="text-end">
                        <button className="btn btn-primary" onClick={addNewSource}>Add new Source</button>
                    </div>
                    <div>
                        {loading &&
                            <div className="text-center p-5">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {!loading && <div className="row justify-content-center mt-5">
                            <div className="col-12 col-md-10">
                                <div className="custom_table_scroll">
                                    <table className="content_question_source_table">
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>Question's Source</th>
                                                <th> Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sourceList?.map((data: any, i: number) => (
                                                <tr key={i} className={`${i % 2 != 0 && "odd"}  `}>
                                                    <td>{i + 1}</td>
                                                    <td className="f18">{data?.source}</td>
                                                    <td>
                                                        <i title="Edit" className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={() => editSource(data)}></i>
                                                        <i title="Delete" className="bi bi-trash cursor-pointer p-2" onClick={() => deleteSource(data)}></i>
                                                    </td>
                                                </tr>
                                            )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {sourceList?.length == 0 && <p className="f16 text-center mt-2">No data found</p>}
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}