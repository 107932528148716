import React, { useEffect, useRef, useState } from 'react'
import { GET_BOARD_FILTERS_LIST, GET_CHAPTER_FILTERS_LIST, GET_MEDIUM_FILTERS_LIST, GET_STANDARD_FILTERS_LIST, GET_SUBJECT_FILTERS_LIST, GET_TOPIC_FITERS_LIST } from '../../../../app/service/abhyas-worksheet-filter.service';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import FormBuilder from '../../../../components/form-builder';
import moment from 'moment';
import { CREATE_ABHYAS_QUESTION, DELETE_ABHYAS_QUESTION, GET_ABHYAS_QUESTION_LIST, UPDATE_ABHYAS_QUESTION } from '../../../../app/service/abhyas-question.service';
import { AbhyasMediumList } from '../abhyas/medium/list';
import { AbhyasBoardList } from '../abhyas/board/list';
import { GET_PARIKSHA_SELECT_TEST_LIST, GET_PARIKSHA_TEST_FILTER, GET_PARIKSHA_TEST_LIST } from '../../../../app/service/pariksha-test.service';
import { AbhyasStandardList } from '../abhyas/standard/list';
import { AbhyasSubjectList } from '../abhyas/subject/list';
import { AbhyasChapterList } from '../abhyas/chapter/list';
import { ParikshaTestDataList } from './test/list';
import ParikshaQuestionsList from './question/list';
import { AbhyasTopicList } from '../abhyas/topic/list';
import { GET_BOARD_FILTER_LIST, GET_CHAPTER_FILTER_LIST, GET_MEDIUM_FILTER_LIST, GET_STANDARD_FILTER_LIST, GET_SUBJECT_FILTER_LIST } from '../../../../app/service/common-module.service';


let newFilterData: any = {
    board_uuid: '',
    medium_uuid: '',
    standard_uuid: '',
    subject_uuid: '',
    chapter_uuid: '',
    exam_type: ''
};

export const ParikshaTestInfoList = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [standardLoading, setStandardLoading] = useState<boolean>(false);
    const [subjectLoading, setSubjectLoading] = useState<boolean>(false);
    const [chapterLoading, setChapterLoading] = useState<boolean>(false);
    const [questionsLoading, setQuestionsLoading] = useState<boolean>(false);
    const [boardsList, setBoardsList] = useState<any>([]);
    const [mediumList, setMediumList] = useState<any>([]);
    const [standardList, setStandardList] = useState<any>([]);
    const [subjectList, setSubjectList] = useState<any>([]);
    const [chapterList, setChapterList] = useState<any>([]);
    const [testList, setTestList] = useState<any>([]);
    const [topicList, setTopicList] = useState<any>([]);
    const [questionsList, setQuestionsList] = useState<any>([]);
    const [filterData, setFilterData] = useState<any>({
        board_uuid: '',
        medium_uuid: '',
        standard_uuid: '',
        subject_uuid: '',
        chapter_uuid: '',
        exam_type: ''
    });
    const [showComponent, setShowComponent] = useState<string>("");
    const filterForm = useRef<any>({});
    ///  Questions///
    const [testsLoading, setTestsLoading] = useState<boolean>(true);
    const [canShowTestQuestions, setCanShowTestQuestions] = useState<boolean>(false);
    const [testInfo, setTestInfo] = useState<any>({});
    const [canSelectTest, setCanSelectTest] = useState<boolean>(false);

    useEffect(() => {
        getBoardData();
    }, [])

    const getBoardData = () => {
        setLoading(true);
        GET_BOARD_FILTER_LIST().then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    setBoardsList(data);
                    setLoading(false);
                }
            }
        });
    }


    const handleInput = (data: any) => {
        let preData = { ...data.value }
        setFilterData(preData);
    };
    const onChangeBoard = (e: any) => {
        setTestsLoading(false);
        setCanShowTestQuestions(true);
        setMediumList([]);
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setTestList([]);
        setQuestionsList([]);
        setCanShowTestQuestions(false);
        if (e) {

            newFilterData = {
                ...newFilterData, board_uuid: e,
                medium_uuid: undefined,
                standard_uuid: undefined,
                subject_uuid: undefined,
                chapter_uuid: undefined,
                exam_type: undefined
            }
            getmediumData(e);

        } else {
            newFilterData = {
                ...newFilterData, board_uuid: '',
                medium_uuid: undefined,
                standard_uuid: undefined,
                subject_uuid: undefined,
                chapter_uuid: undefined,
                exam_type: undefined
            }
            setTestsLoading(true);
        }

        const data = testInfo;
        data.board_uuid = e;
        data.medium_uuid = '';
        data.standard_uuid = '';
        data.subject_uuid = '';
        data.chapter_uuid = '';
        data.exam_type = '';
        setTestInfo({ ...data });

    }

    const getmediumData = (id: any) => {
        GET_MEDIUM_FILTER_LIST(id).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setTestsLoading(true);

                } else {
                    const data = res['rows'];
                    setMediumList(data);
                    setTestsLoading(true);

                }
            }
        });

    }

    const onChangeMedium = (e: any) => {
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setTestList([]);
        setQuestionsList([]);


        // setCount(1);
        // setShowComponent(filterData?.board_uuid);
        setTestsLoading(false);
        if (e) {
            newFilterData = {
                ...newFilterData, medium_uuid: e,
                standard_uuid: undefined,
                subject_uuid: undefined,
                chapter_uuid: undefined,
                exam_type: undefined
            }
            // setCount(2);
            getStandardData(e);
            // GET_STANDARD_FILTERS_LIST(filterData?.board_uuid, e).then((res: any) => {
            //     if (res) {
            //         if (res.status === 'fail') {
            //             toast.error(res?.err);
            //             setStandardLoading(false);
            //             setTestsLoading(true);
            //         } else {
            //             const data = res['rows'];
            //             setStandardList(data);
            //             setStandardLoading(false);
            //             setTestsLoading(true);
            //         }
            //     }
            // });
        } else {
            newFilterData = {
                ...newFilterData, medium_uuid: ''
            }
            setTestsLoading(true);
        }

        const data = testInfo;
        data.medium_uuid = e;
        data.standard_uuid = '';
        data.subject_uuid = '';
        data.chapter_uuid = '';
        data.exam_type = '';
        setTestInfo({ ...data });
    }

    const getStandardData = (id: any) => {
        // GET_STANDARD_FILTER_LIST(filterData?.board_uuid, id).then((res: any) => {
        GET_STANDARD_FILTER_LIST(id).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setStandardLoading(false);
                    setTestsLoading(true);
                } else {
                    const data = res['rows'];
                    setStandardList(data);
                    setStandardLoading(false);
                    setTestsLoading(true);
                }
            }
        });
    }

    const onChangeStandard = (e: any) => {
        setSubjectList([]);
        setChapterList([]);
        setTestList([]);
        setQuestionsList([]);
        setTestsLoading(false);
        // setCount(2);
        // setShowComponent(filterData?.medium_uuid);


        if (e) {
            newFilterData = {
                ...newFilterData, standard_uuid: e,
                subject_uuid: undefined,
                chapter_uuid: undefined,
                exam_type: undefined
            }
            setShowComponent(e);
            // setCount(3);
            setSubjectLoading(true);
            getSubjectsData(e);
            // GET_SUBJECT_FILTERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, e).then((res: any) => {
            //     if (res) {
            //         if (res.status === 'fail') {
            //             toast.error(res?.err);
            //             setSubjectLoading(false);
            //             setTestsLoading(true);
            //         } else {
            //             const data = res['rows'];
            //             setSubjectList(data);
            //             setSubjectLoading(false);
            //             setTestsLoading(true);
            //         }
            //     }
            // });
        } else {
            newFilterData = {
                ...newFilterData, standard_uuid: ''
            }
            setTestsLoading(true);
        }

        const data = testInfo;
        data.standard_uuid = e;
        data.subject_uuid = '';
        data.chapter_uuid = '';
        data.exam_type = '';
        setTestInfo({ ...data });
    }

    const getSubjectsData = (id: any) => {
        // GET_SUBJECT_FILTER_LIST(filterData?.board_uuid, filterData?.medium_uuid, id).then((res: any) => {
        GET_SUBJECT_FILTER_LIST(id).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setSubjectLoading(false);
                    setTestsLoading(true);
                } else {
                    const data = res['rows'];
                    setSubjectList(data);
                    setSubjectLoading(false);
                    setTestsLoading(true);
                }
            }
        });
    }
    // const onChangeSubject = (e: any) => {
    //     setChapterList([]);
    //     setTestList([]);
    //     setQuestionsList([]);
    //     setCount(3);
    //     setShowComponent(filterData?.standard_uuid);
    //     if (e) {
    //         setCount(4);
    //         setShowComponent(e);
    //         setChapterLoading(true);
    //         GET_PARIKSHA_SELECT_TEST_LIST(filterData?.board_uuid, filterData?.medium_uuid, filterData?.standard_uuid, e, "TESTS", "PARIKSHA").then((res: any) => {
    //             if (res) {
    //                 if (res.status === 'fail') {
    //                     toast.error(res?.err);
    //                     setTopicLoading(false);

    //                 } else {
    //                     const data = res['rows'];
    //                     setTestList(data);
    //                     setTopicLoading(false);


    //                 }
    //             }
    //         });
    //     }
    // }
    const onChangeSubject = (e: any) => {
        setChapterList([]);
        // setWorksheetList([]);
        setTopicList([]);
        setQuestionsList([]);
        // setCount(3);
        // setShowComponent(filterData?.standard_uuid);

        setTestsLoading(false);
        if (e) {
            newFilterData = {
                ...newFilterData, subject_uuid: e,
                chapter_uuid: undefined,
                exam_type: undefined
            }
            // setCount(4);
            // setShowComponent(e);
            // setChapterLoading(true);
            getChaptersData(e);
            // GET_CHAPTER_FILTERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, filterData?.standard_uuid, e).then((res: any) => {
            //     if (res) {
            //         if (res.status === 'fail') {
            //             toast.error(res?.err);
            //             setChapterLoading(false);
            //             setTestsLoading(true);

            //         } else {
            //             const data = res['rows'];
            //             setChapterList(data);
            //             setChapterLoading(false);
            //             setTestsLoading(true);

            //         }
            //     }
            // });
        } else {
            setTestsLoading(true);
        }
        const data = testInfo;
        data.subject_uuid = e;
        data.chapter_uuid = '';
        data.exam_type = '';
        setTestInfo({ ...data });
    }

    const getChaptersData = (id: any) => {
        // GET_CHAPTER_FILTER_LIST(filterData?.board_uuid, filterData?.medium_uuid, filterData?.standard_uuid, id).then((res: any) => {
        GET_CHAPTER_FILTER_LIST(id).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setChapterLoading(false);
                    setTestsLoading(true);

                } else {
                    const data = res['rows'];
                    setChapterList(data);
                    setChapterLoading(false);
                    setTestsLoading(true);
                }
            }
        });
    }

    const onChangeChapter = (e: any) => {
        setTestList([]);
        setQuestionsList([]);
        // setCount(4);
        // setShowComponent(filterData?.subject_uuid);

        setTestsLoading(false);
        if (e) {
            newFilterData = {
                ...newFilterData, chapter_uuid: e,
                exam_type: undefined
            }
            // setCount(5);
            // setShowComponent(e);
            setTimeout(() => {
                setTestsLoading(true);
            }, 1000);
            // GET_PARIKSHA_SELECT_TEST_LIST(filterData?.board_uuid, filterData?.medium_uuid, filterData?.standard_uuid, filterData?.subject_uuid, e, "TESTS", "PARIKSHA").then((res: any) => {
            //     if (res) {
            //         if (res.status === 'fail') {
            //             toast.error(res?.err);
            //             setTopicLoading(false);

            //         } else {
            //             const data = res['rows'];
            //             setTestList(data);
            //             setTopicLoading(false);


            //         }
            //     }
            // });
        } else {
            setTestsLoading(true);
            newFilterData = {
                ...newFilterData, chapter_uuid: ''
            }
        }
        const data = testInfo;
        data.chapter_uuid = e;
        setTestInfo({ ...data });
    }

    const handleExamType = (value: any) => {
        if (value) {
            newFilterData = {
                ...newFilterData, exam_type: value
            }
        } else {
            newFilterData = {
                ...newFilterData, exam_type: ''
            }
        }

        const data = testInfo;
        data.exam_type = value;
        setTestInfo({ ...data });

        setTestsLoading(false);
        setTimeout(() => {
            setTestsLoading(true);
        }, 1000);
    }

    // const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const handleKeyDown = (event: any) => {
        newFilterData = {
            ...newFilterData, search: event.target.value
        }
        if (event.key === 'Enter') {
            event.preventDefault();
            setTestsLoading(false);
            setTimeout(() => {
                setTestsLoading(true);
            }, 1000);
        }
    };


    const onChangeTest = (e: any) => {
        filterData.uuid = e;
        setQuestionsList([]);
        setCanSelectTest(true);
        // setCount(4);

        // setShowComponent(filterData?.subject_uuid);
        if (e) {
            // setCount(5);
            // setShowComponent(e);
            setQuestionsLoading(true);
            setLoading(true);
            GET_ABHYAS_QUESTION_LIST(e, {}).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setQuestionsLoading(false);
                        setLoading(false);
                    } else {
                        const data = res.rows;
                        if (data.length > 0) {
                            data?.forEach((element: any, i: number) => {
                                element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                                if (element?.modified_dt) {
                                    element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                                }
                                element.data = JSON.parse(element?.data)
                            });
                            setQuestionsList(data[0]?.data);
                            setQuestionsLoading(false);
                            getmediumData(data[0].board_uuid);
                            getStandardData(data[0].medium_uuid);
                            getSubjectsData(data[0].standard_uuid);
                            getChaptersData(data[0].subject_uuid);
                            setTimeout(() => {
                                setTestInfo(data[0]);
                                setLoading(false);
                                setCanShowTestQuestions(true);
                            }, 1000);

                        }


                    }
                }
            });
        }
    }

    // const onChangeTopic = (e: any, name?: string) => {
    //     setTopicName(name)
    //     filterData.topic_uuid = e;
    //     setTopicList([]);
    //     setQuestionsList([]);
    //     setCount(5);
    //     setShowComponent(filterData?.subject_uuid);
    //     if (e) {
    //         setCount(6);
    //         setShowComponent(e);
    //         setTopicLoading(true);
    //         GET_TOPIC_FITERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, filterData?.standard_uuid, filterData?.subject_uuid, e).then((res: any) => {
    //             if (res) {
    //                 if (res.status === 'fail') {
    //                     toast.error(res?.err);
    //                     setTopicLoading(false);

    //                 } else {
    //                     const data = res['rows'];
    //                     setTopicList(data);
    //                     setTopicLoading(false);

    //                 }
    //             }
    //         });
    //     }

    // }


    const handleRefresh = () => {
        setMediumList([]);
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setTestList([]);
        setQuestionsList([]);
        // const filterData = {};
        // setFilterData({ ...filterData });
        const data = {};
        setTestInfo({ ...data });
        filterForm.current.reset();
        newFilterData = {};
        setTestsLoading(false);
        setTimeout(() => {
            setTestsLoading(true);
        }, 1000);
    }

    const onChangeAddUpdateTest = (data: any) => {
        // filterForm.current.reset();
        setLoading(false);
        setTimeout(() => {
            setTestsLoading(true);
        }, 1000);

        if (data) {
            setTestInfo(data);
            getmediumData(data.board_uuid);
            getStandardData(data.medium_uuid);
            getSubjectsData(data.standard_uuid);
            getChaptersData(data.subject_uuid);
        }
    }

    const onClickBackTest = (data: any) => {
        setCanShowTestQuestions(false);
        setFilterData({});
        setTestInfo({});
        setLoading(true);
        setTestsLoading(false);
        newFilterData = {};
        setTimeout(() => {
            setLoading(false);
            setTestsLoading(true);
        }, 1000);
        setCanSelectTest(false);
    }

    const getDisabledFilters = (data: any) => {
        if (canSelectTest) {
            return true;
        } else {
            return false;
        }
    }


    return (
        <div>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className='my-3'>
                    <FormBuilder onUpdate={handleInput}>
                        <form ref={filterForm}>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className='mb-4'>
                                        <label>Board</label>
                                        <select name="board_uuid" id="board_uuid" className="form-select ft-14" disabled={getDisabledFilters(testInfo)} value={testInfo?.board_uuid} onChange={(e) => { onChangeBoard(e.target.value) }}>
                                            <option value="">Select Board</option>
                                            {boardsList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4 position-relative'>
                                        {/* {mediumLoading &&
                                            <div className=" mt-4 pt-1 position-absolute " style={{ right: "8px" }}>
                                                <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        } */}
                                        <label>Medium</label>

                                        <select name="medium_uuid" id="medium_uuid" className="form-select ft-14" disabled={getDisabledFilters(testInfo)} value={testInfo?.medium_uuid} onChange={(e) => { onChangeMedium(e.target.value) }}>
                                            <option value="">Select Medium</option>
                                            {mediumList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Standard</label>
                                        <select name="standard_uuid" id="standard_uuid" className="form-select ft-14" disabled={getDisabledFilters(testInfo)} value={testInfo?.standard_uuid} onChange={(e) => { onChangeStandard(e.target.value) }}>
                                            <option value="">Select Standard</option>
                                            {standardList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.standard}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Subject</label>
                                        <select name="subject_uuid" id="subject_uuid" className="form-select ft-14" disabled={getDisabledFilters(testInfo)} value={testInfo?.subject_uuid} onChange={(e) => { onChangeSubject(e.target.value) }}>
                                            <option value="">Select Subject</option>
                                            {subjectList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Chapter</label>
                                        <select name="chapter_uuid" id="chapter_uuid" className="form-select ft-14" disabled={getDisabledFilters(testInfo)} value={testInfo?.chapter_uuid} onChange={(e) => { onChangeChapter(e.target.value) }}>
                                            <option value="">Select Chapter</option>
                                            {chapterList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className='mb-4  position-relative'>
                                        <label>Exam Type</label>
                                        <select name="exam_type" id="exam_type" className="form-select f14" disabled={getDisabledFilters(testInfo)} value={testInfo?.exam_type} onChange={(e) => { handleExamType(e.target.value) }} >
                                            <option value="">Select Type</option>
                                            <option value="CHAPTER_WISE_TEST">CHAPTER WISE TEST</option>
                                            <option value="UNIT_TEST_1">UNIT TEST 1</option>
                                            <option value="UNIT_TEST_2">UNIT TEST 2</option>
                                            <option value="SEMESTER_TEST">SEMESTER TEST</option>
                                            <option value="PRE_BOARD_TEST">PRE BOARD TEST</option>
                                            <option value="FINAL_TEST">FINAL TEST</option>
                                            <option value="GROUP_TEST">GROUP TEST</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3 mt-4">
                                    <div className='mb-4  position-relative'>
                                        <input type="text" id="search" className="form-control ft-14" placeholder="search" name="search" onKeyDown={(e) => handleKeyDown(e)} />
                                    </div>
                                </div>
                                <div className="col-md-3 ">
                                    <button className="btn clear-btn cursor-pointer mt-4" type="button" style={{ height: '35px' }} onClick={() => handleRefresh()}>
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </form>
                    </FormBuilder>
                    {/* // Question // */}

                    {(testsLoading && !canShowTestQuestions) &&
                        <ParikshaTestDataList isLinked={"false"} filterData={newFilterData} updateList={onChangeAddUpdateTest} selectTest={onChangeTest} />
                    }
                    {canShowTestQuestions &&
                        <div>
                            <ParikshaQuestionsList isLinked={"false"} display={"none"} boardUuid={filterData?.board_uuid} mediumUuid={filterData?.medium_uuid} standardUuid={testInfo?.standard_uuid} subjectUuid={testInfo?.subject_uuid} chapterUUid={testInfo.chapter_uuid} sheetUuid={filterData?.uuid} setBackQuestionsList={onClickBackTest} />
                        </div>
                    }
                </div>
            }
        </div>
    )
}

