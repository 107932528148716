import React, { useEffect, useState } from 'react'
import FormBuilder from '../../../../components/form-builder';
import { FormValidator, GetControlIsValid, GetEmailControlIsValid, GetMobileControlIsValid } from '../../../../components/form-builder/validations';
import { FormControlError, FormField, FormValidators } from '../../../../components/form-builder/model/form-field';
import { Link, useHistory, useParams } from 'react-router-dom';
import { CREATE_SCHOOL_USER, UPLOAD_LOGO } from '../../../../app/service/school.service';
import { GET_USER_BY_ID, UPDATE_USER } from '../../../../app/service/users.service';
import { ToastContainer, toast } from 'react-toastify';
import { MultiSelect } from 'react-multi-select-component';
import moment from 'moment';
import appConfig from '../../../../config/constant';
import errorMessages from '../../../../components/error-messages';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateComponent } from '../../../../components/date';
import ProfileImageCrop from '../../../../components/image-crop/crop';

export const TeachersForm = () => {
  const [teachersData, setTeachersData] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const [validationErrors, setValidationErrors] = useState<FormControlError[]>([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [currentTeachersData, setCurrentTeachersData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  // const [standardSelected, setStandardSelected] = useState([]);
  const [divisionSelected, setDivisionSelected] = useState([]);
  const [subjectSelected, setSubjectSelected] = useState([]);
  const [teacherLogo, setTeacherLogo] = useState<any>();
  const [imageLoader, setImageLoader] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(false);
  const [whatsappNumber, setWhatsappNumber] = useState(false);
  const [teacherInfo, setTeacherInfo] = useState<any>({});
  const [canShowPopup, setCanShowPopup] = useState(false);
  const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
  const [createDate, setCreateDate] = useState(moment().format('YYYY-MM-DD'));
  const [min, setMin] = useState<any>(moment().subtract(60, "years").format("YYYY-MM-DD"));
  const [max, setMax] = useState<any>(moment().subtract(18, "years").format("YYYY-MM-DD"));
  const [email, setEmail] = useState(false);
  const [canCheckMobileNumber, setCanCheckMobileNumber] = useState(false);
  const [canShowWhatsAppError, setCanShowWhatsAppError] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [checkedUserMechanismStatus, setCheckedUserMechanismStatus] = useState<any>();
  let { id, schoolCode, userId } = useParams<{ id: string, schoolCode: string, userId: string }>();
  const schoolId = parseInt(id);
  const schoolsCode = schoolCode;
  const usersId = parseInt(userId);
  const [isRegistrationDateError, setIsRegistrationDateError] = useState<any>({});
  const history = useHistory();
  // 
  // Multiple subjects
  const [standardSelected, setStandardSelected] = useState<string>("");
  const [standardsData, setStandardsData] = useState<any>([]); // Store final data
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isAddedTeachingData, setIsAddedTeachingData] = useState<boolean>(false);
  const [editStandard, setEditStandard] = useState<string>("");

  const teachingDivisions = [
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "E", value: "E" }
  ]

  const teachingStandards = [
    { label: "6", value: "six" },
    { label: "7", value: "seven" },
    { label: "8", value: "eight" },
    { label: "9", value: "nine" },
    { label: "10", value: "ten" },
    { label: "11 ", value: "eleven" },
    { label: "12", value: "twelve" },
  ];
  const subjectList = [
    {
      label: 'HINDI',
      value: 'hindi'
    },
    {
      label: 'ENGLISH',
      value: 'english'
    },
    {
      label: 'MATH',
      value: 'math'
    },
    {
      label: 'SCIENCE',
      value: 'science'
    },
    {
      label: 'SOCIAL',
      value: 'social'
    }
  ];
  const [nameError, setNameError] = useState('');
  const [isDateError, setIsDateError] = useState<any>({});
  const formValidations = [
    new FormField('name', [FormValidators.REQUIRED]),
    new FormField('gender', [FormValidators.REQUIRED]),
    new FormField('date_of_birth', []),
    new FormField('city_village', []),
    new FormField('blood_group', []),
    new FormField('email_id', [FormValidators.REQUIRED]),
    new FormField('mobile_number', [FormValidators.REQUIRED]),
    new FormField('teacher_type', [FormValidators.REQUIRED]),
    new FormField('whatsapp_number', []),
    new FormField('date_of_registration', []),
    new FormField('teaching_standards', []),
    new FormField('teaching_subjects', []),
  ];
  const userRole = localStorage.getItem("user_role");


  useEffect(() => {

    if (usersId > 0) {
      fetchAllData();

    }
  }, []);
  const fetchAllData = async () => {
    setLoading(true);
    try {
      await exsistingUser(); // Fetch user data
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  // const exsistingUser = async () => {
  //   try {
  //     const res: any = await GET_USER_BY_ID(usersId);
  //     if (res.status === 'fail') {
  //       toast.error(res.message);
  //     } else {
  //       const data = res.rows[0];
  //       if (data.date_of_birth) {
  //         const date_of_birth: any = new Date(data.date_of_birth);
  //         setStartDate(date_of_birth);
  //       }
  //       if (data.date_of_registration) {
  //         const date_of_registration: any = new Date(data.date_of_registration);
  //         setTodayDate(date_of_registration);
  //       }
  //       if (data.created_at) {
  //         const created_at: any = new Date(data.created_at);
  //         setCreateDate(created_at);
  //       }
  //       if (data.mobile_number === data.whatsapp_number) {
  //         data.checked = true;
  //       }
  //       if (data.access) {
  //         setCheckedUserMechanismStatus(data.access);
  //       }
  //       if (data.teaching_standards.length > 0) {
  //         data.standard = data.teaching_standards[0].value;
  //         if (data.teaching_standards[0].divisions && data.teaching_standards[0].divisions.length > 0) {
  //           setDivisionSelected(data.teaching_standards[0].divisions);
  //         }
  //         setStandardsData(data.teaching_standards);

  //       }
  //       if (data?.class_section_details?.standard && data?.class_section_details?.section) {
  //         data.class_teacher_standard = data?.class_section_details?.standard;
  //         data.class_teacher_division = data?.class_section_details?.section;
  //       }
  //       setCurrentTeachersData(data);
  //       setTeacherLogo(data.profile_img);
  //       setSubjectSelected(data.teaching_subjects);
  //       setStandardSelected(data.teaching_standards);
  //     }
  //   } catch (error) {

  //   }
  // }
  const exsistingUser = async () => {
    try {
      const res: any = await GET_USER_BY_ID(usersId);
      if (res.status === 'fail') {
        toast.error(res?.message || "Failed to fetch user data.");
      } else {
        const data = res.rows[0];
        if (data.date_of_birth) {
          const date_of_birth: any = new Date(data.date_of_birth);
          setStartDate(date_of_birth);
        }
        if (data.date_of_registration) {
          setTodayDate(new Date(data.date_of_registration));
        }
        if (data.created_at) {
          setCreateDate(new Date(data.created_at));
        }
        if (data.mobile_number === data.whatsapp_number) {
          data.checked = true;
        }
        if (data.access !== undefined) {
          setCheckedUserMechanismStatus(data.access);
        }
        // if (data.teaching_standards && data.teaching_standards.length > 0) {
        //   const teachingStandard = data.teaching_standards[0];
        //   data.standard = teachingStandard.value;
        //   if (data?.teaching_subjects?.length > 0) {
        //     data.teaching_standards.subjects = [...data?.teaching_subjects, ...data?.teaching_standards?.subjects];
        //     console.log(data.teaching_standards.subjects, " data.teaching_standards.subjects");

        //     setStandardsData(data.teaching_standards);
        //   } else {
        //     setStandardsData(data.teaching_standards);
        //   }
        // }
        if (data.teaching_standards && data.teaching_standards.length > 0) {
          const teachingStandard = { ...data.teaching_standards[0] };
          if (data?.teaching_subjects?.length > 0) {
            teachingStandard.subjects = [
              ...(teachingStandard.subjects || []),
              ...data.teaching_subjects
            ];
          }
          setStandardsData([teachingStandard, ...data.teaching_standards.slice(1)]);
        }
        if (data.class_section_details) {
          const { standard, section } = data.class_section_details;
          data.class_teacher_standard = standard;
          data.class_teacher_division = section;
        }
        setCurrentTeachersData(data);
        setTeacherLogo(data.profile_img || "");
      }

    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleInput = (data: any) => {
    data.value = { ...currentTeachersData, ...data.value };
    setTeachersData(data);
    const errors: any = FormValidator(formValidations, data.value);
    setValidationErrors(errors);
  };

  const handleSubmit = () => {
    setIsFormSubmitted(true);
    const data = teachersData.value ? { ...teachersData.value } : { ...currentTeachersData };
    const errors: FormControlError[] = FormValidator(formValidations, data);
    setValidationErrors(errors);
    data.type = "school";
    data.user_role = "TEACHER";
    data.institute_school_code = schoolsCode;
    data.profile_img = teacherLogo;
    data.teaching_standards = standardsData;
    // data.teaching_standards = [{ label: getStandards(data, 'label'), value: getStandards(data, 'value'), divisions: divisionSelected }];
    // data.teaching_subjects = subjectSelected;
    data.teaching_subjects = [];
    data.date_of_registration = todayDate ? todayDate : null;
    data.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
    delete data.sameMobileNumber;
    delete data.file;
    delete data.checked;
    delete data[''];
    delete data.day;
    delete data.month;
    delete data.year;
    delete data.standard;
    Object.keys(data).forEach(function (key) {
      if (typeof data[key] === 'string') {
        data[key] = data[key].trim();
      }
    });
    if (errors.length < 1 && !email && !mobileNumber && onChangeName(data.name) && (data?.teacher_type === "SUBJECT_TEACHER" || (data?.teacher_type === "CLASS_TEACHER" && data?.class_teacher_standard && data?.class_teacher_division))) {
      data.whatsapp_number = data.whatsapp_number ? data.whatsapp_number : data.mobile_number;
      data.class_section_details = {
        standard: data?.class_teacher_standard,
        section: data?.class_teacher_division,
      };
      delete data.class_teacher_standard;
      delete data.class_teacher_division;
      setLoading(true);
      CREATE_SCHOOL_USER(data).then((res: any) => {
        if (res.status === 'fail') {
          toast.error(res.message);
          setLoading(false);
          setCurrentTeachersData(data);
        } else {
          setCanShowPopup(true);
          toast.success("Teacher Created Successfully");
          if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
            history.push(`/institute-dashboard/manage-school/list/info/${id}/teacherslist/${schoolsCode}`);
          }
          else if (userRole === "SCHOOL_ADMIN" || userRole === "VICE_PRINCIPAL" || userRole === "PRINCIPAL") {
            history.push(`/school-dashboard/profile/teacher/list/${schoolsCode}`);
          }
          else {
            history.push(`/dashboard/school/info/${id}/teacherslist/${schoolsCode}`);
          }
          setLoading(false);
          setTeacherInfo(res);
        }
      });
    }
    else {

      if (!nameError && !onChangeName(data.name)) {
        setNameError('Please enter name');
      }
    }
  }

  function getStandards(data: any, type: any) {
    if (type == 'label') {
      return data.standard ? data.standard.toUpperCase() : ''
    } else {
      return data.standard ? data.standard : ''
    }
  }

  const getInputValid = (control: string) => {
    const value = GetControlIsValid(validationErrors, control);
    return value;
  }

  const getEmailValid = (control: string) => {
    const data = { ...teachersData.value };
    const value = GetEmailControlIsValid(validationErrors, control, data);
    return value;
  }


  const getMobileValid = (control: string) => {
    const data = { ...teachersData.value };
    const value = GetMobileControlIsValid(validationErrors, control, data);
    return value;
  }

  function updateTeacher() {
    setIsFormSubmitted(true);
    const data = teachersData.value ? { ...teachersData.value } : { ...currentTeachersData };
    const errors: FormControlError[] = FormValidator(formValidations, data);
    setValidationErrors(errors);
    data.type = "school";
    data.user_role = "TEACHER";
    data.institute_school_code = schoolsCode;
    data.id = usersId;
    data.profile_img = teacherLogo;
    data.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
    data.date_of_registration = todayDate;
    data.teaching_standards = standardsData;
    // data.teaching_standards = [{ label: getStandards(data, 'label'), value: getStandards(data, 'value'), divisions: divisionSelected }]
    // data.teaching_subjects = subjectSelected;
    data.teaching_subjects = [];
    delete data.sameMobileNumber;
    delete data.file;
    delete data.checked;
    delete data[''];
    data.whatsapp_number = currentTeachersData.whatsapp_number;
    delete data.date_of_registration;
    delete data.user_code;
    delete data.address;
    delete data.address2;
    delete data.status;
    delete data.guardian;
    delete data.state;
    delete data.taluka;
    delete data.pin_code;
    delete data.district;
    delete data.standard;
    delete data.division;
    delete data.occupation;
    delete data.active;
    delete data.cas_report_view;
    delete data.indv_school_details;
    delete data.created_at;
    delete data.updated_at;
    delete data.user_name;
    delete data.role_name;
    delete data.short_name;
    delete data.name_of_school;
    delete data.name_of_organization;
    delete data.display_name;
    delete data.day;
    delete data.month;
    delete data.year;
    delete data.access;
    Object.keys(data).forEach(function (key) {
      if (typeof data[key] === 'string') {
        data[key] = data[key].trim();
      }
    });

    if (errors.length < 1 && !email && !mobileNumber && standardsData?.length > 0 && onChangeName(data.name) && (data?.teacher_type === "SUBJECT_TEACHER" || (data?.teacher_type === "CLASS_TEACHER" && data?.class_teacher_standard && data?.class_teacher_division))) {      // if (errors.length < 1) {
      data.access = checkedUserMechanismStatus;
      data.class_section_details = {
        standard: data.class_teacher_standard,
        section: data.class_teacher_division,
      };
      delete data.class_teacher_standard;
      delete data.class_teacher_division;
      setLoading(true);
      UPDATE_USER(data).then((res: any) => {
        if (res.status === 'fail') {
          toast.error(res.message);
          setLoading(false);
        } else {
          toast.success("Teacher Updated Successfully");
          setLoading(false);
          if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
            history.push(`/institute-dashboard/manage-school/list/info/${id}/teacherslist/${schoolsCode}`);
          }
          else if (userRole === "SCHOOL_ADMIN" || userRole === "VICE_PRINCIPAL" || userRole === "PRINCIPAL") {
            history.push(`/school-dashboard/profile/teacher/list/${schoolsCode}`);
          }
          else {
            history.push(`/dashboard/school/info/${id}/teacherslist/${schoolsCode}`);
          }
        }
      });
    } else {
      if (standardsData?.length == 0) {
        setIsAddedTeachingData(true);
      } else {
        setIsAddedTeachingData(false);
      }
      if (!nameError && !onChangeName(data.name)) {
        setNameError('Please enter name');
      }
    }
  }

  const handleGender = (e: any) => {
    const data = { ...teachersData.value };
    data.gender = e.target.value;
    if (currentTeachersData) {
      currentTeachersData.gender = e.target.value;
    }
  }

  function handleUploadLogo(e: any, type: string) {
    if (e.target.files && e.target.files[0]) {
      const fileType = e.target.files[0].name.split('.').pop()
      if (fileType == "jpeg" || fileType == "jpg") {
        const formData = new FormData();
        formData.append('file', e.target.files[0], e.target.files[0].name);
        uploadLogo(formData, type);
      } else {
        toast.error("Valid file type .jpg, .jpeg only");
      }
    }
  }

  function uploadLogo(formdata: any, type: string) {
    setImageLoader(true);
    UPLOAD_LOGO(formdata).then((res: any) => {
      if (res.status === 'fail') {
        toast.error(res.message);
        setImageLoader(false);
      } else {
        setTeacherLogo(res.url);
        setImageLoader(false);
      }
    });
  }

  const handleBloodGroupChange = (e: any) => {
    const data = { ...teachersData.value };
    data.blood_group = e.target.value;
    if (currentTeachersData) {
      currentTeachersData.blood_group = e.target.value;
    }
  }


  const handleMobileChange = (e: any) => {
    const data = { ...teachersData.value };
    const re = /(6|7|8|9)\d{9}/;

    if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
      data.mobile_number = e.target.value;
      setMobileNumber(false);
    } else {
      data.mobile_number = e.target.value;
      setMobileNumber(true);
    }

    if (currentTeachersData) {
      currentTeachersData.mobile_number = e.target.value.replace(/\D+/g, '');
    }
    if (currentTeachersData.checked) {
      currentTeachersData.whatsapp_number = e.target.value.replace(/\D+/g, '');
    }
  }

  const handleWhatsappChange = (e: any) => {
    const data = { ...teachersData.value };
    const re = /(6|7|8|9)\d{9}/;

    if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
      data.whatsapp_number = e.target.value;
      setWhatsappNumber(false);
      setCanShowWhatsAppError(false);
    } else {
      data.whatsapp_number = e.target.value;
      setWhatsappNumber(true);
    }

    if (currentTeachersData) {
      currentTeachersData.whatsapp_number = e.target.value.replace(/\D+/g, '');
    }
  }

  const handleEmailChange = (e: any) => {
    const data = { ...teachersData.value };
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

    if (e.target.value === '' || re.test(e.target.value)) {
      data.email_id = e.target.value;
      setEmail(false);
    } else {
      data.email_id = e.target.value;
      setEmail(true);
    }

    if (currentTeachersData) {
      currentTeachersData.email_id = e.target.value;
    }
  }

  const handleMobileNumberChange = (e: any) => {
    const data = { ...teachersData.value };
    if (e.target.checked) {
      if (data.whatsapp_number) {
        data.whatsapp_number = data.mobile_number;
        currentTeachersData.whatsapp_number = data.mobile_number;
        setCanCheckMobileNumber(true);
        setCanShowWhatsAppError(false);
      }
      if (data.mobile_number || currentTeachersData.mobile_number) {
        setCanCheckMobileNumber(true);
        setCanShowWhatsAppError(false);
        currentTeachersData.whatsapp_number = currentTeachersData.mobile_number;
        setCurrentTeachersData(currentTeachersData);
      }
      currentTeachersData.checked = true;
    } else {

      setCanCheckMobileNumber(false);
      currentTeachersData.checked = false;
    }
  }

  const handleclose = () => {
    setCanShowPopup(false);
    history.push(`/dashboard/school/info/${id}/teacherslist/${schoolsCode}`);
  }

  const handleSave = () => {
    setCanShowPopup(false);
    history.push(`/dashboard/school/info/${id}/teacherslist/${schoolsCode}`);

  }

  const onDateChange = (dates: any) => {
    setTodayDate(todayDate);
    if (dates.error) {
      setIsRegistrationDateError(dates);
    } else {
      setTodayDate(dates.date);
    }
  };

  const onChange = (dates: any) => {
    setStartDate(null);
    if (dates.error) {
      setIsDateError(dates);
    } else {
      setStartDate(dates.date);
    }
  };

  function addMonths(theDate: any, years: any) {
    return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
  }

  function subMonths(theDate: any, years: any) {
    return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
  }

  function onTodayDate(theDate: any, years: any) {
    return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
  }

  const onChangeName = (event: any) => {
    setNameError('');
    const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
    if (event) {
      if (!reg.test(event.trim())) {
        setNameError('Enter only alphabets');
      } else {
        return true;
      }
    } else {
      setNameError('Please enter name');
    }
  }

  const handleUserMechanism = (e: any) => {
    if (currentTeachersData) {
      const status = e.target.checked === true ? "ENABLED" : "DISABLED";
      currentTeachersData.access = status;
      setCheckedUserMechanismStatus(status);
    }
  }
  const handleDivisionChange = (e: any) => {
    const data = { ...teachersData.value };
    data.division = e.target.value;
    if (currentTeachersData) {
      currentTeachersData.class_teacher_division = e.target.value;
    }
  }
  const handleStandardChange = (e: any) => {
    const data = { ...teachersData.value };
    data.standard = e.target.value;
    if (currentTeachersData) {
      currentTeachersData.class_teacher_standard = e.target.value;
    }
  }
  const handleTeacherType = (e: any) => {
    const data = { ...teachersData.value };
    data.teacher_type = e.target.value;
    if (currentTeachersData) {
      currentTeachersData.teacher_type = e.target.value;
    }
  }
  // 
  const handleAdd = () => {
    if (standardSelected && divisionSelected.length > 0 && subjectSelected.length > 0) {
      const newStandard: any = {
        label: standardSelected.toUpperCase(),
        value: standardSelected,
        divisions: divisionSelected,
        subjects: subjectSelected
      };

      if (editIndex !== null) {
        // Edit Mode: Replace the item being edited
        const updatedStandards = [...standardsData];
        updatedStandards[editIndex] = newStandard;
        setStandardsData(updatedStandards);
        setEditIndex(null); // Exit edit mode
        setEditStandard("");
      } else {
        // Add Mode: Add new item
        setStandardsData((prev: any) => [...prev, newStandard]);
      }
      resetTachingData();
      setIsAddedTeachingData(false);
    } else {
      setIsAddedTeachingData(true);
    }
  };
  const resetTachingData = () => {
    setStandardSelected("");
    setDivisionSelected([]);
    setSubjectSelected([]);
    setEditIndex(null);
    setEditStandard("");
  }

  // Handle delete
  const handleDelete = (index: number) => {
    const updatedStandards = standardsData.filter((_: any, i: number) => i !== index);
    setStandardsData(updatedStandards);
    resetTachingData();

  };

  // Handle edit
  const handleEdit = (index: number, label: string) => {
    const standardToEdit = standardsData[index];
    setStandardSelected(standardToEdit?.value);
    setDivisionSelected(standardToEdit?.divisions);
    setSubjectSelected(standardToEdit?.subjects);
    setEditIndex(index); // Set the index of the item being edited
    setEditStandard(label);
  };
  const standardStatus = (value: string): boolean => {
    return standardsData?.some((item: any) => item?.value === value) ?? false;
  };
  const getStandard = (standard: string): number | undefined => {
    switch (standard) {
      case "SIX":
        return 6;
      case "SEVEN":
        return 7;
      case "EIGHT":
        return 8;
      case "NINE":
        return 9;
      case "TEN":
        return 10;
      case "ELEVEN":
        return 11;
      case "TWELVE":
        return 12;
      default:
        return undefined;
    }
  };
  return (
    <div>
      {loading &&
        <div className="text-center p-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      }
      <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Teacher Login Details</h5>
              <button type="button" className="close popup-close" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>Login ID: {teacherInfo.user_name}</div>
              <div>Password: {teacherInfo.password}</div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>
            </div>
          </div>
        </div>
      </div>
      {!loading && <div>
        <FormBuilder onUpdate={handleInput}>
          <form>

            <div className="row custom-form">
              <div style={{ borderBottom: 'solid 1px black', marginTop: '15px' }} className="mb-4">
                <h5 className="form-label mb-2 d-block">Personal Details</h5>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Mrs./ Mr
                    <span style={{ paddingLeft: '5px' }}>Name</span></label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                  <input className="form-control form-control-lg" type="text" name="name" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentTeachersData.name} placeholder="Please enter name" />
                  {nameError && <p className="text-danger">{nameError}</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-4">Gender</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <br />
                  <input type="radio" name="gender" value="male" checked={currentTeachersData?.gender === "male"} onChange={(e) => { handleGender(e) }} />
                  <span style={{ margin: '0px 12px' }}>Male</span>
                  <input type="radio" name="gender" value="female" checked={currentTeachersData?.gender === "female"} onChange={(e) => { handleGender(e) }} />
                  <span style={{ margin: '0px 12px' }}>Female</span>
                  <input className="mb-0" type="radio" value="transgender" name="gender" checked={currentTeachersData?.gender === "transgender"} onChange={(e) => { handleGender(e) }} />
                  <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Other</span>
                  {isFormSubmitted && !getInputValid('gender') && <p className="text-danger">Please select gender</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Date of Birth</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  {currentTeachersData.date_of_birth &&
                    <DateComponent onDate={onChange} yearDiff={18} defaultData={currentTeachersData.date_of_birth}></DateComponent>}
                  {!currentTeachersData.date_of_birth && <DateComponent onDate={onChange} yearDiff={18}></DateComponent>}
                  {isFormSubmitted && !startDate && !isDateError?.error && <p className="text-danger">Please select date</p>}
                  {isDateError?.error && !startDate && <p className="text-danger">{isDateError?.error}</p>}

                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Blood Group</label>
                  <select name="blood_group" value={currentTeachersData.blood_group} onChange={(e) => { handleBloodGroupChange(e) }} className="form-select form-select-lg mb-3">
                    <option value="">Select blood group</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                    <option value="A-">A-</option>
                    <option value="A+">A+</option>
                    <option value="B-">B-</option>
                    <option value="B+">B+</option>
                    <option value="AB-">AB-</option>
                    <option value="AB+">AB+</option>
                  </select>
                </div>
              </div>
              {usersId > 0 && <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Date of Registration</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  {currentTeachersData.date_of_registration &&
                    <DateComponent onDate={onDateChange} idDisable={true} yearDiff={0} defaultData={currentTeachersData.date_of_registration} margin={1}></DateComponent>}
                  {!currentTeachersData.date_of_registration && <DateComponent idDisable={true} defaultData={todayDate} onDate={onDateChange} yearDiff={0}></DateComponent>}
                </div>
              </div>}
              {schoolId > 0 && <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label">User Access</label><br />
                  <label className="switch">
                    <input type="checkbox" name="access" value={currentTeachersData?.access} checked={currentTeachersData?.access === "ENABLED"} onChange={(e) => { handleUserMechanism(e) }} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>}
              <div className="col-md-4 col-12">
                <div className="mb-4 d-flex justify-content-center">
                  <ProfileImageCrop uploadLogo={uploadLogo} profileImg={teacherLogo} imageLoader={imageLoader} profileImageDelete={setTeacherLogo} />
                </div>
              </div>
              <div style={{ borderBottom: 'solid 1px black' }} className="mb-4">
                <h5 className="form-label mb-2 d-block">Contact Details</h5>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Email ID</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <input className="form-control form-control-lg" type="text" name="email_id" value={currentTeachersData.email_id} onChange={(e) => { handleEmailChange(e) }} placeholder="Please enter email" />
                  {email && <p className="text-danger">{errorMessages.email}</p>}
                  {isFormSubmitted && !getInputValid('email_id') && <p className="text-danger">Please enter email</p>}
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">City/Village (Optional)</label>
                  <input className="form-control form-control-lg" type="text" name="city_village" defaultValue={currentTeachersData.city_village} placeholder="Please enter city/village" />
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Mobile Number</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <input className="form-control form-control-lg" type="text" name="mobile_number" maxLength={10} onChange={(e) => { handleMobileChange(e) }} value={currentTeachersData.mobile_number} placeholder="Please enter mobile number" />
                  {mobileNumber && <p className="text-danger">{errorMessages.mobile_number}</p>}
                  {isFormSubmitted && !getInputValid('mobile_number') && <p className="text-danger">Please enter mobile number</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">WhatsApp Number</label>
                  {/* <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span> */}
                  (  <input type="checkbox" name="sameMobileNumber" checked={currentTeachersData.checked} onChange={(e) => { handleMobileNumberChange(e) }} style={{ paddingLeft: '10px' }} />
                  <span style={{ paddingLeft: '10px' }}>Same as Mobile Number</span> )
                  <input className="form-control form-control-lg" disabled={currentTeachersData.checked} type="text" name="whatsapp_number" maxLength={10} onChange={(e) => { handleWhatsappChange(e) }} value={currentTeachersData.whatsapp_number} placeholder="Please enter whatsapp number" />
                  {(whatsappNumber) && <p className="text-danger">{errorMessages.whatsapp_number}</p>}
                </div>
              </div>
              <div style={{ borderBottom: 'solid 1px black' }} className="mb-4">
                <h5 className="form-label mb-2 d-block">Job Details</h5>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-2">Teacher Type </label>
                  <span style={{ color: 'red', fontSize: '19px', paddingLeft: '8px' }}>*</span>
                  <select className="form-select form-select-lg mb-1" name="teacher_type" value={currentTeachersData?.teacher_type} onChange={(e) => { handleTeacherType(e) }}>
                    <option value="">Select teacher type</option>
                    <option value="SUBJECT_TEACHER">Subject Teacher</option>
                    <option value="CLASS_TEACHER">Class Teacher</option>
                  </select>
                  {isFormSubmitted && !getInputValid('teacher_type') && <p className="text-danger">Please select teacher type</p>}
                </div>
              </div>
              {(currentTeachersData?.teacher_type === "CLASS_TEACHER" || teachersData?.teacher_type === "CLASS_TEACHER") && <>
                <div className="col-md-4">
                  <div className="mb-4">
                    <label className="form-label mb-2">Class Teacher  Standards </label>
                    <span style={{ color: 'red', fontSize: '19px', paddingLeft: '8px' }}>*</span>
                    <select className="form-select form-select-lg mb-1" name="class_teacher_standard" value={currentTeachersData.class_teacher_standard} onChange={(e) => handleStandardChange(e)}>
                      <option value="">Select standard</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                    {(isFormSubmitted && !currentTeachersData.class_teacher_standard && (currentTeachersData?.teacher_type === "CLASS_TEACHER" || teachersData?.teacher_type === "CLASS_TEACHER")) && <p className="text-danger">Please select class teacher standard</p>}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-4">
                    <label className="form-label mb-2">Class Teacher  Divisions </label>
                    <span style={{ color: 'red', fontSize: '19px', paddingLeft: '8px' }}>*</span>
                    <select className="form-select form-select-lg mb-1" name="class_teacher_division" value={currentTeachersData.class_teacher_division} onChange={(e) => handleDivisionChange(e)}>
                      <option value="">Select divisions</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                      <option value="E">E</option>
                      <option value="F">F</option>
                    </select>
                    {(isFormSubmitted && !currentTeachersData.class_teacher_division && (currentTeachersData?.teacher_type === "CLASS_TEACHER" || teachersData?.teacher_type === "CLASS_TEACHER")) && <p className="text-danger">Please select class teacher division</p>}
                  </div>
                </div>
              </>}
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-2">Teaching To which Standards</label>
                  <span style={{ color: 'red', fontSize: '19px', paddingLeft: '8px' }}>*</span>
                  <select
                    className="form-select form-select-lg mb-1"
                    name="standard"
                    value={standardSelected}
                    onChange={(e) => setStandardSelected(e.target.value)}
                  >
                    <option value="">Select standard</option>
                    {teachingStandards?.map((data: { value: string; label: string }, i: number) => (
                      <option className={`${standardStatus(data.value) && "bg_secondary_light"}`}
                        key={i}
                        disabled={data.value !== editStandard && standardStatus(data.value)}
                        value={data.value}>
                        {data.label}
                      </option>
                    ))}
                  </select>
                  {(isAddedTeachingData && !standardSelected) && <p className="text-danger mb-1">Please select class standard</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-3">Teaching To which Divisions</label>
                  <span style={{ color: 'red', fontSize: '19px', paddingLeft: '8px' }}>*</span>
                  <MultiSelect
                    options={teachingDivisions}
                    value={divisionSelected}
                    onChange={setDivisionSelected}
                    labelledBy="Select"
                  />
                  {(isAddedTeachingData && divisionSelected?.length == 0) && <p className="text-danger mb-1">Please select class standard</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-3">Teaching which Subjects</label>
                  <span style={{ color: 'red', fontSize: '19px', paddingLeft: '8px' }}>*</span>
                  <MultiSelect
                    options={subjectList}
                    value={subjectSelected}
                    onChange={setSubjectSelected}
                    labelledBy="Select"
                  />
                  {(isAddedTeachingData && subjectSelected?.length == 0) && <p className="text-danger mb-1">Please select class standard</p>}
                </div>
              </div>
              <div className="col-md-12">
                <button type='button' onClick={handleAdd} className="btn btn-primary">
                  {editIndex !== null ? "Update" : "Add"} Standard
                </button>
              </div>
              {(isFormSubmitted && standardsData?.length === 0) && <p className="text-danger mb-1">Please select teaching standards, divisions and subjects</p>}
              {/* Display the data below */}
              <div className="col-md-12 mt-3">
                <h5 className="form-label mb-2 d-block">Teaching Standards Info:</h5>
                {standardsData?.length === 0 ? (
                  <p>No data added yet.</p>
                ) : (
                  <ul>
                    {standardsData?.map((standard: any, index: number) => (
                      <li key={index} className="mb-2">
                        <strong className='mb-1'>{standard?.label && getStandard(standard?.label)}</strong>th
                        <p className='mb-1'>Divisions: {standard?.divisions?.map((d: any) => d.label).join(", ")}</p>
                        <p>Subjects: {standard?.subjects?.map((s: any) => s.label).join(", ")}</p>
                        <button type='button' onClick={() => handleEdit(index, standard?.value)} className="btn btn-sm btn-warning me-2">Edit</button>
                        <button type='button' onClick={() => handleDelete(index)} className="btn btn-sm btn-danger">Delete</button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {/*  */}
            </div>
          </form>
        </FormBuilder>
        <div className="form-footer border-top border-primary py-3 text-end mt-4">
          {usersId === 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleSubmit}>Create</a>}
          {usersId > 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => updateTeacher()}>Update</a>}
          {userRole !== "SCHOOL_ADMIN" && userRole !== "VICE_PRINCIPAL" && userRole !== "PRINCIPAL" && userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" && <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to={`/dashboard/school/info/${id}/teacherslist/${schoolsCode}`}>Cancel</Link>}
          {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") && <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to={`/institute-dashboard/manage-school/list/info/${id}/teacherslist/${schoolsCode}`}>Cancel</Link>}
          {(userRole === "SCHOOL_ADMIN" || userRole === "VICE_PRINCIPAL" || userRole === "PRINCIPAL") && <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to={`/school-dashboard/profile/teacher/list/${schoolsCode}`}>Cancel</Link>}
        </div>
      </div>}
    </div>
  )
}