import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { LOGIN, REQUEST_OTP, RESET_PASSWORD } from '../../app/service/login.service'
import LogoImg from '../../assets/images/iamsamarth-Logo-Final.svg';
import FormBuilder from '../../components/form-builder'
import { FormControlError, FormField, FormValidators } from '../../components/form-builder/model/form-field'
import { FormValidator, GetControlIsValid } from '../../components/form-builder/validations'
import { ToastContainer, toast } from 'react-toastify';
import Logos from "../../assets/images/eliments.svg";
import { useParams } from 'react-router-dom'
import { CHECK_USER_NAME_AVAILABILITY, VALIDATE_USER_NAME } from '../../app/service/users.service';
import { log } from 'console';
import moment from 'moment';

export const SetPasswordPage = () => {
    const [loginValidationErrors, setLoginValidationErrors] = useState<FormControlError[]>([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loginData, setLoginData] = useState<any>({});
    const [formData, setFormData] = useState<any>({});
    const [errorMessage, setErrorMessage] = useState("");
    const [passwordErrorMessage, setPasswordErrorMessage] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [usersName, setUsersName] = useState("");
    const [messageType, setMessageType] = useState("mobile");
    const [isSendOtpSuccess, setIsSendOtpSuccess] = useState(false);
    const [otpResponse, setOtpResponse] = useState<any>();
    const history = useHistory();
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    let { userName } = useParams<{ userName: string }>();
    const [userNameError, setUserNameError] = useState('');
    const [checkAvailability, setCheckAvailability] = useState(false);
    const [checkUserExist, setCheckUserExist] = useState(false);
    const firstTimeLogin = localStorage.getItem("first_time_login");
    const userCode = localStorage.getItem("user_code");
    const [fisrstTimeEnable, setFisrstTimeEnable] = useState(true);
    const [userNameValid, setUserNameValid] = useState(true);
    const [emptyUserName, setEmptyUserName] = useState(true);
    const [sugesstions, setSugesstions] = useState(false);
    const formValidations = [
        new FormField('user_name', []),
        new FormField('new_password', [FormValidators.REQUIRED]),
        new FormField('confirm_password', [FormValidators.REQUIRED])
    ];
    const userDateOfBirth = localStorage.getItem("user-date-of-birth");
    const name_of_user = localStorage.getItem("user_name");
    const [avalableUserNames, setAvalableUserNames] = useState([]);
    const [startDate, setStartDate] = useState(null);

    useEffect(() => {
        if (userName) {
            setUsersName(userName);
        }
        if (firstTimeLogin == "1") {
            setFisrstTimeEnable(false);
        }
    }, []);


    const handleInput = (data: any) => {
        setLoginData(data);
        setFormData({ ...data.value });
        const errors: any = FormValidator(formValidations, data.value);
        setLoginValidationErrors(errors);
    };

    function onClickLogin() {
        setIsFormSubmitted(true);
        const loginsData = { ...loginData.value };

        const errors: FormControlError[] = FormValidator(formValidations, loginsData);

        setLoginValidationErrors(errors);

        if (loginsData.new_password == loginsData.confirm_password) {

            if (errors.length < 1 && userNameValid && emptyUserName && !checkUserExist && onChangePasswordValidation(loginsData.new_password)
                && onChangeConfirmPasswordValidation(loginsData.confirm_password)) {
                setLoading(true);

                if (!isSendOtpSuccess) {
                    if (loginsData.new_password === loginsData.confirm_password) {
                        const payload = {
                            user_name: userName,
                            otp_type: "resetpassword",
                            message_type: messageType
                        }
                        REQUEST_OTP(payload).then((res: any) => {
                            if (res.status === 'fail') {
                                setErrorMessage(res.message);
                                setLoading(false);
                            } else {
                                setLoading(false);
                                const user_info = res;
                                setOtpResponse(res);
                                setIsSendOtpSuccess(true);
                                formValidations.push(new FormField('confirm_password', [FormValidators.REQUIRED]));
                            }
                        });
                    } else {
                        setPasswordErrorMessage(true);
                        setLoading(false);
                    }
                } else {

                    const payload = {
                        user_code: userCode,
                        user_name: usersName,
                        password: loginsData.new_password,
                        ref_number: otpResponse?.ref_number ? otpResponse.ref_number : "",
                        pin: loginsData.pin
                    }
                    RESET_PASSWORD(payload).then((res: any) => {
                        if (res.status === 'fail') {
                            setErrorMessage(res.message);
                            setLoading(false);
                        } else {
                            setLoading(false);
                            const user_info = res;
                            history.push("/");
                            toast.success('Password successfully updated');
                        }
                    });
                }

            } else {

                if (!emptyUserName) {
                    setUserNameError('Please enter user name');

                }
                if (!userNameValid) {
                    setSugesstions(true);

                }
                if (checkUserExist) {
                    setCheckUserExist(true);
                }
                if (!passwordError && !onChangePasswordValidation(loginsData.new_password)) {
                    setPasswordError('Please enter password');
                }
                if (!confirmPasswordError && !onChangeConfirmPasswordValidation(loginsData.confirm_password)) {
                    setConfirmPasswordError('Please enter password');
                }
            }
        } else {
            setConfirmPasswordError('confirm password not matching new password')
        }
    }



    const getInputValid = (control: string) => {
        const value = GetControlIsValid(loginValidationErrors, control);
        return value;
    }

    function onClickNewPasswordEyeIcon() {
        setNewPassword(!newPassword);
    }

    function onClickConfirmPasswordEyeIcon() {
        setShowConfirmPassword(!showConfirmPassword);
    }
    const onChangeOtpType = (event: any) => {
        setMessageType(event);
    }
    const onChangePasswordValidation = (event: any) => {
        setConfirmPasswordError('');
        setPasswordError('');
        const reg = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setPasswordError('Password minimum of 6 character, at least one capital letter, at least one special character, at least one number');
            } else {
                return true;
            }
        } else {
            setPasswordError('Please enter password');
        }
    }

    const onChangeConfirmPasswordValidation = (event: any) => {
        setConfirmPasswordError('');
        const reg = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setConfirmPasswordError('Confirm password minimum of 6 character, at least one capital letter, at least one special character, at least one number');
            } else {
                return true;
            }
        } else {
            setConfirmPasswordError('Please enter confirm password');
        }

    }
    const onChangeUserName = (event: any) => {
        setUsersName(event);
        setUserNameError('');
        setCheckAvailability(false);
        setCheckUserExist(false);
        setUserNameValid(true);
        setEmptyUserName(true);
        setSugesstions(false);
        const reg = /^[a-zA-Z0-9._-]{6,16}$/;
        if (event) {

            if (!reg.test(event.trim())) {
                setUserNameValid(false);
                setSugesstions(true);
                setCheckUserExist(false);
            } else {
                setAvalableUserNames([]);
                getUserName(event);

            }
        } else {
            setEmptyUserName(false)
            setUserNameError('Please enter user name');

        }
    }
    function getUserName(event: any) {
        setCheckUserExist(false);
        if (event) {
            const validUser = {
                user_name: event,
                name: name_of_user,
                dob: moment(userDateOfBirth).format('YYYY-MM-DD')
                // dob: userDateOfBirth
            }
            // CHECK_USER_NAME_AVAILABILITY(event).then((res: any) => {
            VALIDATE_USER_NAME(validUser).then((res: any) => {
                if (res.status === 'fail' || res.errorCode === 400) {
                    toast.error(res.message || res.errorMsg);
                    setLoading(false);
                } else {
                    if (res?.available) {

                    }

                    if (!res?.available) {
                        setSugesstions(false);
                        setAvalableUserNames(res.ref_user_name)
                        setCheckUserExist(true)
                    }

                    setLoading(false);
                }
            });
        }
    }
    const handleUserName = (e: any) => {
        getUserName(e.target.value);
        setUsersName(e.target.value);
        setSugesstions(false);
        setCheckUserExist(false);
        setUserNameValid(true);


    }
    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        // Prevent pasting
    };
    return (
        <div>
            <section className="d-flex justify-content-md-center align-items-center login-block-wraper forgot-password">
                <div>
                    <div className="bg-white border border-gray rounded-16 p-3 p-lg-5 shadow-sm login-block">
                        <div className="row justify-content-md-center login-row">
                            <div className="col col-md-5 text-center text-lg-start" style={{ paddingTop: '100px' }}>
                                <img src={LogoImg} alt="" className="login-logo" />
                            </div>

                            <div className="col-md-7 login-fileds">
                                <FormBuilder onUpdate={handleInput}>
                                    <form>
                                        <h2 className="login-hd mt-3 mb-1 mb-md-2 text-center text-lg-start">Set Password</h2>
                                        {!isSendOtpSuccess ? <div>
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control fw-light" value={usersName} name="user_name" placeholder="Please enter user Id" onChange={(event) => onChangeUserName(event.target.value)} disabled={fisrstTimeEnable} />
                                                {checkAvailability && <span className="eye-icon cursor-pointer">
                                                    <i className="text-completed fa fa-check-circle ps-1"></i>
                                                </span>}
                                                {checkUserExist && <span className="eye-icon cursor-pointer">
                                                    <i className="text-inprogess fa fa-times-circle-o ps-1"></i>
                                                </span>}
                                                <label htmlFor="floatingInput">User Name</label>

                                                {userNameError && <p className="text-danger">{userNameError}</p>}
                                                {sugesstions && <div>
                                                    <p className='text-danger f14 mb-0'>- Should be between 6 and 16 characters </p>
                                                    <p className='text-danger f14 mb-0'>- User name allow only a-z A-Z 0-9 ._-  </p>

                                                </div>}
                                                {avalableUserNames?.map((user: any, i: number) => {
                                                    return <div style={{ marginRight: "10px" }}>
                                                        <input type="radio" name="user_name" value={user}
                                                            checked={usersName === user}
                                                            onChange={(e) => { handleUserName(e) }} />
                                                        <span style={{ margin: '0px 12px' }} key={i}>{user}</span>
                                                    </div>
                                                })}

                                            </div>

                                            <div className="form-floating">
                                                <input type={newPassword ? "text" : "password"} className="form-control fw-light" name="new_password" placeholder="New password" onChange={(e) => onChangePasswordValidation(e.target.value)} />
                                                <span className="eye-icon cursor-pointer" onClick={() => onClickNewPasswordEyeIcon()}>
                                                    <i className={newPassword ? "bi bi-eye-fill" : "bi bi-eye-slash"}></i>
                                                </span>
                                                <label htmlFor="floatingPassword">New Password</label>
                                                {passwordError && <p className="text-danger">{passwordError}</p>}

                                            </div>
                                            <div className="form-floating">
                                                <input type={showConfirmPassword ? "text" : "password"} className="form-control fw-light" name="confirm_password" placeholder="Confirm password" onChange={(e) => onChangeConfirmPasswordValidation(e.target.value)} onPaste={handlePaste} />
                                                <span className="eye-icon cursor-pointer" onClick={() => onClickConfirmPasswordEyeIcon()}>
                                                    <i className={showConfirmPassword ? "bi bi-eye-fill" : "bi bi-eye-slash"}></i>
                                                </span>
                                                <label htmlFor="floatingPassword">Confirm Password</label>
                                                {confirmPasswordError && <p className="text-danger">{confirmPasswordError}</p>}

                                            </div>
                                            {firstTimeLogin === "1" && <div className="form-floating mb-3 mt-3">
                                                <span>Please select you desired mode to receive OTP</span>
                                                <div className='mt-2'>
                                                    <input type="radio" value={messageType} className='me-2' checked={messageType === 'mobile'} name='message_type' onChange={() => onChangeOtpType('mobile')} /> <span className='me-2'>Mobile</span>
                                                </div>
                                                <div className='mt-2'>
                                                    <input type="radio" value={messageType} className='me-2' checked={messageType === 'email'} name='message_type' onChange={() => onChangeOtpType('email')} /> <span className='me-2'>Email</span>
                                                </div>
                                            </div>}
                                        </div> :
                                            <div>
                                                <div className="form-floating">
                                                    <span>Please enter 4 digit OTP number sent to your registered {messageType === 'mobile' ? 'mobile' : 'email'}</span>
                                                    <input type="text" className="form-control fw-light" name="pin" placeholder="Please enter OTP" />
                                                    {isFormSubmitted && !getInputValid('pin') && <p className="text-danger">Please OTP</p>}
                                                </div>
                                            </div>
                                        }
                                    </form>
                                </FormBuilder>
                                <div className="text-center mb-1 mt-2" style={{ color: 'red' }}>{errorMessage}</div>
                                {passwordErrorMessage && <div className="text-center mb-1 mt-2" style={{ color: 'red' }}>
                                    Confirm password not matching.</div>}
                                {loading ?
                                    <div className='text-center'>
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div> :
                                    <div>
                                        {checkAvailability &&
                                            <p className='text-completed  ps-1 f14 text-center text-md-start'>User Name Available </p>
                                        }
                                        {checkUserExist && <p className='text-inprogess  ps-1 f14 text-center text-md-start'> User Name Not Available </p>}
                                        <div className="mt-4 mb-3 text-center text-lg-start">

                                            <a className="btn btn-primary btn-sm px-5 rounded-12 fw-light cursor-pointer" onClick={() => onClickLogin()}>
                                                Submit
                                            </a><br />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="login-elements text-center" style={{ marginTop: '1rem' }}>
                        <img src={Logos} alt="" className="login-elements-icons" />
                    </div>
                </div>
            </section>
            <footer className="text-center f12 text-dgray"> <div className="container">
                &copy; 2023-24  <a href="http://iamsamarth.com" className="text-dgray text-decoration-none">iamsamarth.com</a>. All Rights Reserved | <a href="http://iamsamarth.com/privacy-policy/" className="text-dgray text-decoration-none">Terms and Conditions</a> |
                <a href="http://iamsamarth.com/privacy-policy/" className="text-dgray text-decoration-none ms-1">Privacy Policy</a>
            </div>
            </footer>
        </div>
    )
}