import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

// Define props for the component
interface ChartProps {
    subjectsList: string[];
    abhyaasDataValue: number[];
    parkshaDataValue: number[];
    selectedYear?: number; // Optional if needed in context
}

const AbhyaasBarChartComponent: React.FC<ChartProps> = ({ subjectsList, abhyaasDataValue, parkshaDataValue }) => {

    const options = {
        chart: {
            type: 'column',
            backgroundColor: "transparent",
            height: 350,
        },
        title: {
            text: "Worksheets completed in the week",
            align: 'center',
            y: 3,
            style: {
                color: 'black',
                fontSize: '17px',
                fontWeight: 'normal',
            },
        },
        xAxis: {
            lineWidth: 0,
            categories: subjectsList,
            type: 'category',
            // title: {
            //     text: 'Obtained Marks',
            //     style: {
            //         fontSize: '14px',
            //         width: 200,
            //         fontWeight: 'normal',
            //         color: 'black',
            //     },
            // },
            labels: {
                style: {
                    color: 'black',
                    fontSize: '10px',
                    fontWeight: 'normal',
                },
            },
        },
        yAxis: {
            title: {
                text: 'Number of Worksheets',
                style: {
                    fontSize: '14px',
                    width: 200,
                    fontWeight: 'normal',
                    color: 'black',
                },
            },
            tickPixelInterval: 50,
            gridLineColor: "#D9D9D9",
            gridLineWidth: 1,
            min: 0,
            max: 100,
        },
        legend: {
            symbolWidth: 8,
            symbolHeight: 8,
            symbolRadius: 0,
            symbolPadding: 5,
            y: -1,
            itemStyle: {
                cursor: 'default', // Change cursor to default to indicate non-interactivity
            },
        },
        plotOptions: {
            column: {
                size: '41%',
                pointPadding: 0.2,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    style: {
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: 'normal',
                    },
                },
                events: {
                    legendItemClick: function () {
                        return false; // Disable legend click interaction
                    },
                },
            },
            allowPointSelect: false,
        },
        series: [
            {
                name: 'Worksheets by Assessment',
                data: abhyaasDataValue,
                color: '#156082',
            },
            {
                name: 'Worksheets by Pariksha',
                data: parkshaDataValue,
                color: '#E97132',
            },
        ],
        credits: {
            enabled: false, // Disable Highcharts credits
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default AbhyaasBarChartComponent;
