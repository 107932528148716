import { DataTableCol } from "../../components/data-table/types";

export const ContentMediumDataGridCols: DataTableCol[] = [
    {
        title: "Name",
        control: "name",
        sortable: true,
        canShowColumn: true,
        isLink: true,
    },
    {
        title: "Created",
        control: "created_dt",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Modified",
        control: "modified_dt",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Actions",
        control: "board_info",
        isEdit: true,
        // isDelete: true,
        canShowColumn: true,
    },
];
