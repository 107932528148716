import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_SCHOOL_BY_ID, GET_SCHOOL_BY_SCHOOL_CODE } from "../../../app/service/school.service";
import { GET_USER_AD_SCHOOL_CODE, GET_USER_BY_SCHOOL_CODE, GET_USER_VP_SCHOOL_CODE } from "../../../app/service/users.service";
import appConfig from '../../../config/constant';
import SchoolinsideRoutings from "../../../routes/institute-inside-school";
import { SchoolDashBoardRoutes } from "../../../routes/school-dashboard/school-dashboard";
import IMGLOGO from '../../../institute-dashboard-assets/images/bala-bavan-logo.svg'
import CHAIRMANLOGO from '../../../institute-dashboard-assets/images/chairman-icon.svg'
import GENERALSECRETARY from '../../../institute-dashboard-assets/images/generalsecretary-icon.svg'
import INSTITUTEADMIN from '../../../institute-dashboard-assets/images/institute-admin-icon.svg'
import SchoolProfileInsideRoutings from "../../../routes/school-dashboard/school-profile-inside-routes";

export const SchoolProfileInformation = () => {
    const [schoolId, setSchoolId] = useState<number>();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [schoolInfo, setSchoolInfo] = useState<any>({});
    const [principalInfo, setPrincipalInfo] = useState<any>({});
    const [vicePricipalInfo, setVicePricipalInfo] = useState<any>({});
    const [adminInfo, setAdminInfo] = useState<any>({});
    const [schoolCode, setSchoolCode] = useState<any>();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const userRole = localStorage.getItem("user_role");
    const SchoolNames = localStorage.getItem("name_of_school");
    const userSchoolName = localStorage.getItem('school_name')

    useEffect(() => {
        setLoading(true);
        const data: any = localStorage.getItem("institute_code");
        GET_SCHOOL_BY_SCHOOL_CODE(data).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res.rows[0];
                    setSchoolInfo(data);
                    setLoading(false);
                    getPrincipal(data.school_code);
                    getvicePrivipal(data.school_code);
                    getAdmin(data.school_code);
                    setSchoolCode(data.school_code);
                    setSchoolId(data.id)
                }
            }
        });

    }, [])

    function getPrincipal(schoolCode: any) {
        if (schoolCode) {
            setLoading(true);
            GET_USER_BY_SCHOOL_CODE(schoolCode, 'PRINCIPAL', 0, {}).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        const data = res.rows[0];
                        setPrincipalInfo(data);
                        setLoading(false);
                    }
                }
            });
        }
    }
    function getvicePrivipal(schCode: any) {
        if (schCode) {
            setLoading(true);
            GET_USER_VP_SCHOOL_CODE(schCode, 'VICE_PRINCIPAL', 0, {}).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        const data = res.rows[0];
                        setVicePricipalInfo(data);
                        setLoading(false);
                    }
                }
            });
        }
    }
    function getAdmin(schCode: any) {
        setLoading(true);
        GET_USER_BY_SCHOOL_CODE(schCode, 'SCHOOL_ADMIN', 0, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                    const data = res.rows[0];
                    setAdminInfo(data);

                }
            }
        });

    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb ">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom f14">
                            {userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" && <li className="breadcrumb-item"><span className="active">{userSchoolName}</span> / <span>Profile</span> </li>}
                            {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") && <li className="breadcrumb-item"> <Link to="/institute-dashboard/dashboard/registered-schools"><span>{SchoolNames}</span></Link> / <span>Profile</span> </li>}
                        </ol>
                    </nav>
                    <div className="border-bottom pb-3">
                        <div className="d-md-flex sc-profile-details">
                            <div className="mb-3 mb-md-0">
                                <div className="sc-profile-img py-0">
                                    <img className='pr-logo' src={schoolInfo?.logo_url ? `${appConfig.FILE_UPLOAD_URL}${schoolInfo?.logo_url.charAt(0) === "/" ? schoolInfo?.logo_url.substring(1) : schoolInfo?.logo_url}` : IMGLOGO} alt="" />
                                </div>
                            </div>
                            <div className="sc-profile-text ps-md-4">
                                <h2 className="m-0">{schoolInfo?.name_of_school}</h2>
                                <p className="m-0 sc-profile-contact"><span>{schoolInfo?.contact_number}</span> {schoolInfo?.email_id && <span>|</span>} <span>{schoolInfo?.email_id}</span>
                                </p>
                                <p className="m-0 sc-profile-addrs"><span>{schoolInfo?.state}</span> | <span>{schoolInfo?.district}</span> | <span>{schoolInfo?.city_village}</span>  </p>
                                <p className="mt-3">
                                    <a className="btn btn-primary sc-profile-viewbtn collapsed" data-bs-toggle="collapse"
                                        href="#collapseExample" aria-expanded="false" aria-controls="collapseExample">View More
                                        Information <i className="view-arrow"></i></a>
                                </p>
                            </div>
                        </div>
                        <div className="collapse ms-lg-5 ps-lg-5" id="collapseExample">
                            <div className='ps-2   ms-lg-5'>
                                <div className="row">
                                    <div className="col-md-4 col-sm-6   mb-4">
                                        <label className="f12 text-mgray">Display Name</label>
                                        <p className="f18 mb-0">{schoolInfo?.name_of_school}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Registration Number</label>
                                        <p className="f18 mb-0">{schoolInfo?.registration_number}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Date of Registration</label>
                                        <p className="f18 mb-0">{schoolInfo?.date_of_registration}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Contact Number</label>
                                        <p className="f18 mb-0">{schoolInfo?.contact_number}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Fax Number</label>
                                        <p className="f18 mb-0">{schoolInfo?.fax_number}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Office Timings</label>
                                        <p className="f18 mb-0">{schoolInfo?.timings}</p>
                                    </div>
                                    <div className="col-lg-12 mb-4">
                                        <label className="f12 text-mgray">Head Quarter Address</label>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">State</label>
                                        <p className="f18 mb-0">{schoolInfo?.state}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">District</label>
                                        <p className="f18 mb-0">{schoolInfo?.district}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Block</label>
                                        <p className="f18 mb-0">{schoolInfo?.['']}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Area/Locality</label>
                                        <p className="f18 mb-0">{schoolInfo?.taluka}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">City / Vilage (O)</label>
                                        <p className="f18 mb-0">{schoolInfo?.city_village}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">Pincode</label>
                                        <p className="f18 mb-0">{schoolInfo?.pin_code}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">GST Number</label>
                                        <p className="f18 mb-0">{schoolInfo?.gst_number}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">PAN Number</label>
                                        <p className="f18 mb-0">{schoolInfo?.['']}</p>
                                    </div>
                                    <div className="col-md-4 col-sm-6  mb-4">
                                        <label className="f12 text-mgray">website address</label>
                                        <p className="f18 mb-0">{schoolInfo?.website_url}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className="my-4 section-hd">School Administration Wing Details</h3>
                    <div className="border-bottom mb-3">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="d-flex sc-profile-details small mb-4">
                                    <div className="sc-profile-img pt-0">
                                        <img className='pr-image' src={principalInfo?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${principalInfo?.profile_img.charAt(0) === "/" ? principalInfo?.profile_img.substring(1) : principalInfo?.profile_img}` : CHAIRMANLOGO} alt="" />
                                    </div>
                                    <div className="sc-profile-text ps-4">
                                        <p className="sc-profile-deg mb-1">Principal</p>
                                        <h2 className="m-0">{principalInfo?.name}</h2>
                                        <p className="m-0 sc-profile-contact">
                                            <span>{principalInfo?.mobile_number}</span>  {principalInfo?.email_id && <span>|</span>} <span>{principalInfo?.email_id}</span>
                                        </p>
                                        <p className="m-0 sc-profile-addrs">
                                            <span>{principalInfo?.state}</span>{principalInfo?.district && <span> | </span>}<span>{principalInfo?.district}</span> {principalInfo?.city_village && principalInfo?.district && <span> | </span>} <span>{principalInfo?.city_village}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="d-flex sc-profile-details small mb-4">
                                    <div className="sc-profile-img pt-0">
                                        <img className='pr-image' src={vicePricipalInfo?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${vicePricipalInfo?.profile_img.charAt(0) === "/" ? vicePricipalInfo?.profile_img.substring(1) : vicePricipalInfo?.profile_img}` : GENERALSECRETARY} alt="" />
                                    </div>
                                    <div className="sc-profile-text ps-4">
                                        <p className="sc-profile-deg mb-1">Vice Principal</p>
                                        <h2 className="m-0">{vicePricipalInfo?.name}</h2>
                                        <p className="m-0 sc-profile-contact">
                                            <span>{vicePricipalInfo?.mobile_number}</span> {vicePricipalInfo?.email_id && <span>|</span>}  <span>{vicePricipalInfo?.email_id}</span>
                                        </p>
                                        <p className="m-0 sc-profile-addrs">
                                            <span>{vicePricipalInfo?.state}</span>{vicePricipalInfo?.district && <span> | </span>}<span>{vicePricipalInfo?.district}</span> {vicePricipalInfo?.city_village && vicePricipalInfo?.district && <span> | </span>} <span>{vicePricipalInfo?.city_village}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="d-flex sc-profile-details small mb-4">
                                    <div className="sc-profile-img pt-0">
                                        <img className='pr-image' src={adminInfo?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${adminInfo?.profile_img.charAt(0) === "/" ? adminInfo?.profile_img.substring(1) : adminInfo?.profile_img}` : INSTITUTEADMIN} alt="" />
                                    </div>
                                    <div className="sc-profile-text ps-4">
                                        <p className="sc-profile-deg mb-1">School Admin</p>
                                        <h2 className="m-0">{adminInfo?.name}</h2>
                                        <p className="m-0 sc-profile-contact">
                                            <span>{adminInfo?.mobile_number}</span> {adminInfo?.email_id && <span>|</span>} <span>{adminInfo?.email_id}</span>
                                        </p>
                                        <p className="m-0 sc-profile-addrs">
                                            <span>{adminInfo?.state}</span>{adminInfo?.district && <span> | </span>}<span>{adminInfo?.district}</span> {adminInfo?.city_village && adminInfo?.district && <span> | </span>} <span>{adminInfo?.city_village}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ul className="nav nav-tabsnewui  custom-tabsnewui" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <NavLink
                                className={
                                    splitLocation[3] === "teachersform"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                to={{
                                    pathname: `/school-dashboard/profile/teacher/list/${schoolCode}`,
                                    state: { data: "" },
                                }}
                            >
                                Teachers
                            </NavLink>

                        </li>
                        <li className="nav-item" role="presentation">
                            <NavLink
                                className={
                                    splitLocation[3] === "studentsform"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                to={{
                                    pathname: `/school-dashboard/profile/students/list/${schoolInfo?.id}/${schoolCode}`,
                                    state: { data: "" },
                                }}
                            >
                                Students
                            </NavLink>

                        </li>
                        <li className="nav-item" role="presentation">
                            <NavLink
                                className={
                                    splitLocation[2] === "studentcount"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                to={{
                                    pathname: `/school-dashboard/profile/student-count/list/${schoolCode}`,
                                    state: { data: "" },
                                }}
                            >
                                Student Count
                            </NavLink>

                        </li>
                        <li className="nav-item" role="presentation">
                            <NavLink
                                className={
                                    splitLocation[2] === "modules"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                to={{
                                    pathname: `/school-dashboard/profile/module/list/${schoolCode}`,
                                    state: { data: "" },
                                }}
                            >
                                Modules
                            </NavLink>

                        </li>
                        <li className="nav-item" role="presentation">
                            <NavLink
                                className={
                                    splitLocation[2] === "payments"
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                to={{
                                    pathname: `/school-dashboard/profile/payment/list/${schoolCode}`,
                                    state: { data: "" },
                                }}
                            >
                                Payments
                            </NavLink>
                        </li>
                    </ul>
                    <div className="mt-3">
                        <SchoolProfileInsideRoutings />
                    </div>
                </div>}

        </div>
    )
}


