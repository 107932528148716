import React from "react";
import { useHistory } from "react-router-dom";
import { ContentMediumList } from "../../../../components/mediums/list";
import { ContentStandardList } from "../../../../components/standards/list";
import { ContentSubjectList } from "../../../../components/subjects/list";
import { ContentChapterList } from "../../../../components/chapters/list";

export const ContentChapter = () => {
    const history = useHistory();


    return (
        <div>
            <div className="border-top border-primary py-3">
                <ContentChapterList isLinked={""} display={""} boardUuid={undefined} mediumUuid={undefined} standardUuid={undefined} subjectUuid={undefined} navigateurl="/dashboard/content/upload" />
            </div>
        </div>
    )
}