import React from 'react'
import { Route, Switch } from 'react-router'
import { ContentBoards } from '../../pages/dashboard/content/uploads/boards';
import { ContentMedium } from '../../pages/dashboard/content/uploads/medium';
import { ContentSatndard } from '../../pages/dashboard/content/uploads/standard';
import { ContentSubject } from '../../pages/dashboard/content/uploads/subject';
import { ContentChapter } from '../../pages/dashboard/content/uploads/chapter';
import { ContentTopic } from '../../pages/dashboard/content/uploads/topic';


const NestedContentList = () => {
    return (
        <Switch>
            <Route path="/dashboard/content/upload/boards-list" component={ContentBoards}></Route>
            <Route path="/dashboard/content/upload/board/:BoardUuid/:BoardName/medium" component={ContentMedium}></Route>
            <Route path="/dashboard/content/upload/board/:BoardUuid/:BoardName/:MediumUuid/:MediumName/standard" component={ContentSatndard}></Route>
            <Route path="/dashboard/content/upload/board/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/subject" component={ContentSubject}></Route>
            <Route path="/dashboard/content/upload/board/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/chapter" component={ContentChapter}></Route>
            <Route path="/dashboard/content/upload/board/:BoardUuid/:BoardName/:MediumUuid/:MediumName/:StandardUuid/:StandardName/:SubjectUuid/:SubjectName/:ChapterUuid/:ChapterName/topic" component={ContentTopic}></Route>

        </Switch>
    )
}

export default NestedContentList;