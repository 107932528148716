import { MathJax, MathJaxContext } from "better-react-mathjax";
import React from "react";

interface Props {
    questionsList: any;
    setShowPopUp: any;
}

const ContentPreviewQuestions: React.FC<Props> = (props: Props) => {
    const handleClose = () => {
        props.setShowPopUp(false);
    }

    function onViewQuestion(name: any) {
        return { __html: name };
    }
    return (
        <div
            className={`modal fade ${true ? "show" : "false"}`}
            style={{ display: true ? "block" : "none" }}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog question_preview_dialog" role="document">
                <div className="modal-content">
                    <h5 className="text-center" style={{ top: "16px", position: "relative" }}>
                        Test Preview
                    </h5>
                    <div className="modal-body px-5 mx-2">
                        <MathJaxContext>
                            <MathJax>
                                <div className=" border ps-4 pe-3 pt-2 mt-3 question_view_height">
                                    {props?.questionsList?.map((topic: any, index: number) => (
                                        <div key={index}>
                                            {topic?.questions?.map((question: any, i: number) => (
                                                <div key={i}>
                                                    <div className='row col-md-12 mt-3'>
                                                        <div className='row'>
                                                            <div className='col-md-11'>
                                                                <div className='d-flex'>
                                                                    <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                                        Q{i + 1}:
                                                                    </p>
                                                                    <div className='ms-4 ps-2 img_ck'>
                                                                        <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                                            <div dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {question.options.map((options: any, j: number) => (
                                                            <div className='row' key={j}>
                                                                <div className='col-md-1'>
                                                                    <span>{options.option}.</span>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <p className="mb-1 f14 fw-bold text-primary cursor-pointer"><u>Show more</u></p>
                                                    <div className="d-flex justify-content-between">
                                                        <h6 className="text-danger mb-0 f14 fw-bold">Question Used Count {question?.question_used_count}</h6>
                                                        {question?.question_info && <p className="mb-0 f16">({question?.question_info})</p>}
                                                    </div>
                                                </div>))}
                                        </div>
                                    ))}
                                    {props?.questionsList.length == 0 && <h6 className="text-center mt-3">No Data Found</h6>}
                                </div>
                            </MathJax>
                        </MathJaxContext>
                    </div>
                    <div className="modal-footer border-0 text-center d-block">

                        <button
                            type="button"
                            className="btn btn-primary  px-5 rounded-12  py-1"
                            data-dismiss="modal"
                            onClick={handleClose}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContentPreviewQuestions;