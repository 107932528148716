import React, { useEffect, useRef, useState } from "react";

import moment from "moment";
import { FormControlError, FormField, FormValidators } from "../form-builder/model/form-field";
import { useHistory, useParams } from "react-router-dom";
import { CREATE_BOARD, DELETE_BOARD, GET_BOARD_BY_UUID, GET_BOARD_FILTER, GET_BOARD_LIST, UPDATE_BOARD } from "../../app/service/abhyas-board.service";
import { toast } from "react-toastify";
import { FormValidator } from "../form-builder/validations";
import FormBuilder from "../form-builder";
import { DataTable } from "../data-table";
import { ContentBoardDataGridCols } from "./data-gird-cols";

interface Props {
    isLinked: string;
    navigateurl?: string
}

export const ContentBoardList: React.FC<Props> = (props) => {
    const [boardsList, setBoardsList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [boardId, setBoardId] = useState<string>("0");
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [currentFormData, setCurrentFormData] = useState<any>({});
    const [formData, setFormData] = useState<any>([]);
    const [formValidationErrors, setFormValidationErrors] = useState<FormControlError[]>([]);
    const [nameError, setNameError] = useState('');
    const [preparedSearchData, setPreparedSearchData] = useState<any>({});
    const [codeValue, setCodeValue] = useState<string>('');
    const [codeError, setCodeError] = useState('');
    const boardForm = useRef<any>({});
    const searchForm = useRef<any>({});
    const [searchData, setSearchData] = useState<any>({});

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const FormValidations = [
        new FormField('name', [FormValidators.REQUIRED]),
        new FormField('code', [FormValidators.REQUIRED]),
    ];
    const history = useHistory();

    useEffect(() => {
        for (let j = 0; j < ContentBoardDataGridCols.length; j++) {
            if (String(ContentBoardDataGridCols[j].isLink) === "true" || String(ContentBoardDataGridCols[j].isLink) === "false") {
                ContentBoardDataGridCols[j].isLink = props.isLinked === "false" ? false : true;
            }

        }

        getBoardData();
    }, [])

    const getBoardData = () => {
        setLoading(true);
        GET_BOARD_LIST(activePage == 0 ? activePage : activePage - 1, {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element.created_dt.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt.split("T")[0]).format('DD-MM-yyyy');
                        }

                    });
                    setBoardsList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });
    }


    const onPageChange = (data: any) => {
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_BOARD_LIST(pageNumber, preparedSearchData ? preparedSearchData : {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element.created_dt.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setBoardsList(data);
                    setLoading(false);
                }
            }
        });
    }
    const onDelete = (data: any) => {
        setLoading(true);
        const id = data.uuid;
        DELETE_BOARD(id).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res.err);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success(res?.message);
                getBoardData();
            }
        });

    }
    const onEdit = (data: any) => {
        setBoardId(data?.uuid);
        setFormLoading(false);
        setCodeError('');
        setNameError('');
        setCanShowPopup(true);
        if (data?.uuid) {
            setFormLoading(true);
            GET_BOARD_BY_UUID(data?.uuid).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setFormLoading(false);

                } else {
                    if (res.rows.length > 0) {
                        setCurrentFormData(res.rows[0]);
                        setCodeValue(res?.rows[0]?.code);
                        setFormLoading(false);

                    }
                }
            });
        }
    }
    const handleClose = () => {
        setFormData({});
        setCurrentFormData({});
        setCanShowPopup(false);
    }
    const addUpdateBoard = (data: any) => {
        setFormLoading(false);
        setCodeError('');
        setNameError('');
        setCanShowPopup(true);
        setBoardId(data);
        setCodeValue("");
        const datafrom = {
            uuid: '',
            code: '',
            name: ''
        };
        setFormData({ ...datafrom });
        setCurrentFormData({ ...datafrom });
        boardForm?.current.reset();
    }
    const handleFormInput = (data: any) => {
        data.value = { ...currentFormData, ...data.value };
        setFormData(data);
        const errors: any = FormValidator(FormValidations, data.value);
        setFormValidationErrors(errors);
    };
    const onChangeCode = (event: any) => {
        setCodeError('');
        const inputValue = event.toUpperCase(); // Convert to uppercase
        setCodeValue(inputValue.replace(/\s/g, ''));
        // const inputValueWithoutSpaces = event.target.value.replace(/\s/g, ''); 
        // const uppercaseValue = inputValueWithoutSpaces.toUpperCase();
        const reg = /^[a-zA-Z\s_-]+$/;

        if (inputValue) {
            if (!reg.test(inputValue.trim())) {
                setCodeError('Code can contain only letters, Underscore(_),Hyphen(-) and Without Space');
            } else {
                return true;
            }
        } else {
            setCodeError('Please enter board code');
        }
    }
    const onChangeName = (event: any) => {
        setNameError('');
        // if (currentFormData) {
        //     currentFormData.name = event;
        // }
        if (event) {
            return true;
        } else {
            setNameError('Please enter board name');
        }
    }
    const createBoard = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        formsData.code = codeValue;
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        setFormValidationErrors(errors);
        Object.keys(formsData).forEach(function (key) {
            if (typeof formsData[key] === 'string') {
                formsData[key] = formsData[key].trim();
            }
        });
        if (onChangeName(formsData?.name) && onChangeCode(formsData?.code)) {
            setFormLoading(true);
            CREATE_BOARD([formsData]).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setFormLoading(false);
                } else {
                    toast.success(res.message);
                    getBoardData();
                    setFormLoading(false);
                    setCanShowPopup(false);
                    setFormData({});
                    setCurrentFormData({});
                }
            });
        } else {
            if (!nameError && !onChangeName(formsData?.name)) {
                setNameError('Please enter board name');
            }

            if (!codeError && !onChangeCode(formsData?.code)) {
                setCodeError('Please enter board code');
            }
        }
    }
    const updateBoard = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        formsData.code = codeValue;
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        setFormValidationErrors(errors);
        if (onChangeName(formsData?.name) && onChangeCode(formsData?.code)) {
            formsData.uuid = boardId;
            setFormLoading(true);
            UPDATE_BOARD(formsData, boardId).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setFormLoading(false);
                } else {
                    toast.success(res?.message);
                    getBoardData();
                    setFormLoading(false);
                    setCanShowPopup(false);
                    setFormData({});
                    setCurrentFormData({});

                }
            });
        } else {
            if (!nameError && !onChangeName(formsData?.name)) {
                setNameError('Please enter board name');
            }

            if (!codeError && !onChangeCode(formsData?.code)) {
                setCodeError('Please enter board code');
            }
        }
    }
    const handleSearchInput = (data: any) => {
        setSearchData(data);
    };
    function handleSearch() {
        const data = { ...searchData.value };
        if (data?.search) {
            setLoading(true);
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            setPreparedSearchData(data);
            GET_BOARD_FILTER(data).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element.created_dt.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt.split("T")[0]).format('DD-MM-yyyy');
                        }

                    });
                    setActivePage(0);
                    setBoardsList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }
    function handleRefresh() {
        const data = searchData;
        data.value = {};
        setSearchData({ ...data });
        setPreparedSearchData({});
        getBoardData();
        searchForm.current.reset();
    }
    const onActions = (data: any) => {
        if (data.type === "link") {
            history.push(
                `${props?.navigateurl}/board/${data.data.uuid}/${encodeURIComponent(data.data.name)}/medium`
            );

        }
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };
    return (
        <div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {boardId === "0" ? "Add Board" : "Update Borad"}
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info ">
                            {formLoading && (
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {!formLoading && (
                                <>
                                    <FormBuilder onUpdate={handleFormInput}>
                                        <form ref={boardForm}>
                                            <div className="row custom-form">
                                                <div className="col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label">Code <sup style={{ color: 'red', fontSize: '14px', }}>*</sup></label>
                                                        <input className="form-control form-control-lg f18" type="text" name="code" value={codeValue} onChange={(e) => onChangeCode(e.target.value)} placeholder="Please enter board code" />
                                                        {codeError && <p className="text-danger">{codeError}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label">Board Name <sup style={{ color: 'red', fontSize: '14px', }}>*</sup></label>
                                                        <input className="form-control form-control-lg f18" type="text" name="name" defaultValue={currentFormData?.name} onChange={(e) => onChangeName(e.target.value)} placeholder="Please enter board name" />
                                                        {nameError && <p className="text-danger">{nameError}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </FormBuilder>


                                </>
                            )}
                        </div>
                        <div className="modal-footer">

                            {boardId === "0" && < a className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer py-2" onClick={() => createBoard()}>Add</a>}
                            {boardId !== "0" && <a className="btn btn-primary btn-sm px-4 rounded-12 cursor-pointer py-2" onClick={() => updateBoard()}>Update</a>}
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer btn-sm px-4 rounded-12 cursor-pointer py-2"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex mt-4 ms-2 justify-content-between">
                <h5 className="">Boards</h5>
                <p className="mb-0"><button className="btn btn-primary" onClick={() => addUpdateBoard("0")}>Add Board</button></p>
            </div>
            <div className="row  mt-4" style={{ marginBottom: '15px' }}>
                <div className="col-md-4">
                    <FormBuilder onUpdate={handleSearchInput}>
                        <form ref={searchForm}>
                            <input type="text" id="search" className="form-control ft-14" placeholder="Board name" name="search" onKeyDown={(e) => handleKeyDown(e)} />
                        </form>
                    </FormBuilder>
                </div>
                <div className="col-md-4 pt-3 pt-md-0 ">
                    <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                    <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                        Clear
                    </button>
                </div>
            </div>

            <div className="mt-3">
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {!loading && <DataTable TableCols={ContentBoardDataGridCols} tableData={boardsList}
                    // onChange={addUpdateBoard}
                    onActions={(e) => onActions(e)}
                    editInfo={onEdit}
                    deleteInfo={onDelete}
                    pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>}
            </div>
        </div>
    )
}