import { CKEditor } from '@ckeditor/ckeditor5-react';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import React, { useEffect, useRef, useState } from 'react';
import ClassicEditor from 'ckeditor5-classic-with-mathtype';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { UPLOAD_LOGO } from '../../../../../app/service/school.service';
import { GET_QUESTION_SOURCES_list } from '../../../../../app/service/content-module/question-source';
import { BULK_UPLOAD_QUESTIONS, BULK_UPLOAD_QUESTIONS_FILE, CREATE_CONTENT_QUESTION, DELETE_CONTENT_QUESTION, GET_CONTENT_QUESTIONS, UPDATE_CONTENT_QUESTION } from '../../../../../app/service/content-module/question-service';
import { strictEqual } from 'assert';
import { stringify } from 'querystring';
import appConfig from '../../../../../config/constant';

interface Props {
    boardUuid: any;
    mediumUuid: any;
    standardUuid: any;
    subjectUuid: any;
    chapterUuid: any;
    topicUuid: any;
    // addQuestion: (fn: () => void) => void;
    updateList?: (e: string) => void;
    selectTest?: (e: string) => void;
    setBackQuestionsList?: (e: string) => void;
}
const UploadsAddQuestionsList: React.FC<Props> = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [questionsList, setQuestionsList] = useState<any>([]);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [questionTypeError, setQuestionTypeError] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState<string>('multiple_choice_questions');
    const [questionImg, setQuestionImg] = useState<any[]>([]);
    const [imageLoader, setImageLoader] = useState(false);
    const [questionUuid, setQuestionUuid] = useState<string>('');
    const [questionsData, setQuestionsData] = useState<any>([]);
    const [updateQuestionsData, setUpdateQuestionsData] = useState<any>([]);
    const [canShowCkeditor, setCanShowCkeditor] = useState(false);
    const [inputData, setInputData] = useState<any>();
    const [questionData, setQuestionData] = useState<any>();
    const [canShowCKeditorPopup, setCanShowCKeditorPopup] = useState(false);
    const [canShowCKeditorAddPopup, setCanShowCKeditorAddPopup] = useState(false);
    const [loadingCkeditor, setLoadingCkeditor] = useState(false);
    const [currentQuestionData, setCurrentQuestionData] = useState<any>([]);
    const [questionIndex, setQuestionIndex] = useState<any>(null);
    const [optionIndex, setOptionIndex] = useState<any>(null);
    const [correctAnswer, setCorrectAnswer] = useState<any>('');
    const [editor, setEditor] = useState<any>();
    const [questionNumber, setQuestionNumber] = useState<any>('');
    const [questionLevel, setQuestionLevel] = useState<string>("MEDIUM");
    const [questionExplanation, setQuestionExplanation] = useState<string>("");
    const [selectSource, setSelectSource] = useState<string>("");
    const [canShowExplanation, setCanShowExplanation] = useState(false);
    const [alphabets, setAlphabets] = useState<any>(["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]);
    const history = useHistory();
    const listRef: any = useRef(null);
    const editorRef = useRef(null);
    const [sourceList, setSourceList] = useState<any>([]);
    const [sourceError, setSourceError] = useState<string>("");
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [questionUsed, setQuestionUsed] = useState<string>("1");
    const [questionAsked, setQuestionAsked] = useState<string>("");
    const [questionOptions, setQuestionOptions] = useState<boolean>(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
    const [bulkUploadResponse, setBulkUploadResponse] = useState<any>({});
    const boardUuid = props.boardUuid;
    const mediumUuid = props.mediumUuid;
    const standardUuid = props.standardUuid;
    const subjectUuid = props.subjectUuid;
    const chapterUuid = props?.chapterUuid;
    const topicUuid = props?.topicUuid;



    useEffect(() => {
        getSorceList();
        getQuestionsList();

    }, []);
    // useEffect(() => {
    //     props?.addQuestion(onAddQuestion);
    // }, [props?.addQuestion]);

    const getDangerouslySetInnerHTML = (element: any) => {
        return <div dangerouslySetInnerHTML={onViewQuestion(element?.question?.question)}></div>
    }

    const getQuestionsList = () => {
        setLoading(true);
        const payLoad = {
            board_uuid: boardUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            subject_uuid: subjectUuid,
            chapter_uuid: chapterUuid,
            topic_uuid: topicUuid
        }
        GET_CONTENT_QUESTIONS(payLoad, 0, 1000000).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setLoading(false);
                } else {
                    const data = res?.data;
                    setQuestionsList(data);
                    if (data && data?.length > 0) {
                        setUpdateQuestionsData(data);
                    } else {
                        setUpdateQuestionsData([]);
                    }
                    setLoading(false);
                }
            }
        });
    }
    const onSaveAddQuestion = () => {
        const payLoad =
        {
            "id": questionNumber,
            "uuid": "",
            "question_type": selectedOption,
            "question": getCkeditorValue('question'),
            "options": getCkeditorValue('options'),
            "correct_answer": correctAnswer ? correctAnswer : '',
            "explanation": questionExplanation ? questionExplanation : "",
            "mode": parseInt(questionUsed),
            "difficulty_level": questionLevel,
            "question_info": questionAsked,
            "question_source_uuid": selectSource,
            "board_uuid": boardUuid,
            "medium_uuid": mediumUuid,
            "standard_uuid": standardUuid,
            "subject_uuid": subjectUuid,
            "chapter_uuid": chapterUuid,
            "topic_uuid": topicUuid,
            // "validation_status": "VERIFIED"
        }
        // return
        setIsFormSubmitted(true);
        if (correctAnswer) {
            CREATE_CONTENT_QUESTION(payLoad).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    toast.success(res.message);
                    setCanShowCKeditorAddPopup(false);
                    getQuestionsList();
                }
            });
        }

    }
    const onUpdate = () => {
        currentQuestionData[0].mode = parseInt(questionUsed);
        currentQuestionData[0].difficulty_level = questionLevel;
        currentQuestionData[0].question_info = questionAsked;
        currentQuestionData[0].question_source_uuid = selectSource;
        delete currentQuestionData[0].created_dt;
        delete currentQuestionData[0].updated_dt;
        // currentQuestionData[0].explanation = questionExplanation ? questionExplanation : "";
        setIsFormSubmitted(true);
        if (questionLevel) {
            UPDATE_CONTENT_QUESTION(currentQuestionData[0]?.uuid, currentQuestionData[0]).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    toast.success(res.message);
                    setCurrentQuestionData([]);
                    setCanShowCKeditorPopup(false);
                    getQuestionsList();
                }
            });
        }
    }


    function onViewQuestion(name: any) {
        return { __html: name };
    }

    function onChange(e: any, editor: any) {
        setInputData(editor.getData());
    };

    function onClickEdit(questions: any, i: number, backNext?: string) {
        console.log(questions, "edit");
        console.log(questions?.mode, "questions?.mode");

        setCurrentIndex(i);
        setQuestionExplanation(questions?.explanation);
        setCurrentQuestionData([]);
        setQuestionImg([]);
        setQuestionUsed(questions?.mode);
        setQuestionLevel(questions?.difficulty_level);
        setQuestionAsked(questions?.question_info);
        setSelectSource(questions?.question_source_uuid);
        setQuestionUuid(questions?.uuid)
        // setQuestionImg(questions?.question?.images);
        setSelectedOption(questions?.question_type)
        setCanShowCKeditorPopup(true);
        setQuestionData(questions.question);
        const data = currentQuestionData;
        if (backNext) {
            setCurrentQuestionData([questions]);
        } else {
            data.push(questions);
            setCurrentQuestionData(data);
        }
        questionImg.push(questions)
        setLoadingCkeditor(true);
        setCanShowCkeditor(false);
        setTimeout(() => {
            setLoadingCkeditor(false);
        }, 1000);
    }

    function onEditOptions(question: any, i: number, option: any, j: number) {
        setInputData("");
        setCanShowExplanation(false);
        setQuestionIndex(i);
        setOptionIndex(j);
        setQuestionData(option.value);
        setCanShowCkeditor(true);
    }

    function getCorrectAnswer(option: any) {
        for (let i = 0; i < alphabets.length; i++) {
            if (option === alphabets[i]) {
                return alphabets[i - 1];
            }
        }
    }

    function onDeleteOptions(question: any, i: number, option: any, optionIndex: number) {
        setLoadingCkeditor(true);
        const data = currentQuestionData;
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].options.length; j++) {
                if (option.options === data[i].correct_answer) {
                    data[i].correct_answer = "";
                } else {
                    if (data[i].correct_answer === data[i].options[j].option) {

                        if (j > optionIndex) {
                            data[i].correct_answer = getCorrectAnswer(data[i].options[j].option);
                        } else {
                            data[i].correct_answer = data[i].correct_answer;
                        }
                    }
                }
            }
        }
        data[0].options.splice(optionIndex, 1);

        for (let i = 0; i < data[0].options.length; i++) {
            for (let j = 0; j < alphabets.length; j++) {
                if (i === j) {
                    data[0].options[i].option = alphabets[j];
                    data[0].options[i].value = data[0].options[i].value;
                }
            }
        }

        setTimeout(() => {
            setCurrentQuestionData(data);
            setLoadingCkeditor(false);
            setCanShowCkeditor(false);
        }, 1000);

    }
    function onDeleteExplanation(question: any, i: number) {
        setLoadingCkeditor(true);
        const data = currentQuestionData;
        for (let i = 0; i < data.length; i++) {
            data[i].explanation = "";
        }

        setTimeout(() => {
            setCurrentQuestionData(data);
            setLoadingCkeditor(false);
            setCanShowCkeditor(false);
        }, 1000);

    }
    function onEditExplanation(question: any, i: number) {
        setInputData("");
        setCanShowExplanation(true);
        setQuestionData(question?.explanation);
        setOptionIndex(null);
        setCanShowCkeditor(true);
    }
    function onEditQuestion(question: any, i: number) {
        setInputData("");
        setCanShowExplanation(false);
        setQuestionData(question?.question);
        setQuestionIndex(i);
        setOptionIndex(null);
        setCanShowCkeditor(true);
    }

    const handleClose = () => {
        setCanShowCKeditorPopup(false);
        getQuestionsList();
        // getWorksheetQuestions(worksheetNumber);
        setCurrentQuestionData([]);
        setQuestionIndex(null);
        setOptionIndex(null);
        setCanShowPopup(false);
    }

    function getOptionLists(item: any, optionData: any) {
        const answerOptions = item;
        answerOptions.push({
            option: '',
            value: ''
        });
        for (let i = 0; i < answerOptions.length; i++) {
            for (let j = 0; j < alphabets.length; j++) {
                if (i === j && (answerOptions[i].value === '' || answerOptions[i].option === '')) {
                    answerOptions[i].option = alphabets[j];
                    answerOptions[i].value = optionData;
                }
            }
        }

        return answerOptions;
    }

    function onSave() {
        setLoadingCkeditor(true);
        const data = currentQuestionData;
        if (canShowExplanation) {
            setQuestionExplanation(inputData);
            // data[questionIndex].explanation = inputData ? inputData : data[questionIndex].explanation;
            currentQuestionData[0].explanation = inputData ? inputData : currentQuestionData[0].explanation;
            setCanShowExplanation(false);
            setTimeout(() => {
                setCurrentQuestionData(data);
                setLoadingCkeditor(false);
                setCanShowCkeditor(false);
            }, 1000);
        }
        else if (questionIndex !== null && optionIndex !== null) {
            data[questionIndex].options[optionIndex].value = inputData ? inputData : data[questionIndex].options[optionIndex].value;
            // data[questionIndex].options[optionIndex].value = inputData;
            setTimeout(() => {
                setCurrentQuestionData(data);
                setLoadingCkeditor(false);
                setCanShowCkeditor(false);
            }, 1000);
        } else if (questionIndex !== null) {
            data[questionIndex].question = inputData ? inputData : data[questionIndex].question;
            // data[0].question = inputData;
            setTimeout(() => {
                setCurrentQuestionData(data);
                setLoadingCkeditor(false);
                setCanShowCkeditor(false);
            }, 1000);
        } else {
            data[0].options = getOptionLists(data[0].options, inputData);
            setTimeout(() => {
                setCurrentQuestionData(data);
                setLoadingCkeditor(false);
                setCanShowCkeditor(false);
            }, 1000);
        }
        setQuestionData(inputData);
    }

    function handleQuestionOptions(item: any, index: number) {
        const data = currentQuestionData;
        for (let i = 0; i < alphabets.length; i++) {
            if (index === i) {
                data[0].correct_answer = alphabets[i];
            }
        }
        setCurrentQuestionData(data);
    }

    function getUpdateQuestionsData() {
        const result = Object.values(
            [].concat(updateQuestionsData, currentQuestionData)
                .reduce((r: any, c: any) => (r[c.question_number] = Object.assign((r[c.question_number] || {}), c), r), {})
        );
        const updatedOptions = currentQuestionData?.map((element: any) => ({
            ...element,
            uuid: questionUuid
        }));
        return updatedOptions;
    }

    function onAddAnswerOptions() {
        setCanShowExplanation(false);
        setCanShowCkeditor(true);
        setQuestionData('');
        setOptionIndex(null);
        setQuestionIndex(null);
    }

    function onAddQuestion() {
        setIsFormSubmitted(false);
        setQuestionExplanation("")
        setInputData("");
        setQuestionImg([]);
        setSelectedOption('multiple_choice_questions')
        setQuestionTypeError('');
        setCurrentQuestionData([]);
        setLoadingCkeditor(false);
        if (updateQuestionsData.length > 0) {
            setQuestionNumber(updateQuestionsData.length + 1);
        } else {
            setQuestionNumber("1");
        }
        setQuestionsData([]);
        setCanShowCKeditorAddPopup(true);
        setCanShowCkeditor(false);
        getStatusSaveBtn();
        setQuestionUsed("1");
        setQuestionLevel("MEDIUM");
        setQuestionAsked("");
        setSelectSource("");
        setTimeout(() => {
            setLoadingCkeditor(false);
        }, 2000);
        setCanShowExplanation(false);
    }

    function getOptions(index: number) {
        for (let i = 0; i < alphabets.length; i++) {
            if (index === i) {
                return alphabets[i];
            }
        }
    }

    function createMarkup(name: any) {

        return { __html: name };
    }

    function handleAddQuestionOptions(item: any, index: number, e: any) {
        for (let i = 0; i < alphabets.length; i++) {
            if ((index - 1) === i) {
                setCorrectAnswer(alphabets[i]);
            }
        }
    }

    function getStatusSaveBtn(optionType?: string) {
        if (questionsData.length > 1) {
            return false;
        } else {
            return true;
        }
    }




    function getCkeditorValue(type: any) {
        if (type === 'question') {
            if (questionsData.length > 0) {
                return questionsData[0];
            }
        } else {
            const preparedOptions = [];
            const data = questionsData.slice(1);
            for (let i = 0; i < data.length; i++) {
                preparedOptions.push({
                    option: getOptions(i),
                    value: data[i]
                })
            }
            return preparedOptions;
        }
    }

    function getQuestionsData() {
        const data = [];
        const payloads = {
            "id": "",
            "uuid": "",
            "question_type": selectedOption,
            "question": getCkeditorValue('question'),
            // "images": questionImg,
            "options": getCkeditorValue('options'),
            "correct_answer": correctAnswer ? correctAnswer : ''
        }
        data.push(payloads);
        return data;


    }

    const handleAddClose = () => {
        setCanShowCKeditorAddPopup(false);
        getQuestionsList();
        // getWorksheetQuestions(worksheetNumber);
        setCurrentQuestionData([]);
        setQuestionIndex(null);
        setOptionIndex(null);
        setQuestionImg([]);

    }

    function onAddSave() {
        setCanShowCkeditor(true);
        if (canShowExplanation) {
            setQuestionExplanation(inputData);
            setCanShowExplanation(false);
        }
        else if (editor.getData()) {
            questionsData.push(inputData);
        }
    }

    function addAnswerOptions() {
        setCanShowExplanation(false);
        setCanShowCkeditor(false);
        getStatusSaveBtn();
    }

    function addAnswerExplanation() {
        setCanShowExplanation(true);
        setCanShowCkeditor(false);
        getStatusSaveBtn();
    }


    const onDelete = (data: any) => {
        setLoading(true);
        DELETE_CONTENT_QUESTION(data?.uuid).then((res: any) => {
            if (res.status === 'fail') {
                const errorMessage = Array.isArray(res?.err) ? res?.err[0] : res?.err
                toast.error(errorMessage);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success(res.message);
                getQuestionsList();
            }
        });
    }
    const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        setQuestionTypeError("");

    }
    function handleUploadLogo(e: any, type: string) {
        if (e.target.files && e.target.files[0]) {
            const fileType = e.target.files[0].name.split('.').pop()
            if (fileType == "jpeg" || fileType == "jpg") {
                const formData = new FormData();
                formData.append('file', e.target.files[0], e.target.files[0].name);
                uploadLogo(formData, type);
            } else {
                toast.error("Valid file type .jpg, .jpeg only");
            }
        }
    }
    function uploadLogo(formdata: any, type: string) {
        setImageLoader(true);
        UPLOAD_LOGO(formdata).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
                setImageLoader(false);
            } else {
                let imgUrl = res.url
                questionImg.push(imgUrl)
                // setQuestionImg(prevState => [...prevState, imgUrl]);
                setImageLoader(false);
            }
        });
    }
    const handleRemoveItem = (index: number) => {
        const updatedItems = [...questionImg];
        updatedItems.splice(index, 1);
        setQuestionImg(updatedItems);
    };

    const uploadAdapter = (loader: any) => {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: any) => {
                        body.append("file", file);
                        UPLOAD_LOGO(body).then((res: any) => {
                            if (res.status === 'fail') {
                                toast.error(res?.error);
                            } else {
                                let imgUrl = res.url
                                resolve({ default: `${res?.base_url}${imgUrl}` })
                            }
                        });

                    })
                })
            }
        }
    }

    function extractFigureTags(htmlString: string): string[] {
        const parsedHtml = JSON.parse(htmlString);
        const figureTagsContent: string[] = [];
        for (const element of parsedHtml) {
            if (element.type === 'tag' && element.name === 'p' && element.children) {
                const pContent = element.children.map((child: { text: any; }) => child.text || '').join('');
                figureTagsContent.push(pContent);
            }
        }

        return figureTagsContent;
    }
    const testList = () => {
        if (props.selectTest) {
            props.selectTest('');
        }
    }
    const getSorceList = () => {
        const payLoad = {
            limit: 100000
        }
        GET_QUESTION_SOURCES_list(payLoad).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res?.data;
                    setSourceList(data);
                }
            }
        });
    };
    const handleNext = () => {
        if (currentIndex < updateQuestionsData.length - 1) {
            const newIndex = currentIndex + 1;
            setCurrentIndex(newIndex);
            onClickEdit(updateQuestionsData[newIndex], newIndex, "backNext");
        }
    };

    const handleBack = () => {
        if (currentIndex > 0) {
            const newIndex = currentIndex - 1;
            setCurrentIndex(newIndex);
            onClickEdit(updateQuestionsData[newIndex], newIndex, "backNext");
        }
    };
    function handleQuestionTemplateDownload() {
        // const url = `${appConfig.FILE_UPLOAD_URL}uploads/1733306695986-MSQ-EXCEL-TEMPLATE.xlsx`;
        // setTimeout(() => {
        //     toast.success('Successfully Downloaded');
        //     window.location.href = url;
        // }, 1000);
        const data = 'uploads/1733402661891-MSQ-EXCEL-TEMPLATE.xlsx';
        if (data) {
            const url = `${appConfig.FILE_UPLOAD_URL}${data}`;
            window.location.href = url;
            toast.success("Download Question Template Successfully");
        }
    }
    function handleUploadBulkUpload(e: any) {
        if (e.target.files && e.target.files[0]) {
            let formData: any = new FormData();
            formData.count = true;
            formData.append('file', e.target.files[0], e.target.files[0].name);
            // formData.append('school_code', schoolsCode);

            BULK_UPLOAD_QUESTIONS_FILE(formData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.error);
                } else {
                    toast.success(res?.message);
                    setBulkUploadResponse(res);
                }
            });
        }
    }
    const saveBulkUploadQuestions = () => {
        const payLoad = {
            "s3_url": `${bulkUploadResponse?.base_url}${bulkUploadResponse?.url}`,
            "board_uuid": boardUuid,
            "medium_uuid": mediumUuid,
            "standard_uuid": standardUuid,
            "subject_uuid": subjectUuid,
            "chapter_uuid": chapterUuid,
            "topic_uuid": topicUuid
        }
        BULK_UPLOAD_QUESTIONS(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                toast.success(res?.message);
                setBulkUploadResponse({});
                setCanShowPopup(false);
                getQuestionsList();
            }
        });
    }

    return (
        <div>
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog quesions-upload_dialog" role="document">
                    <div className="modal-content worksheet-questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Bulk Upload Questions
                            </h5>
                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info">
                            <div className='text-center mt-5'>
                                <button className='btn bg_org px-3 f14 text-white my-5 radius_13' onClick={handleQuestionTemplateDownload}>Download Template File (Bulk Questions Upload)</button>
                                <br />
                                <div className="file btn  f14 questio_upload_btn text-white d-inline-block" style={{ marginRight: '10px', cursor: 'pointer' }}>Upload the File (Bulk Questions)
                                    <input type="file" style={{ cursor: 'pointer' }} onChange={(e) => handleUploadBulkUpload(e)} onClick={(e: any) => {
                                        e.target.value = null
                                    }} />
                                </div>
                                {/* <button className='btn  f14 questio_upload_btn text-white'>Upload the File (Bulk Questions)</button> */}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary cursor-pointer btn-sm px-3 rounded-12 cursor-pointer py-1"
                                data-dismiss="modal"
                                onClick={saveBulkUploadQuestions}
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary cursor-pointer btn-sm px-3 rounded-12 cursor-pointer py-1"
                                data-dismiss="modal"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade parent-modal-fade ${canShowCKeditorPopup ? 'show' : 'false'}`} style={{ display: (canShowCKeditorPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog worksheet-quesions-dialog" role="document">
                    <div className="modal-content worksheet-questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Update Question</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ overflowX: 'hidden' }}>
                            <div className='row mt-1 mb-3'>
                                <div className='col-md-3'>
                                    <div className="form-group ">
                                        <label className='control-label  fw-bold mb-1'>Question will be used for</label>
                                        <select name="level" id="level" className="form-select f14" value={questionUsed} onChange={(e) => setQuestionUsed(e.target.value)}>
                                            <option value="1">ABHYAAS</option>
                                            <option value="2">PARIKSHA</option>
                                            <option value="3">BOTH ABHYAAS AND PARIKSHA</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div className="form-group ">
                                        <label className='control-label  fw-bold mb-1'>Difficult Level</label>
                                        <select name="level" id="level" className="form-select f14" value={questionLevel} onChange={(e) => setQuestionLevel(e.target.value)}>
                                            <option value="EASY">Easy</option>
                                            <option value="MEDIUM">Medium</option>
                                            <option value="HARD">Hard</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-group ">
                                        <label className='control-label fw-bold mb-1'>Question asked in <span className='text-inprogess f12'>(for ex.JEE 2013, NDA 2011)</span></label>
                                        <input className="form-control" type="text" name="question_asked" value={questionAsked} onChange={(e) => setQuestionAsked(e.target.value)} />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="form-group ">
                                        <label className='control-label  fw-bold mb-1'>Question Source</label>
                                        <select name="source" id="source" className="form-select ft-14 " value={selectSource} onChange={(e) => setSelectSource(e.target.value)}>
                                            <option value="">Select source</option>
                                            {sourceList?.map((name: any, i: number) => (
                                                <option key={i} value={name?.uuid}>
                                                    {name?.source}
                                                </option>
                                            ))}
                                        </select>
                                        {(sourceError && !selectSource) && <p className='text-danger'>Please select source</p>}
                                    </div>
                                </div>
                            </div>
                            {loadingCkeditor &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loadingCkeditor && <div>
                                <div className='cdk-question-view'>
                                    {currentQuestionData?.map((question: any, i: number) => (
                                        <div className="mt-1" key={i}>
                                            <div className='row'>
                                                <div className='row'>
                                                    <div className='col-md-1 pt-4'>
                                                        <span className='fw-bold ' style={{ fontSize: '22px' }}>Q{question?.id}:</span>
                                                    </div>
                                                    <div className='col-md-7 mb-3 ' ref={listRef} style={{ paddingTop: '25px' }}>
                                                        <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                            <MathJaxContext>
                                                                <MathJax>
                                                                    <div className="img_ck" dangerouslySetInnerHTML={onViewQuestion(question?.question)}></div>
                                                                </MathJax>
                                                            </MathJaxContext>
                                                        </span>
                                                    </div>
                                                    <div className='col-md-2 px-0 pb-0 pt-4'>
                                                        <i className="bi bi-pencil-square cursor-pointer edit-icon p-2 " onClick={() => onEditQuestion(question, i)}></i>
                                                    </div>
                                                </div>
                                                <div className='row pt-5'>
                                                    <div className='col-md-9'>
                                                        {question?.options?.map((options: any, j: number) => (
                                                            <div className='row' key={j}>
                                                                <div className='col-md-1'>
                                                                    <span>{options.option}.</span>
                                                                </div>
                                                                <div className='col-md-9'>
                                                                    <div className='row' style={{ position: 'relative' }}>
                                                                        <div className='col-md-8'>
                                                                            <MathJaxContext>
                                                                                <MathJax>
                                                                                    <span className='text-break img_ck' dangerouslySetInnerHTML={onViewQuestion(options.value)} ></span>
                                                                                </MathJax>
                                                                            </MathJaxContext>
                                                                        </div>
                                                                        <div className='col-md-4'>
                                                                            {j === 0 && <div style={{ position: 'absolute', top: '-30px', left: '390px' }}>Choose Correct Answer</div>}
                                                                            <input type='radio' name="option" defaultChecked={question.correct_answer === options.option} onChange={(e) => { handleQuestionOptions(options, j) }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-2'>
                                                                    <i className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={() => onEditOptions(question, i, options, j)}></i>
                                                                    <i className="bi bi-trash cursor-pointer edit-icon p-2" onClick={() => onDeleteOptions(question, i, options, j)}></i>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className='col-md-9 mt-3 mb-2'>
                                                            <h5>Explanation  <i className="bi bi-pencil-square cursor-pointer edit-icon p-2 f14 ms-2" onClick={() => onEditExplanation(question, i)}></i>
                                                                {question.explanation && <i className="bi bi-trash cursor-pointer edit-icon p-2 f14" onClick={() => onDeleteExplanation(question, i)}></i>}
                                                            </h5>
                                                            <div className='f16 text-break' dangerouslySetInnerHTML={createMarkup(question.explanation)} >
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3 mb-2'>
                                                        <button className='btn btn-primary' onClick={() => onAddAnswerOptions()}>Add Answer Options</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {canShowCkeditor && <div className='mt-3'>
                                    <MathJaxContext>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="editorId"
                                            data={questionData}
                                            config={{
                                                toolbar: {
                                                    items: [
                                                        'heading', 'MathType', 'ChemType',
                                                        '|',
                                                        'bold',
                                                        'italic',
                                                        'link',
                                                        'bulletedList',
                                                        'numberedList',
                                                        'imageUpload',
                                                        'mediaEmbed',
                                                        'insertTable',
                                                        'blockQuote',
                                                        'undo',
                                                        'redo',
                                                        '|',
                                                        'imageStyle:full', 'imageStyle:side',
                                                        'imageTextAlternative',
                                                        'imageResize',
                                                        'imageUploadMultiple',

                                                    ]
                                                },
                                                language: 'en',
                                            }}

                                            onChange={(event: any, editor: any) => {
                                                return onChange(event, editor);
                                            }}
                                            onReady={(editor: any) => {
                                                editorRef.current = editor;
                                                editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
                                                    return uploadAdapter(loader);
                                                };
                                                setEditor(editor);
                                            }}
                                        />
                                        <MathJax>
                                        </MathJax>
                                    </MathJaxContext>
                                    <div className='mt-2'>
                                        <button className='btn btn-primary' disabled={editor && editor.getData() ? false : true} onClick={() => onSave()}>Ok</button>&nbsp;
                                    </div>

                                </div>}
                            </div>}
                        </div>
                        <div className="modal-footer  d-flex justify-content-between">
                            <div className=''>
                                {updateQuestionsData?.length > 1 && <div>
                                    <button className={`q_nxt_btn ms-5 me-4  f16 ${currentIndex === 0 && "btn_disabled"}`} onClick={handleBack} disabled={currentIndex === 0}>
                                        Back
                                    </button>
                                    <button className={`q_nxt_btn  f16 ${currentIndex === updateQuestionsData?.length - 1 && "btn_disabled"}`} onClick={handleNext} disabled={currentIndex === updateQuestionsData.length - 1}>
                                        Next
                                    </button>
                                </div>}
                            </div>
                            <div className='questions-save-btn'>
                                <button className='btn btn-primary me-3' onClick={() => onUpdate()}>Update</button>
                                <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleClose}>Close</button>&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal fade parent-modal-fade ${canShowCKeditorAddPopup ? 'show' : 'false'}`} style={{ display: (canShowCKeditorAddPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog worksheet-quesions-dialog" role="document">
                    <div className="modal-content worksheet-questions-model">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Question </h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleAddClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ overflowX: 'hidden' }}>
                            {loadingCkeditor &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!loadingCkeditor && <div>
                                <div className='row mt-1 mb-3'>
                                    <div className='col-md-3'>
                                        <div className="form-group ">
                                            <label className='control-label  fw-bold mb-1'>Question will be used for</label>
                                            <select name="level" id="level" className="form-select f14" value={questionUsed} onChange={(e) => setQuestionUsed(e.target.value)}>
                                                <option value="1">ABHYAAS</option>
                                                <option value="2">PARIKSHA</option>
                                                <option value="3">BOTH ABHYAAS AND PARIKSHA</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div className="form-group ">
                                            <label className='control-label fw-bold mb-1'>Difficult Level</label>
                                            <select name="level" id="level" className="form-select ft-14" value={questionLevel} onChange={(e) => setQuestionLevel(e.target.value)}>
                                                <option value="EASY">Easy</option>
                                                <option value="MEDIUM">Medium</option>
                                                <option value="HARD">Hard</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="form-group ">
                                            <label className='control-label fw-bold mb-1'>Question asked in <span className='text-inprogess f12'>(for ex.JEE 2013, NDA 2011)</span></label>
                                            <input className="form-control " type="text" name="question_asked" value={questionAsked} onChange={(e) => setQuestionAsked(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form-group ">
                                            <label className='control-label fw-bold mb-1'> Question Source</label>
                                            <select name="source" id="source" className="form-select ft-14 " value={selectSource} onChange={(e) => setSelectSource(e.target.value)}>
                                                <option value="">Select source</option>
                                                {sourceList?.map((name: any, i: number) => (
                                                    <option key={i} value={name?.source}>
                                                        {name?.source}
                                                    </option>
                                                ))}
                                            </select>
                                            {(sourceError && !selectSource) && <p className='text-danger'>Please select source</p>}
                                        </div>
                                    </div>

                                </div>
                                <div className='mt-2'>
                                    <h6>Question:{questionNumber}</h6>
                                </div>
                                {(questionsData && questionsData.length > 0) && <div className='mt-2'>
                                    <div className='cdk-question-view'>
                                        {questionsData && <div className='row' style={{ position: 'relative' }}>
                                            {questionsData?.map((name: any, i: number) => (
                                                <div key={i} className=' col-md-8 row' style={{
                                                    marginTop: i !== 0 ? '15px' : '2px',
                                                    marginBottom: i !== 0 ? '5px' : '25px',
                                                    position: i !== 0 ? 'relative' : 'static'
                                                }}>
                                                    {i !== 0 && <div className='col-md-1'>
                                                        <span>{getOptions(i - 1)}.</span>
                                                    </div>}
                                                    <div className={i !== 0 ? 'col-md-4' : 'col-md-11'}>
                                                        <MathJaxContext>
                                                            <MathJax>
                                                                <div style={{ fontSize: i !== 0 ? '14px' : '25px' }} dangerouslySetInnerHTML={createMarkup(name)} >
                                                                </div>
                                                            </MathJax>
                                                        </MathJaxContext>
                                                    </div>

                                                    {(i !== 0 && questionsData.length >= 3) && <div className='col-md-4'>
                                                        {(i !== 0 && i === 1) && <div className='mt-2' style={{ position: 'absolute', top: '-40px', left: '240px' }}>
                                                            <div>Choose Correct Answer</div>
                                                        </div>}
                                                        <div className='col-md-1'>
                                                            <input type='radio' name="option" onChange={(e) => { handleAddQuestionOptions(name, i, e) }} />
                                                        </div>
                                                    </div>}
                                                </div>
                                            ))}
                                            <div className='col-md-2 mb-2'>
                                                <button className='btn btn-primary' style={{ padding: '6px 6px' }} onClick={() => addAnswerOptions()}>Add Answer Options</button>
                                            </div>
                                            <div className='col-md-2 mb-2'>
                                                <button className='btn btn-primary' style={{ padding: '6px 6px' }} onClick={() => addAnswerExplanation()}>Add Explanation</button>
                                            </div>
                                        </div>}
                                    </div>

                                </div>}
                                {!canShowCkeditor && <div className='mt-3'>
                                    <MathJaxContext>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="editorId"
                                            data=''
                                            config={{
                                                // plugins: [Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize],
                                                toolbar: {
                                                    items: [
                                                        'heading', 'MathType', 'ChemType',
                                                        '|',
                                                        'bold',
                                                        'italic',
                                                        'link',
                                                        'bulletedList',
                                                        'numberedList',
                                                        'imageUpload',
                                                        'mediaEmbed',
                                                        'insertTable',
                                                        'blockQuote',
                                                        'undo',
                                                        'redo',
                                                        '|',
                                                        'imageStyle:full', 'imageStyle:side',
                                                        'imageTextAlternative',
                                                        'imageResize',
                                                        'imageUploadMultiple',
                                                    ]
                                                },

                                                image: {
                                                    resizeOptions: [{
                                                        name: 'resizeImage:original',
                                                        value: null,
                                                        label: 'Original Size',
                                                        icon: 'original'
                                                    },
                                                    {
                                                        name: 'resizeImage:25',
                                                        value: '25',
                                                        label: '25%',
                                                        icon: 'small'
                                                    },
                                                    {
                                                        name: 'resizeImage:50',
                                                        value: '50',
                                                        label: '50%',
                                                        icon: 'medium'
                                                    },
                                                    {
                                                        name: 'resizeImage:75',
                                                        value: '75',
                                                        label: '75%',
                                                        icon: 'large'
                                                    }
                                                    ],
                                                    toolbar: [
                                                        'imageStyle:inline',
                                                        'imageStyle:block',
                                                        'imageStyle:side',
                                                        '|',
                                                        'toggleImageCaption',
                                                        'imageTextAlternative',
                                                        'resizeImage:25',
                                                        'resizeImage:50',
                                                        'resizeImage:75',
                                                        'resizeImage:original',
                                                    ],
                                                },
                                                language: 'en',

                                            }}

                                            onChange={(event: any, editor: any) => {
                                                return onChange(event, editor);
                                            }}
                                            onReady={(editor: any) => {
                                                editorRef.current = editor;
                                                editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
                                                    return uploadAdapter(loader);
                                                };
                                                setEditor(editor);
                                            }}

                                        />
                                        <MathJax>
                                        </MathJax>
                                    </MathJaxContext>
                                    <div className='mt-2'>
                                        <button className='btn btn-primary' disabled={editor && editor.getData() ? false : true} onClick={() => onAddSave()}>Ok</button>&nbsp;
                                    </div>
                                </div>
                                }
                                {questionExplanation &&
                                    <div className='col-md-6 mt-3'>
                                        <h5>Explanation</h5>
                                        <div className='f16 text-break' dangerouslySetInnerHTML={createMarkup(questionExplanation)} >
                                        </div>
                                    </div>
                                }
                            </div>}
                        </div>
                        <div className="modal-footer">
                            <div className='questions-save-btn'>
                                <button className='btn btn-primary' disabled={getStatusSaveBtn()} onClick={() => onSaveAddQuestion()}>Save</button>
                            </div>
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleAddClose}>Close</button>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-between'>
                <h5>Questions</h5>
                <div className='export_btn me-2 me-sm-4'>
                    <button
                        className="export_btn_select f14"
                        onClick={() => setQuestionOptions(!questionOptions)}
                    >
                        <span>Add Questions
                            <i className='bi bi-chevron-down ps-3'></i></span>
                    </button>
                    {questionOptions && <ul className='export_btn_select_drop_list z_100'>
                        <li className='export_btn-list-view' onClick={() => onAddQuestion()}>
                            <span>One by one</span></li>
                        <li className='export_btn-list-view' onClick={() => setCanShowPopup(true)}>
                            <span>Bulk upload</span></li>
                    </ul>}
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <MathJaxContext>
                        <MathJax>
                            {questionsList?.map((question: any, i: number) => (
                                <div className="mt-4" key={i}>
                                    <div className='row'>
                                        <div className='col-md-11'>
                                            <div className='row'>
                                                <div className='col-12' style={{ paddingTop: '15px' }}>
                                                    <div className='d-flex'>
                                                        <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                            Q{question.id}:
                                                        </p>
                                                        <div className='ms-4 ps-2'>
                                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                                <div dangerouslySetInnerHTML={onViewQuestion(question.question)}></div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ms-5 ps-5'>
                                                {question.options.map((options: any, j: number) => (
                                                    <div className='d-flex' key={j}>
                                                        <div className=''>
                                                            <span>{options.option}.</span>
                                                        </div>
                                                        <div className='ms-3'>
                                                            <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className='col-md-1' style={{ paddingTop: '15px' }}>
                                            <i className="bi bi-pencil-square cursor-pointer edit-icon p-2" onClick={() => onClickEdit(question, i)}></i>
                                            <i className="bi bi-trash cursor-pointer p-2" onClick={() => onDelete(question)}></i>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </MathJax>
                    </MathJaxContext>
                    {questionsList?.length == 0 && <p className='text-center'>No Questions Found</p>}
                </div>
            }

        </div >
    );
};
export default UploadsAddQuestionsList;
